import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TRevision, TRevisionUser } from "src/types/audit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { TCcVariable } from "../slices/ccVariablesSlice";
import { axiosBaseQuery } from "src/utils/axios/axiosBaseQuery";

export type RevisionScope = {
  revision: TRevision;
  user: TRevisionUser;
};

export type RevisionsGrouped = {
  day: string;
  revisions: RevisionScope[];
  users: Set<string>;
};

export const historyApi = createApi({
  reducerPath: "historyApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Revisions", "CCItems"],
  endpoints: (builder) => ({
    getAuditRevisionList: builder.query<RevisionScope[], { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/api/secured/audit-bt/${uuid}/revision/list`,
        method: "GET",
      }),
      providesTags: ["Revisions"],
    }),
    getRevisionCCItems: builder.query<
      TCcVariable[],
      { uuid: string | null; revisionId: number | null; keys: string[] }
    >({
      query: ({ uuid, revisionId, keys }) => ({
        url: `/api/secured/audit-bt/${uuid}/revision/${revisionId}/cc-items`,
        method: "PUT",
        data: keys,
      }),
      providesTags: ["CCItems"],
    }),
    getAllRevisionsForStep: builder.query<RevisionScope[], { stepId: number }>({
      query: ({ stepId }) => ({
        url: `/api/secured/audit-bt/${stepId}/revisions`,
        method: "GET",
      }),
      providesTags: ["Revisions"],
    }),
  }),
});

export const {
  useGetAuditRevisionListQuery,
  useGetRevisionCCItemsQuery,
  useGetAllRevisionsForStepQuery,
} = historyApi;

type HistoryState = {
  revisions: RevisionScope[];
};

const initialState: HistoryState = {
  revisions: [],
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      historyApi.endpoints.getAllRevisionsForStep.matchFulfilled,
      (state, action) => {
        state.revisions = action.payload;
      },
    );
  },
});

export const selectRevisionsForStep = (state: RootState, stepId: number) =>
  state.history.revisions[stepId] || [];

export const selectGroupedRevisions = (state: RootState) => {
  const revisions = state.history.revisions;
  if (!revisions) return [];

  const grouped = revisions.reduce(
    (acc: { [key: string]: RevisionsGrouped }, revision) => {
      const day = new Date(revision.revision.timestamp)
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
        .toString();

      if (!acc[day]) {
        acc[day] = {
          day,
          revisions: [],
          users: new Set(),
        };
      }

      acc[day].revisions.push(revision);
      acc[day].users.add(
        revision.user.firstName + " " + revision.user.lastName,
      );
      return acc;
    },
    {},
  );

  return Object.values(grouped).sort(
    (a, b) => new Date(b.day).getTime() - new Date(a.day).getTime(),
  );
};

export default historySlice.reducer;
