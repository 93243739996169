import { Checkbox, Form, FormInstance, Input, InputRef, Modal } from "antd";
import React, { FC, useRef, useState } from "react";
import { dictionaryApi } from "../../../../api/dictionary.api";
import useSetFocus from "../../../../hooks/useSetFocus";
import useSubmitFormOnEnter from "../../../../hooks/useSubmitFormOnEnter";
import { getSteps, TStep } from "../../../../store/slices/stepsSlice";
import { TStepFormValues } from "../../../campaignSteps/components/CampaignSteps";
import SearchableMultiSelect from "../../SearchableMultiSelect";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import { minMax } from "../../../../utils/validations";
import micrositeContextFolderTooltip from "../../../../constants/micrositeContextFolderTooltip";
import { MICROSITE_INPUT_PLACEHOLDER } from "../../../../constants";
import customizeFormLabels from "../../../../utils/customizeFormLabels";
import {
  stringToUrlName,
  validateUniqueNamesWithReplace,
} from "../../../../utils/cm.utils";

type Props = {
  editStep: TStep | null;
  isModalOpen: boolean;
  form: FormInstance<TStepFormValues>;
  onCancel: () => void;
  onSubmitForm: () => Promise<void>;
};

const StepModal: FC<Props> = ({
  editStep,
  isModalOpen,
  form,
  onCancel,
  onSubmitForm,
}) => {
  const { companyId } = useParams();
  const steps = getSteps();
  const inputRef = useRef<InputRef>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [classes, setClasses] = useState<string[]>(editStep?.classes || []);
  const { hasStepShowHiddenRole, hasDictionarySearchRole } =
    useUserHasPermission({ companyId });

  const onSaveStep = async () => {
    setIsFetching(true);
    await onSubmitForm();
    setIsFetching(false);
  };

  useSubmitFormOnEnter(onSaveStep, { condition: !isFetching });
  useSetFocus(inputRef);

  const fetchClasses = async (word: string) => {
    const { data } = await dictionaryApi({ dictionaryType: "CLASS" }).find({
      wordMatch: `*${word}*`,
    });

    return data.map((item) => ({ label: item, value: item }));
  };

  const handleSelectClasses = (classes: string[]) => {
    form.setFieldValue("classes", classes);
    setClasses(classes);
  };

  return (
    <Modal
      title={editStep ? "Edit Step" : "Add Step"}
      open={isModalOpen}
      onOk={onSaveStep}
      confirmLoading={isFetching}
      okText={editStep ? "Update" : "Save"}
      width={600}
      onCancel={onCancel}
    >
      <Form
        requiredMark={customizeFormLabels}
        form={form}
        layout="vertical"
        name="step_form"
        initialValues={
          !editStep
            ? {
                // eslint-disable-next-line no-template-curly-in-string
                microSiteContextFolder: "${step.name}",
              }
            : undefined
        }
      >
        <Form.Item
          name="name"
          label="Step Name"
          tooltip="Please enter a string up to 255 characters long"
          rules={[
            {
              validator: (_, value) => {
                const errorMessage = validateUniqueNamesWithReplace({
                  names: steps.map(({ name }) => ({
                    originalName: name,
                    transformedName: stringToUrlName(name).toLowerCase(),
                  })),
                  value: value || "",
                  editName: editStep?.name,
                });

                if (errorMessage) {
                  return Promise.reject(new Error(errorMessage));
                }
                return Promise.resolve();
              },
            },
            {
              type: "string",
              required: true,
              whitespace: true,
              message: "Required field!",
            },
            {
              max: 255,
              message: "Step name must contain no more than 255 characters!",
            },
          ]}
        >
          <Input
            className="h-[48px]"
            placeholder="Enter Step name"
            ref={inputRef}
          />
        </Form.Item>
        <Form.Item
          name="microSiteContextFolder"
          label="Microsite Context Folder"
          tooltip={micrositeContextFolderTooltip}
          rules={[minMax({ max: 255, text: "Path" })]}
        >
          <Input
            className="h-[48px]"
            placeholder={MICROSITE_INPUT_PLACEHOLDER}
          />
        </Form.Item>

        {hasDictionarySearchRole && (
          <Form.Item name="classes" label="Classes" valuePropName="checked">
            <SearchableMultiSelect
              fetchOptions={fetchClasses}
              onSelect={handleSelectClasses}
              value={classes}
              // className="h-[48px]"
            />
          </Form.Item>
        )}

        {hasStepShowHiddenRole && (
          <Form.Item name="hidden" valuePropName="checked">
            <Checkbox>Switch visibility</Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default StepModal;
