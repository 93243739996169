import React, { FC, useState } from "react";
import {
  TCcVariable,
  TCcVarsFormValues,
} from "../../../store/slices/ccVariablesSlice";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { updateCcVarApi } from "../../../api/cc-variables.api";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import saveCCItemWithRepublish from "../../../utils/saveCCItemWithRepublish";
import { Button, Form, Input, Skeleton } from "antd";
import customizeFormLabels from "../../../utils/customizeFormLabels";
import DynamicCCForm from "../../dynamicForms/DynamicCCForm";
import { OnAfterCCItemUpdate } from "../types";
import useGetModelData from "../hooks/useGetModelData";

type Props = {
  ccItem: TCcVariable;
  ccItemPhaseId: number;
  onAfterCCItemUpdate: OnAfterCCItemUpdate;
};

const ModelTabContent: FC<Props> = ({
  ccItem,
  ccItemPhaseId,
  onAfterCCItemUpdate,
}) => {
  const { id, seq, type, result, modifiedTime, options } = ccItem;
  const { form, dynamicItems, stepCCVars, initialized } =
    useGetModelData(ccItem);
  const [loading, setLoading] = useState(false);
  // const { openConflictModal, conflictModalContext } = useCCItemConflictModal();
  const messageApi = useSelector(selectMessageApi);

  // const setFormValues = (ccVariable: TCcVariable) => {
  //   const { id, type, result, modifiedTime, options } = ccVariable;
  //
  //   form.setFieldsValue({
  //     key: id.key,
  //     type,
  //     result,
  //     modifiedTime,
  //     ...options,
  //   });
  // };

  const onSaveModel = async ({
    values,
    rebuild = false,
  }: {
    rebuild?: boolean;
    values: TCcVarsFormValues;
  }) => {
    try {
      setLoading(true);

      const { data } = await updateCcVarApi({
        ccItem,
        values,
        rebuild,
      });

      await onAfterCCItemUpdate(data);

      messageApi.success("The model was successfully saved");
    } catch (e: any) {
      // if (e.response && e.response.status === 409 && ccItem) {
      //   const actualItem: TCcVariable = e.response.data;
      //   const onApplyActualCCItem = () => {
      //     setItem(actualItem);
      //     setFormValues(actualItem);
      //   };
      //
      //   openConflictModal({
      //     currentItem: ccItem,
      //     actualItem,
      //     onApplyActualCCItem,
      //   });
      // } else {
      //   const customError = handleRequestError(e);
      //   messageApi.error(customError.message);
      //   console.error(customError);
      // }
    } finally {
      setLoading(false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TCcVarsFormValues) => {
      const postProcessingParams = values.postProcessingParams;
      if (postProcessingParams) {
        values.postProcessingParams = JSON.stringify(postProcessingParams);
      }

      await saveCCItemWithRepublish({
        values,
        saveCCItem: onSaveModel,
        ccItem,
      });
    },
  });

  if (!initialized) {
    return <Skeleton active />;
  }

  if (dynamicItems.length === 0) {
    return <span className="text-red-600">Failed to load data</span>;
  }

  return (
    <div className="flex flex-col gap-6">
      <Form
        form={form}
        layout="vertical"
        name="cc_item_edit_drawer"
        size="small"
        requiredMark={customizeFormLabels}
        initialValues={{
          //set initial form values for dynamic items
          ...options,
        }}
      >
        <Form.Item name="type" noStyle initialValue={type}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="key" noStyle initialValue={id.key}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="result" noStyle initialValue={result}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="modifiedTime" noStyle initialValue={modifiedTime}>
          <Input type="hidden" />
        </Form.Item>

        <DynamicCCForm
          hidden={false}
          form={form}
          items={dynamicItems}
          ccItem={ccItem}
          gridItemSequence={seq}
          campaignId={id.campaignId}
          stepId={id.stepId}
          phaseId={ccItemPhaseId}
          stepCCVars={stepCCVars}
        />
      </Form>
      <Button
        size="large"
        type="primary"
        className="font-semibold text-sm !px-8 self-end"
        loading={loading}
        onClick={submitForm}
        shape="round"
      >
        Save
      </Button>

      {/*{conflictModalContext}*/}
    </div>
  );
};

export default ModelTabContent;
