import { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import { AxiosError, AxiosRequestConfig } from "axios";
import axiosInstance from "./axiosInstance";
import handleRequestError, { TCustomError } from "../handleRequestError";

export const axiosBaseQuery =
  (): BaseQueryFn<
    AxiosRequestConfig,
    unknown,
    TCustomError,
    AxiosRequestConfig
  > =>
  async (axiosConfig) => {
    try {
      const result = await axiosInstance(axiosConfig);
      return { data: result.data };
    } catch (axiosError) {
      return {
        error: handleRequestError(axiosError as AxiosError),
      };
    }
  };
