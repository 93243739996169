import { FC } from "react";
import { Button } from "../../common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../common/ui/dialog";

export const CreateNewChatAlert: FC<{
  showNewChatDialog: boolean;
  setShowNewChatDialog: (show: boolean) => void;
  handleConfirmNewChat: () => void;
}> = ({ showNewChatDialog, setShowNewChatDialog, handleConfirmNewChat }) => {
  return (
    <Dialog open={showNewChatDialog} onOpenChange={setShowNewChatDialog}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Chat</DialogTitle>
          <DialogDescription>
            Creating a new chat will deselect all currently selected CC
            variables. Do you want to continue?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={() => setShowNewChatDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleConfirmNewChat}>Continue</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
