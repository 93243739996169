import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { AppDispatch } from "../../../store/store";
import handleRequestError from "../../../utils/handleRequestError";
import { createUserApi, getUsersListApi } from "../../../api/user.api";
import { TUserForm, TUsersListItem } from "../../../globalTypes";
import useNavigateWithOutletRedirect from "../../../hooks/navigation/useNavigateWithOutletRedirect";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useUsersSearchParams } from "./useUsersSearchParams";

type UsersData = { items: TUsersListItem[]; totalItems: number };

const useUsersList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = useSelector(selectMessageApi);
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();
  const { hasUserReadRole } = useUserHasPermission({});
  const [isLoading, setIsLoading] = useState(true);
  const [usersData, setUsersData] = useState<UsersData>({
    items: [],
    totalItems: 0,
  });
  const { pageNumber, pageSize, search, updateSearchParams } =
    useUsersSearchParams();

  const loadUsers = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data } = await getUsersListApi({
        pageSize,
        pageNumber,
        search,
      });

      setUsersData(data);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  }, [pageNumber, pageSize, search, dispatch, messageApi]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  // useEffect(() => {
  //   loadUsers({ pageNumber, pageSize, search });
  // }, [pageNumber, pageSize, search]);
  //
  // const loadUsers = async ({
  //   pageNumber,
  //   pageSize,
  //   search,
  // }: {
  //   pageNumber: number;
  //   pageSize: number;
  //   search: string;
  // }) => {
  //   try {
  //     setIsLoading(true);
  //
  //     const { data } = await getUsersListApi({
  //       pageSize,
  //       pageNumber,
  //       search,
  //     });
  //
  //     const { items, totalItems } = data;
  //     dispatch(setUsers({ users: items, total: totalItems }));
  //   } catch (e: any) {
  //     const customError = handleRequestError(e);
  //
  //     messageApi.error(customError.message);
  //     console.error(customError);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const updatePage = (pageNumber: number) => {
    updateSearchParams({ pageNumber });
  };

  const updatePageSize = (pageSize: number) => {
    updateSearchParams({ pageNumber: 1, pageSize });
  };

  const updateSearch = (search: string) => {
    updateSearchParams({ pageNumber: 1, search });
  };

  const onCreateUser = async ({ values }: { values: TUserForm }) => {
    const { data: newUser } = await createUserApi({ userData: values });

    if (hasUserReadRole) {
      navigateWithOutletRedirect(`/users/${newUser.id}/details`);
    } else {
      await loadUsers();
    }
  };

  return {
    users: usersData.items,
    isLoading,
    total: usersData.totalItems,
    pageSize,
    pageNumber,
    search,
    updatePage,
    updatePageSize,
    updateSearch,
    onCreateUser,
    loadUsers,
  };
};
export default useUsersList;
