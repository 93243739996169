import { Spin } from "antd";
import React from "react";
import { useGetAssets } from "../hooks/useGetAssets";
import { ActionButtons } from "./action-buttons";
import { AssetsTable } from "./assets-table";

export const Assets = () => {
  const { isFetching, loadAssets, assets, setAssets } = useGetAssets();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <h3 className="text-[#0F172A] font-instrument font-semibold">Assets</h3>
        <ActionButtons loadAndSetAssets={loadAssets} />
      </div>

      <Spin spinning={isFetching}>
        <AssetsTable
          assets={assets}
          loadAndSetAssets={loadAssets}
          setAssets={setAssets}
        />
      </Spin>
    </div>
  );
};
