import React, { FC, useState } from "react";
import { Button } from "../../common/ui/button";
import dayjs from "dayjs";
import ExecuteCcItemModal from "../../common/modals/ExecuteCCItemModal";
import { CcItemInfo } from "../types";
import { NavLink } from "react-router-dom";
import { CampaignViewTypes } from "../../../types";
import CCItemStateIcon from "../../common/CCItemStateIcon";
import LastModifiedBy from "./LastModifiedBy";
import XMarkButton from "../../common/XMarkButton";

type Props = {
  onDrawerClose: () => void;
  ccItem: CcItemInfo;
  hasCampaignCcItemComputationRole: boolean;
  companyId: string;
};
const EditorHeader: FC<Props> = ({
  onDrawerClose,
  ccItem,
  hasCampaignCcItemComputationRole,
  companyId,
}) => {
  const [isExecuteOpen, setIsExecuteOpen] = useState(false);

  const {
    item: {
      id: { key, campaignId, stepId },
      modifiedTime,
      type,
      state,
      modifiedBy,
    },
    campaignName,
    phaseName,
    stepName,
    phaseId,
  } = ccItem;
  const lastModifiedTime = dayjs(modifiedTime).format("MMM D, YYYY h:mm:ss A");
  const keyPath = `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}?view=${CampaignViewTypes.GRID}#${key}`;

  return (
    <div className="flex flex-col gap-4">
      <div className="h-[40px] flex items-center justify-between">
        <div className="flex items-center gap-4">
          <XMarkButton onClick={onDrawerClose} />

          <div className="flex gap-3 items-center font-semibold">
            <div className="text-[#0F172A] text-xl">{key}</div>
            <div className="px-2 pt-1 pb-[6px] bg-[#F1F5F9] rounded-xl text-[#3C4E63] text-xs">
              {type}
            </div>
            <CCItemStateIcon state={state} />
          </div>
        </div>

        {hasCampaignCcItemComputationRole && (
          <Button
            variant="primaryOutline"
            className="rounded-full"
            size="sm"
            onClick={() => setIsExecuteOpen(true)}
          >
            Execute
          </Button>
        )}
      </div>

      <div className="flex justify-between items-center gap-4">
        <div className="flex items-center gap-1 text-[#3C4E63] text-[13px] font-medium">
          <span>{campaignName}</span>
          <Divider />
          <span>{phaseName}</span>
          <Divider />
          <span>{stepName}</span>
          <Divider />
          <NavLink
            to={keyPath}
            target="_blank"
            className="text-veSecondary hover:text-vePrimary transition-colors"
          >
            {key}
          </NavLink>
        </div>

        <div className="flex gap-1 items-center text-[#3C4E63] text-[13px] font-normal">
          <span>Last Modified:</span>
          <span>{lastModifiedTime}</span>
          <LastModifiedBy userId={modifiedBy} />
        </div>
      </div>

      {hasCampaignCcItemComputationRole &&
        campaignId &&
        stepId &&
        isExecuteOpen && (
          <ExecuteCcItemModal
            ccItemKey={key}
            closeModal={() => setIsExecuteOpen(false)}
            isOpen={isExecuteOpen}
            stepId={stepId.toString()}
            campaignId={campaignId.toString()}
            showDownstream={false}
          />
        )}
    </div>
  );
};

export default EditorHeader;

const Divider = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.78118 8.00047L5.48132 4.70062L6.42414 3.75781L10.6668 8.00047L6.42414 12.2431L5.48132 11.3003L8.78118 8.00047Z"
        fill="#3C4E63"
      />
    </svg>
  );
};
