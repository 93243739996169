import React, { FC } from "react";
import { CampaignType } from "../types";
import { Select } from "antd";
import { CAMPAIGN_TYPE_OPTIONS } from "../constants";

type Props = {
  type: CampaignType;
  setCampaignTypeParam: (value: CampaignType) => void;
};

export const CampaignTypeSelector: FC<Props> = ({
  type,
  setCampaignTypeParam,
}) => {
  return (
    <Select
      placeholder="Select a type"
      className="w-[160px] h-10"
      value={type}
      onChange={setCampaignTypeParam}
      options={CAMPAIGN_TYPE_OPTIONS}
      dropdownStyle={{ width: 180 }}
      dropdownRender={(menu) => (
        <>
          <div className="text-sm font-semibold py-1.5 pl-2">
            Select Campaign Type
          </div>
          {menu}
        </>
      )}
    />
  );
};
