import React, { FC, useMemo } from "react";
import { Spin } from "antd";
import { DOWNSTREAM_DEPS_MODAL_GRAPH_HEIGHT } from "../../constants";
import DownstreamGraph from "./DownstreamGraph";
import useGetNodesAndEdges from "../../hooks/downstreamDeps/useGetNodesAndEdges";
import { DefaultNode, GroupNode } from "./CustomGraphNodes";
import { CcItemEditor } from "../../../ccItemEditor";
import useDownstreamViewQueryParams from "../../hooks/downstreamDeps/useDownstreamViewQueryParams";
import { CcItemEditorData } from "../../../ccItemEditor/types";
import { useParams } from "react-router-dom";

const nodeTypes = {
  group: GroupNode,
  default: DefaultNode,
};

type Props = {
  campaignId: string;
  stepId: string;
  ccItemKey: string;
};

const DownstreamView: FC<Props> = ({ campaignId, ccItemKey, stepId }) => {
  const {
    nodes,
    initialNodeId,
    isInitialized,
    edges,
    onNodesChange,
    onEdgesChange,
    onNodeClick,
  } = useGetNodesAndEdges({
    campaignId,
    ccItemKey,
    stepId,
  });
  const { companyId } = useParams();
  const {
    params: { editKey, editCampaignId, editStepId },
    clearParams,
  } = useDownstreamViewQueryParams();
  const editDataProps: CcItemEditorData | null = useMemo(() => {
    if (editKey && editCampaignId && editStepId) {
      return {
        ccItemKey: editKey,
        campaignId: editCampaignId,
        stepId: editStepId,
      };
    }

    return null;
  }, [editKey, editCampaignId, editStepId]);

  if (!companyId) {
    return (
      <span className="text-red-600">
        An error occurred while loading data. Please report the issue and try
        again later.
      </span>
    );
  }

  const onDrawerClose = () => {
    clearParams();
  };

  return (
    <div className="w-full h-full">
      <Spin spinning={!isInitialized} tip="Loading the data...">
        <div
          className="w-full border-2"
          style={{ height: `${DOWNSTREAM_DEPS_MODAL_GRAPH_HEIGHT}px` }}
        >
          <DownstreamGraph
            nodes={nodes}
            edges={edges}
            initialNodeId={initialNodeId}
            isInitialized={isInitialized}
            nodeTypes={nodeTypes}
            onNodeClick={onNodeClick}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
          />
        </div>
      </Spin>

      <CcItemEditor
        editDataProps={editDataProps}
        onAfterClose={onDrawerClose}
        companyId={companyId}
      />
    </div>
  );
};

export default DownstreamView;
