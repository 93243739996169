import React, { FC, useState } from "react";
import { UserPermissions } from "../../../store/slices/userData/constants";
import {
  DownloadIcon,
  GlobalStorageIcon,
  Icons,
  LabelsIcon,
  MiniFolderIcon,
} from "../../common/Icons";
import MenuDropdown, { MenuDropdownItem } from "../../common/MenuDropdown";
import { useNavigate } from "react-router-dom";
import useNavigateWithOutletRedirect from "../../../hooks/navigation/useNavigateWithOutletRedirect";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { Button } from "../../common/ui/button";
import { ImportCampaignModal } from "./import-campaign-modal";

type Props = {
  companyId: number | null;
};

export const ActionsMenu: FC<Props> = ({ companyId }) => {
  const navigate = useNavigate();
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const {
    hasG18nListRole,
    hasPublicRootFsFileListRole,
    hasPrivateRootFsFileListRole,
    hasPermissionAnywhere,
  } = useUserHasPermission({});
  const {
    hasPublicFsFileListRole,
    hasPrivateFsFileListRole,
    hasMsFsFileListRole,
    hasCampaignImportRole,
  } = useUserHasPermission({ companyId });

  const actionsItems = [
    hasPermissionAnywhere(UserPermissions.ROLE_CAMPAIGN_IMPORT) && {
      key: "import",
      label: "Import Campaign",
      icon: DownloadIcon,
      disabled: companyId === null || !hasCampaignImportRole,
      onClick: () => setIsImportModalOpen(true),
    },
    hasG18nListRole && {
      key: "globalLabels",
      label: "Global Labels",
      icon: LabelsIcon,
      onClick: () => navigate("/campaigns/globalLabels"),
    },
    (hasPublicRootFsFileListRole || hasPrivateRootFsFileListRole) && {
      key: "globalStorage",
      label: "Global Storage",
      icon: GlobalStorageIcon,
      onClick: () => navigateWithOutletRedirect("/campaigns/storage"),
    },
    (hasPermissionAnywhere(UserPermissions.ROLE_PUBLIC_FS_FILE_LIST) ||
      hasPermissionAnywhere(UserPermissions.ROLE_PRIVATE_FS_FILE_LIST) ||
      hasPermissionAnywhere(UserPermissions.ROLE_MS_FS_FILE_LIST)) && {
      key: "companyStorage",
      label: "Company Storage",
      icon: MiniFolderIcon,
      disabled:
        companyId === null ||
        !hasPublicFsFileListRole ||
        !hasPrivateFsFileListRole ||
        !hasMsFsFileListRole,
      onClick: () =>
        navigateWithOutletRedirect(`/campaigns/company/${companyId}/storage`),
    },
  ].filter(Boolean) as MenuDropdownItem[];

  return (
    <>
      {!!actionsItems.length && (
        <MenuDropdown items={actionsItems} dropdownClassName="w-[190px]">
          <Button variant="primaryOutline" className="text-sm font-medium">
            Actions
            <Icons.More className="size-4 ml-2" />
          </Button>
        </MenuDropdown>
      )}

      {isImportModalOpen && companyId !== null && (
        <ImportCampaignModal
          setIsModalOpen={setIsImportModalOpen}
          isModalOpen={isImportModalOpen}
          companyId={companyId}
        />
      )}
    </>
  );
};
