import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TCompanyShortInfo, TMicroSite } from "../../globalTypes";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import { getCompanyDataApi } from "../../api/companies.api";
import { getMicrositeApi } from "../../api/microsite.api";
import { Campaign } from './campaignsSlice';
import { getCampaignDataApi } from "../../api/campaigns.api";

const initialState = {
  companyData: null as TCompanyShortInfo | null,
  companyMicroSiteData: null as TMicroSite | null,
  campaignData: null as Campaign | null,
};

type InitialStateType = typeof initialState;

const companyStorageSlice = createSlice({
  name: "companyStorage",
  initialState,
  reducers: {
    setCompanyData: (
      state: InitialStateType,
      action: PayloadAction<TCompanyShortInfo>,
    ) => {
      state.companyData = action.payload;
    },
    setCompanyMicroSiteData: (
      state: InitialStateType,
      action: PayloadAction<TMicroSite | null>,
    ) => {
      state.companyMicroSiteData = action.payload;
    },
    setCampaignData: (
      state: InitialStateType,
      action: PayloadAction<Campaign>,
    ) => {
      state.campaignData = action.payload;
    },
    cleanUpCompanyStorageData: () => initialState,
  },
});

export const {
  setCompanyData,
  setCompanyMicroSiteData,
  setCampaignData,
  cleanUpCompanyStorageData,
} = companyStorageSlice.actions;

export default companyStorageSlice.reducer;

export const selectCompanyStorageCompanyData = ({
  companyStorage,
}: RootState) => companyStorage.companyData;
export const selectCompanyStorageMicroSiteData = ({
  companyStorage,
}: RootState) => companyStorage.companyMicroSiteData;
export const selectCompanyStorageCampaignData = ({
  companyStorage,
}: RootState) => companyStorage.campaignData;

type LoadCompanyStorageDataRes = [
  { data: TCompanyShortInfo },
  { data: TMicroSite | null },
  { data: Campaign } | undefined,
];

export const loadCompanyStorageDataThunk = createAsyncThunk<
  undefined,
  { companyId: string; campaignId?: string },
  { rejectValue: TCustomError }
>(
  "companyStorage/loadCompanyStorageDataThunk",
  async ({ companyId, campaignId }, { rejectWithValue, dispatch }) => {
    try {
      const requests: Promise<any>[] = [
        getCompanyDataApi({ companyId }),
        getMicrositeApi({ companyId }),
      ];

      if (campaignId) {
        requests.push(getCampaignDataApi({ campaignId }));
      }

      const [{ data: companyData }, { data: microSite }, campaignDataResponse] =
        (await Promise.all(requests)) as LoadCompanyStorageDataRes;

      dispatch(setCompanyData(companyData));
      dispatch(setCompanyMicroSiteData(microSite));

      if (campaignId && campaignDataResponse) {
        dispatch(setCampaignData(campaignDataResponse.data));
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(e);

      return rejectWithValue(customError);
    }
  },
);
