import { Link } from "react-router-dom";
import { FOOTER_LIST } from "../constants";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="w-full h-[106px]">
      <div className="w-full h-[106px]">
        <div className="w-full h-[106px] bg-white border-t border-slate-200 flex justify-between items-center px-12">
          <div className="h-8 justify-start items-center gap-2 inline-flex">
            <div className="w-8 h-8">
              <img src={"/icon.svg"} alt="logo" className="h-8" />
            </div>
            <div className="text-[#64748B] text-sm font-medium font-sans">
              Copyright © {currentYear} VelocityEngine Inc.
            </div>
          </div>
          <div className="h-2.5 justify-end items-center gap-8 inline-flex">
            {FOOTER_LIST.map(({ path, label }) => {
              return (
                <Link
                  key={path}
                  to={path}
                  className="text-[#64748B] text-sm font-medium font-sans cursor-pointer"
                  target="_blank"
                >
                  {label}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="w-full h-1 bg-gradient-to-tr from-purple-700 to-rose-600" />
      </div>
    </div>
  );
};

export default Footer;
