import React from "react";
import { useParams } from "react-router-dom";
import { PageHeader } from "../../components/common/PageHeader";
import { ReactFlowProvider } from "reactflow";
import useConnectSocket from "../../store/slices/computationMessages/hooks/useConnectSocket";
import EventsLog from "../../components/EventsLog/EventsLog";
import { DownstreamView } from "../../components/graph";

const DownstreamGraph = () => {
  const { campaignId, stepId, key } = useParams();

  useConnectSocket();

  if (!campaignId || !stepId || !key) {
    return <span>error!</span>;
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <PageHeader title="Downstream Dependencies" showBackButton={true} />
        <EventsLog />
      </div>

      <ReactFlowProvider>
        <DownstreamView
          campaignId={campaignId}
          stepId={stepId}
          ccItemKey={key}
        />
      </ReactFlowProvider>
    </div>
  );
};

export default DownstreamGraph;
