import React, { FC, useEffect, useState } from "react";
import { useBoolean } from "../../../hooks/useBoolean";
import handleRequestError from "../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { TUser } from "../../../globalTypes";
import { getUserDataApi } from "../../../api/user.api";
import { Skeleton } from "antd";

type Props = {
  userId: number | null;
};
const LastModifiedBy: FC<Props> = ({ userId }) => {
  const messageApi = useSelector(selectMessageApi);
  const [loading, startLoading, stopLoading] = useBoolean(false);
  const [user, setUser] = useState<TUser | null>(null);

  useEffect(() => {
    if (userId !== null) {
      loadUserData(userId);
    }
  }, [userId]);

  const loadUserData = async (userId: number) => {
    try {
      startLoading();

      const { data } = await getUserDataApi({ userId });

      setUser(data);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      stopLoading();
    }
  };

  if (loading) {
    return (
      <Skeleton
        active
        title={false}
        className="w-[100px] flex items-center"
        paragraph={{ rows: 1, width: 100 }}
      />
    );
  }

  if (!user) return null;

  const userFullName = `${user.firstName} ${user.lastName}`;

  return <span>by {userFullName}</span>;
};

export default LastModifiedBy;
