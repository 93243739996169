import React, { FC, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, InputRef, Modal, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import useSetFocus from "../../../hooks/useSetFocus";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { updateAssetApi } from "../../../api/assets.api";
import handleRequestError from "../../../utils/handleRequestError";
import { getConfirmationMessage } from "../../../utils/getConfirmationMessage";
import { SelectMicrositeStorageFolder } from "./select-microsite-storage-folder";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import customizeFormLabels from "../../../utils/customizeFormLabels";
import {
  minMax,
  required,
  validateMicrositeAssetPath,
} from "../../../utils/validations";
import micrositeContextFolderTooltip from "../../../constants/micrositeContextFolderTooltip";
import { MICROSITE_INPUT_PLACEHOLDER } from "../../../constants";
import { AssetFormValues, SelectMicrositeFolderType } from "../types";
import { useGetAsset } from "../hooks/useGetAsset";
import { AssetListItem } from "../../../globalTypes";

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  setAssets: React.Dispatch<React.SetStateAction<AssetListItem[]>>;
  assetId: number;
};

export const EditAssetModal: FC<Props> = ({
  isModalOpen,
  closeModal,
  assetId,
  setAssets,
}) => {
  const { companyId } = useParams();
  const inputRef = useRef<InputRef>(null);
  const messageApi = useSelector(selectMessageApi);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<AssetFormValues>();
  const { loading, asset } = useGetAsset({ assetId, closeModal });
  const formValues = Form.useWatch([], form);
  const initialFormValues: Partial<AssetFormValues> = useMemo(() => {
    if (!asset) return {};

    return {
      title: asset.title,
      microSiteTargetFolder: asset.microSiteTargetFolder || "",
      ref: asset.ref || "",
    };
  }, [asset]);

  const isFormChanged =
    JSON.stringify(formValues) !== JSON.stringify(initialFormValues);

  useSetFocus(inputRef);
  useSubmitFormOnEnter(() => onFormSubmit(), {
    condition: !isLoading && isFormChanged,
  });

  const onUpdateAsset = async (values: AssetFormValues) => {
    if (!asset) {
      messageApi.error("Unable to get the asset data");
      return;
    }

    try {
      setIsLoading(true);

      const isFolderContextChanged =
        values.microSiteTargetFolder !== asset.microSiteTargetFolder;

      const updateAsset = async () => {
        try {
          const updatedAsset = {
            ...asset,
            ...values,
          };

          const { data } = await updateAssetApi(updatedAsset);

          setAssets((prevItems) =>
            prevItems.map((item) => (item.asset.id === assetId ? data : item)),
          );

          closeModal();

          messageApi.success("Asset has been successfully updated");
        } catch (e: any) {
          const customError = handleRequestError(e);
          messageApi.error(customError.message);
          console.error(customError);
        } finally {
          setIsLoading(false);
        }
      };

      const confirmationMessage = getConfirmationMessage(
        isFolderContextChanged,
        false, // title field is disabled, so it cannot be changed
        "Asset Title",
      );

      if (confirmationMessage && asset.publishedState === "PUBLISHED") {
        Modal.confirm({
          title: "Confirm asset change",
          content: (
            <span>
              Microsite Folder has changed. The published resource will be
              available at the new path.
            </span>
          ),
          okText: "Yes",
          cancelText: "Cancel",
          onOk: updateAsset,
        });
      } else {
        await updateAsset();
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const insertFolderData: SelectMicrositeFolderType = (path: string) => {
    form.setFieldsValue({
      microSiteTargetFolder: path,
    });
  };

  const onFormSubmit = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: AssetFormValues) => {
      await onUpdateAsset(values);
    },
  });

  return (
    <Modal
      title="Edit Asset"
      open={isModalOpen}
      onOk={onFormSubmit}
      okText="Update"
      width={600}
      destroyOnClose
      okButtonProps={{
        loading: isLoading,
        disabled: !isFormChanged,
        shape: "round",
      }}
      cancelButtonProps={{ shape: "round" }}
      onCancel={closeModal}
    >
      {loading ? (
        <Skeleton active paragraph={{ rows: 8 }} />
      ) : (
        <Form
          form={form}
          layout="vertical"
          requiredMark={customizeFormLabels}
          name="edit_asset_form"
          initialValues={initialFormValues}
        >
          <Form.Item
            name="title"
            label="Asset Title"
            tooltip="Please enter a string up to 255 characters long."
            rules={[required(), minMax({ max: 255, text: "Asset Title" })]}
          >
            <Input
              placeholder="Enter asset title"
              ref={inputRef}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label="Microsite Context Folder"
            tooltip={micrositeContextFolderTooltip}
          >
            <Form.Item
              name="microSiteTargetFolder"
              rules={[
                minMax({ max: 255, text: "Path" }),
                validateMicrositeAssetPath,
              ]}
              noStyle
            >
              <Input placeholder={MICROSITE_INPUT_PLACEHOLDER} />
            </Form.Item>
            {companyId && (
              <SelectMicrositeStorageFolder
                companyId={companyId}
                onSelect={insertFolderData}
              />
            )}
          </Form.Item>

          <Form.Item label="Asset Link" required>
            <Form.Item name="ref" noStyle rules={[required()]}>
              <Input placeholder="Enter asset link" disabled={true} />
            </Form.Item>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
