import axiosInstance from "../../utils/axios/axiosInstance";
import { CampaignEntitiesInfo, CampaignEntityType } from "./types";

export const getCampaignEntitiesInfoApi = ({
  entityType,
  entityId,
}: {
  entityType: CampaignEntityType;
  entityId: string;
}) => {
  return axiosInstance.get<CampaignEntitiesInfo>(
    `/api/secured/global/path`,
    {
      params: {
        entityType,
        entityId,
      },
    },
  );
};
