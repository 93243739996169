import React from "react";

export const BoldIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    className={className}
  >
    <path
      d="M5.333 7.333h3a1.667 1.667 0 1 0 0-3.333h-3v3.333Zm6.667 3a3 3 0 0 1-3 3H4V2.667h4.333a3 3 0 0 1 2.171 5.07A2.999 2.999 0 0 1 12 10.333ZM5.333 8.667V12H9a1.667 1.667 0 0 0 0-3.333H5.333Z"
      fill="currentColor"
    />
  </svg>
);

export const ItalicIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    className={className}
  >
    <path
      d="M10 13.333H4.667V12h1.95l1.411-8H6V2.667h5.333V4h-1.95l-1.411 8H10v1.333Z"
      fill="currentColor"
    />
  </svg>
);

export const StrikeIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    className={className}
  >
    <path
      d="M11.436 9.333c.154.344.23.726.23 1.147 0 .895-.348 1.594-1.047 2.098-.698.504-1.663.755-2.895.755-1.093 0-2.175-.254-3.247-.763v-1.503c1.013.585 2.05.878 3.111.878 1.7 0 2.554-.489 2.56-1.465 0-.417-.145-.773-.433-1.068a2.456 2.456 0 0 0-.08-.079H2V8h12v1.333h-2.564Zm-2.719-2H5.086a2.724 2.724 0 0 1-.32-.348c-.289-.371-.433-.821-.433-1.35 0-.824.31-1.525.932-2.102.621-.578 1.582-.866 2.883-.866.98 0 1.92.218 2.815.656v1.434a5.203 5.203 0 0 0-2.631-.686c-1.653 0-2.48.52-2.48 1.564 0 .28.146.524.437.732.29.209.649.375 1.075.5.414.12.865.276 1.353.466Z"
      fill="currentColor"
    />
  </svg>
);

export const AlignRightIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    className={className}
  >
    <path
      d="M2 2.667h12V4H2V2.667Zm2.667 10H14V14H4.667v-1.333ZM2 9.333h12v1.334H2V9.333ZM4.667 6H14v1.333H4.667V6Z"
      fill="currentColor"
    />
  </svg>
);

export const AlignCenterIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    className={className}
  >
    <path
      d="M2 2.667h12V4H2V2.667Zm1.333 10h9.334V14H3.333v-1.333ZM2 9.333h12v1.334H2V9.333ZM3.333 6h9.334v1.333H3.333V6Z"
      fill="currentColor"
    />
  </svg>
);

export const AlignLeftIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    className={className}
  >
    <path
      d="M2 2.667h12V4H2V2.667Zm0 10h9.333V14H2v-1.333Zm0-3.334h12v1.334H2V9.333ZM2 6h9.333v1.333H2V6Z"
      fill="currentColor"
    />
  </svg>
);

export const OrderedListIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    className={className}
  >
    <path
      d="M5.333 2.667H14V4H5.333V2.667Zm-2-.667v2H4v.667H2V4h.667V2.667H2V2h1.333ZM2 9.333V7.667h1.333v-.334H2v-.666h2v1.666H2.667v.334H4v.666H2ZM3.333 13H2v-.667h1.333V12H2v-.667h2V14H2v-.667h1.333V13Zm2-5.667H14v1.334H5.333V7.333Zm0 4.667H14v1.333H5.333V12Z"
      fill="currentColor"
    />
  </svg>
);

export const UnorderedListIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    className={className}
  >
    <path
      d="M5.333 2.667H14V4H5.333V2.667ZM3 4.333a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM3 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 4.6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm2.333-6.267H14v1.334H5.333V7.333Zm0 4.667H14v1.333H5.333V12Z"
      fill="currentColor"
    />
  </svg>
);
