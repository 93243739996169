import clsx from "clsx";
import React, { FC } from "react";
import { TableCell, TableRow } from "../common/ui/table";
import { Badge } from "../common/ui/badge";
import { TUsersListItem } from "../../globalTypes";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { format } from "date-fns";
import useNavigateWithOutletRedirect from "../../hooks/navigation/useNavigateWithOutletRedirect";
import UserMenuTableCell from "./UserMenuTableCell";
import {
  deleteUserApi,
  disableUserApi,
  enableUserApi,
} from "../../api/user.api";

type Props = {
  item: TUsersListItem;
  loadUsers: () => Promise<void>;
};

const UsersListItem: FC<Props> = ({ item, loadUsers }) => {
  const { user, companies, allCompaniesAccess } = item;
  const { id, email, firstName, lastName, position, lastLoginTime, disabled } =
    user;
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();
  const { hasUserReadRole } = useUserHasPermission({});
  const userName = `${firstName} ${lastName}`;

  const onNavigateToUserDetails = () => {
    navigateWithOutletRedirect(`/users/${id}/details`);
  };

  const deleteUser = async () => {
    await deleteUserApi(id);
  };
  const enableUser = async () => {
    await enableUserApi({ userId: id });
  };
  const disableUser = async () => {
    await disableUserApi({ userId: id });
  };

  return (
    <>
      <TableRow
        key={id}
        className={clsx("relative text-[#475569] font-sans h-[48px]", {
          "opacity-30": disabled,
          "cursor-pointer": hasUserReadRole,
        })}
        onClick={hasUserReadRole ? onNavigateToUserDetails : undefined}
      >
        <TableCell className="font-medium text-[#0F172A]">{userName}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>
          {allCompaniesAccess ? (
            <Badge className={"!text-green-500 !bg-green-100"}>
              All Companies Access
            </Badge>
          ) : (
            companies.map(({ name }) => {
              return <div key={name}>{name}</div>;
            })
          )}
        </TableCell>
        <TableCell>{position}</TableCell>
        <TableCell>
          {lastLoginTime
            ? format(lastLoginTime, "MMMM d, yyyy h:mm a zzz")
            : "-"}
        </TableCell>
        <UserMenuTableCell
          updateUsers={loadUsers}
          userName={userName}
          disabled={disabled}
          onNavigateToUserDetails={onNavigateToUserDetails}
          deleteUser={deleteUser}
          enableUser={enableUser}
          disableUser={disableUser}
        />
      </TableRow>
    </>
  );
};

export default UsersListItem;
