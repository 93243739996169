import { ConfigProvider, message, Modal, Spin } from "antd";
import { usePostHog } from "posthog-js/react";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessageApi, setModalApi } from "../../store/slices/appSlice";
import {
  authenticateUserThunk,
  setIsAuthenticated,
} from "../../store/slices/userData/slice";
import { AppDispatch } from "../../store/store";
import localStorageProvider from "../../utils/localStorageProvider";
import { getDefaultRedirectUrlOnLogin } from "../../store/slices/userData/utils";

const InitializeApp: FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch: AppDispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const [modal, modalContext] = Modal.useModal();
  const posthog = usePostHog();
  const [initialDataIsFetched, setInitialDataIsFetched] = useState(false);
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();

  useEffect(() => {
    // Added to support old URLs with the substring “/admin” at the beginning.
    // Remove the “/admin” substring if it is in the URL.
    if (pathname.startsWith("/admin")) {
      const newPath = pathname.replace("/admin", "");
      navigate(newPath + search + hash, { replace: true });
    }
  }, [pathname]);

  useEffect(() => {
    authenticateUser();
  }, []);

  const authenticateUser = async () => {
    try {
      const { userEmail, userPermissions } = await dispatch(
        authenticateUserThunk(),
      ).unwrap();

      posthog?.identify(userEmail);

      const lastUrl = localStorageProvider.getLastUrl();
      const isSignInOrRoot = pathname === "/" || pathname === "/sign-in";
      const targetPath =
        lastUrl || getDefaultRedirectUrlOnLogin(userPermissions);

      if (isSignInOrRoot) {
        navigate(targetPath);
      }
    } catch (e) {
      localStorageProvider.setLastUrl(pathname + search + hash);

      dispatch(setIsAuthenticated(false));

      navigate("/sign-in");
    } finally {
      dispatch(setMessageApi(messageApi));
      dispatch(setModalApi(modal));
      setInitialDataIsFetched(true);
    }
  };

  if (!initialDataIsFetched) return <Spin spinning={true} fullscreen />;

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Instrument Sans",
          colorPrimary: "rgba(98, 60, 234, 1)",
          colorPrimaryHover: "rgba(98, 60, 234, 0.9)",
          colorPrimaryActive: "rgba(98, 60, 234, 0.8)",
        },
      }}
    >
      {messageContext}
      {modalContext}
      {children}
    </ConfigProvider>
  );
};

export default InitializeApp;
