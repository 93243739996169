import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { stringifyObjectValues } from "src/utils/stringifyObjectValues";

type UsersListSearchParams = {
  pageNumber: number;
  pageSize: number;
  search: string;
};

export const useUsersSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageNumber = Number(searchParams.get("pageNumber")) || 1;
  const pageSize = Number(searchParams.get("pageSize")) || 50;
  const search = searchParams.get("search") || "";

  useEffect(() => {
    const stringifiedParams = stringifyObjectValues({
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 10,
      search,
    });

    setSearchParams(stringifiedParams);
  }, [pageNumber, pageSize, search]);

  const updateSearchParams = (data: Partial<UsersListSearchParams>) => {
    const stringifiedParams = stringifyObjectValues({
      pageNumber,
      pageSize,
      search,
      ...data,
    });

    setSearchParams(stringifiedParams);
  };

  return {
    pageNumber,
    pageSize,
    search,
    updateSearchParams,
  };
};
