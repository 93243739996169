export const DOWNSTREAM_DEPS_MODAL_GRAPH_HEIGHT = window.innerHeight - 200;
export const UPSTREAM_DEPS_GRAPH_HEIGHT = ((window.innerHeight - 200) / 3) * 2;
export const NODE_DIMENSIONS = {
  width: 200,
  height: 40,
};
export const DOWNSTREAM_NODE_DIMENSIONS = {
  width: 300,
  height: 120,
};
export const NODE_PADDING = 30;
export const INIT_NODE_POSITION = { x: 0, y: 0 };
export const EDIT_KEY_SEARCH_PARAM = "editKey";
export const EDIT_CAMPAIGN_ID_SEARCH_PARAM = "editCampaignId";
export const EDIT_STEP_ID_SEARCH_PARAM = "editStepId";
