import { Button, Form, Input, Spin } from "antd";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { updateCcVarApi } from "../../../api/cc-variables.api";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  TCcVariable,
  TCcVarsFormValues,
} from "../../../store/slices/ccVariablesSlice";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import DynamicCCForm from "../../dynamicForms/DynamicCCForm";
import useGetDynamicData from "../hooks/useGetDynamicData";
import useCCItemConflictModal from "../../../hooks/useCCItemConflictModal.hook";
import saveCCItemWithRepublish from "../../../utils/saveCCItemWithRepublish";
import customizeFormLabels from "../../../utils/customizeFormLabels";
import { OnAfterCCItemUpdate } from "../types";

type Props = {
  ccItem: TCcVariable;
  nodeType: string;
  ccItemPhaseId: number;
  setItem: Dispatch<SetStateAction<TCcVariable | null>>;
  onAfterCCItemUpdate?: OnAfterCCItemUpdate;
};

const PaneDynamicForm: FC<Props> = ({
  ccItem,
  nodeType,
  ccItemPhaseId,
  setItem,
  onAfterCCItemUpdate,
}) => {
  const { id, seq, type, result, modifiedTime, options } = ccItem;
  const {
    ccVarData,
    form,
    dynamicItems,
    stepCCVars,
    isFetching,
    setIsFetching,
  } = useGetDynamicData(ccItem);
  const { openConflictModal, conflictModalContext } = useCCItemConflictModal();
  const messageApi = useSelector(selectMessageApi);

  const setFormValues = (ccVariable: TCcVariable) => {
    const { id, type, result, modifiedTime, options } = ccVariable;

    form.setFieldsValue({
      key: id.key,
      type,
      result,
      modifiedTime,
      ...options,
    });
  };

  const onSaveModel = async ({
    values,
    rebuild = false,
  }: {
    rebuild?: boolean;
    values: TCcVarsFormValues;
  }) => {
    try {
      setIsFetching(true);

      const { data } = await updateCcVarApi({
        ccItem,
        values,
        rebuild,
      });

      setItem(data);
      onAfterCCItemUpdate && (await onAfterCCItemUpdate(data));

      messageApi.success("The model was successfully saved");
    } catch (e: any) {
      if (e.response && e.response.status === 409 && ccVarData) {
        const actualItem: TCcVariable = e.response.data;
        const onApplyActualCCItem = () => {
          setItem(actualItem);
          setFormValues(actualItem);
        };

        openConflictModal({
          currentItem: ccVarData.variableData,
          actualItem,
          onApplyActualCCItem,
        });
      } else {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TCcVarsFormValues) => {
      const postProcessingParams = values.postProcessingParams;
      if (postProcessingParams) {
        values.postProcessingParams = JSON.stringify(postProcessingParams);
      }

      await saveCCItemWithRepublish({
        values,
        saveCCItem: onSaveModel,
        ccItem: ccVarData.variableData,
      });
    },
  });

  return (
    <Spin spinning={isFetching}>
      <Form
        form={form}
        layout="vertical"
        name={`cc_variable_graph_${nodeType}_form`}
        size="small"
        requiredMark={customizeFormLabels}
        initialValues={{
          //set initial form values for dynamic items
          ...options,
        }}
      >
        <Form.Item name="type" noStyle initialValue={type}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="key" noStyle initialValue={id.key}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="result" noStyle initialValue={result}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="modifiedTime" noStyle initialValue={modifiedTime}>
          <Input type="hidden" />
        </Form.Item>

        <DynamicCCForm
          hidden={false}
          form={form}
          items={dynamicItems}
          ccItem={ccVarData ? ccVarData.variableData : null}
          gridItemSequence={seq}
          campaignId={id.campaignId}
          stepId={id.stepId}
          phaseId={ccItemPhaseId}
          stepCCVars={stepCCVars}
        />
      </Form>
      <Button
        size="small"
        type="primary"
        className="absolute bottom-[8px] right-[8px]"
        onClick={submitForm}
        loading={isFetching}
      >
        Save Model
      </Button>
      {conflictModalContext}
    </Spin>
  );
};

export default PaneDynamicForm;
