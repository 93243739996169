import React, { FC, useState } from "react";
import { ReactFlowProvider } from "reactflow";
import { DownstreamDependenciesModal } from "../graph";
import { useParams } from "react-router-dom";
import { Button } from "./ui/button";

type Props = {
  ccItemKey: string;
};
const DownstreamDepsButton: FC<Props> = ({ ccItemKey }) => {
  const { campaignId, stepId } = useParams();
  const [isDownstreamOpened, setIsDownstreamOpened] = useState(false);

  if (!campaignId || !stepId) {
    return <span className="text-red-600">Error</span>;
  }

  return (
    <div>
      <Button
        onClick={() => setIsDownstreamOpened(true)}
        className="rounded-full"
        variant="primaryOutline"
        size="sm"
      >
        Show Downstream Dependencies
      </Button>

      {isDownstreamOpened && (
        <ReactFlowProvider>
          <DownstreamDependenciesModal
            campaignId={campaignId}
            stepId={stepId}
            ccItemKey={ccItemKey}
            setIsOpened={setIsDownstreamOpened}
            isOpened={isDownstreamOpened}
          />
        </ReactFlowProvider>
      )}
    </div>
  );
};

export default DownstreamDepsButton;
