import React, { FC, useState } from "react";
import { Alert, Button, Modal, Tooltip } from "antd";
import { createApiKeyApi } from "../api";
import handleRequestError from "../../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import { useBoolean } from "../../../../hooks/useBoolean";
import { CreateApiKeyRes } from "../types";
import Paragraph from "antd/lib/typography/Paragraph";
import { CheckmarkIcon, CopyIcon } from "../../../common/Icons";
import { InfoCircleOutlined } from "@ant-design/icons";

type Props = {
  loadApiKeys: () => Promise<void>;
};

const Header: FC<Props> = ({ loadApiKeys }) => {
  const messageApi = useSelector(selectMessageApi);
  const [isCreating, startCreating, stopCreating] = useBoolean(false);
  const [apiKeyData, setApiKeyData] = useState<CreateApiKeyRes | null>(null);

  const createApiKey = async () => {
    try {
      startCreating();

      const { data } = await createApiKeyApi();
      await loadApiKeys();

      setApiKeyData(data);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      stopCreating();
    }
  };

  return (
    <div className="flex items-center gap-4">
      <Button
        type="primary"
        shape="round"
        onClick={createApiKey}
        loading={isCreating}
      >
        Generate New Api Key
      </Button>

      <Tooltip
        overlayStyle={{
          maxWidth: "600px",
        }}
        title={
          <div className="flex flex-col gap-2">
            <span className="font-bold text-[14px]">Usage example:</span>
            <div className="font-mono bg-gray-100 text-red-600 px-1 py-0.5 rounded text-sm">
              {window.location.origin}/api/secured/user/auth?
              <br />
              api-key=
              <b>
                <i>YOUR_API_KEY</i>
              </b>
              &api-key-secret=
              <b>
                <i>YOUR_SECRET_KEY</i>
              </b>
            </div>
          </div>
        }
      >
        <InfoCircleOutlined className="text-veSecondary cursor-pointer" />
      </Tooltip>

      <Modal
        title="You have just generated a new token!"
        open={!!apiKeyData}
        onCancel={() => setApiKeyData(null)}
        width={600}
        footer={null}
      >
        <div className="flex flex-col gap-6">
          <Alert
            message={
              <>
                Make sure to copy your personal <b>API Secret Key</b> now.
                <br />
                You won’t be able to see it again!
              </>
            }
            type="info"
            showIcon
          />

          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <span className="font-bold text-[#475569] text-[14px]">
                API Key:
              </span>
              <Paragraph
                className="flex items-center !mb-0"
                copyable={{
                  icon: [
                    <CopyIcon key="copy-icon" />,
                    <CheckmarkIcon key="copied-icon" />,
                  ],
                  tooltips: ["Copy the key", "Copied!"],
                }}
              >
                {apiKeyData?.userApiKey.id.apiKey}
              </Paragraph>
            </div>

            <div className="flex gap-2">
              <span className="font-bold text-[#475569] text-[14px]">
                API Secret Key:
              </span>
              <Paragraph
                className="flex items-center !mb-0"
                copyable={{
                  icon: [
                    <CopyIcon key="copy-icon" />,
                    <CheckmarkIcon key="copied-icon" />,
                  ],
                  tooltips: ["Copy the key", "Copied!"],
                }}
              >
                {apiKeyData?.apiKeySecret}
              </Paragraph>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <span className="font-bold text-[#475569] text-[14px]">
              Usage example:
            </span>
            <span className="font-mono bg-gray-100 text-red-600 px-1 py-0.5 rounded text-sm">
              {window.location.origin}/api/secured/user/auth?api-key=
              {apiKeyData?.userApiKey.id.apiKey}&api-key-secret=
              {apiKeyData?.apiKeySecret}
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Header;
