import React, { FC } from "react";
import { Select } from "antd";
import { ArchiveStatus } from "../types";
import { CAMPAIGN_ARCHIVE_OPTIONS } from "../constants";

type Props = {
  archive: ArchiveStatus;
  setArchiveParam: (value: ArchiveStatus) => void;
};

export const ArchiveSelector: FC<Props> = ({ archive, setArchiveParam }) => {
  return (
    <Select
      placeholder="Select a status"
      className="w-[160px] h-10"
      value={archive}
      onChange={setArchiveParam}
      options={CAMPAIGN_ARCHIVE_OPTIONS}
      dropdownRender={(menu) => (
        <>
          <div className="text-sm font-semibold py-1.5 pl-2">Select Status</div>
          {menu}
        </>
      )}
    />
  );
};
