import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appSlice from "./slices/appSlice";
import campaignLabelsSlice from "./slices/campaignLabelsSlice";
import campaignsSlice from "./slices/campaignsSlice";
import ccVariablesSlice from "./slices/ccVariablesSlice";
import companiesListSlice from "./slices/companiesListSlice";
import companiesSlice from "./slices/companiesSlice";
import computationMessagesSlice from "./slices/computationMessages/slice";
import docTemplatesSlice from "./slices/docTemplatesSlice";
import envVariablesSlice from "./slices/envVariablesSlice";
import globalLabelsSlice from "./slices/globalLabelsSlice";
import historySlice, { historyApi } from "./slices/historySlice";
import modelConfigsSlice from "./slices/modelConfigsSlice";
import phasesSlice from "./slices/phasesSlice";
import stepsSlice from "./slices/stepsSlice";
import userSlice from "./slices/userData/slice";
import companyStorageSlice from "./slices/companyStorageSlice";
import templateOptionsSlice from "./slices/templateOptionsSlice";
import chatSlice, { chatApi } from "./slices/chatSlice";

const reducers = combineReducers({
  app: appSlice,
  userData: userSlice,
  ccVariables: ccVariablesSlice,
  envVariables: envVariablesSlice,
  steps: stepsSlice,
  phases: phasesSlice,
  companies: companiesSlice,
  campaigns: campaignsSlice,
  computationMessages: computationMessagesSlice,
  globalLabels: globalLabelsSlice,
  campaignLabels: campaignLabelsSlice,
  companiesList: companiesListSlice,
  docTemplates: docTemplatesSlice,
  modelConfigs: modelConfigsSlice,
  companyStorage: companyStorageSlice,
  templateOptions: templateOptionsSlice,
  chat: chatSlice,
  [chatApi.reducerPath]: chatApi.reducer,
  history: historySlice,
  [historyApi.reducerPath]: historyApi.reducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(historyApi.middleware, chatApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
