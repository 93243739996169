import React, { FC } from "react";
import clsx from "clsx";
import { CcItemEditType } from "../constants";
import { NavItem } from "../types";

type Props = {
  hasDynamicFields: boolean;
  hasPrompt: boolean;
  activeTab: CcItemEditType;
  setActiveTab: (tab: CcItemEditType) => void;
  hasCampaignEnvItemReadRole: boolean;
  hasCampaignCcUpdateRole: boolean;
};

const EditorNav: FC<Props> = ({
  hasPrompt,
  hasDynamicFields,
  activeTab,
  setActiveTab,
  hasCampaignEnvItemReadRole,
  hasCampaignCcUpdateRole,
}) => {
  const navItems = [
    {
      label: "Content",
      value: CcItemEditType.CONTENT,
      active: activeTab === CcItemEditType.CONTENT,
    },
    hasCampaignCcUpdateRole &&
      hasDynamicFields && {
        label: "Model",
        value: CcItemEditType.MODEL,
        active: activeTab === CcItemEditType.MODEL,
      },
    hasCampaignEnvItemReadRole &&
      hasPrompt && {
        label: "Prompt",
        value: CcItemEditType.PROMPT,
        active: activeTab === CcItemEditType.PROMPT,
      },
  ].filter(Boolean) as NavItem[];

  return (
    <div className="h-[42px] bg-[#EEF2FF] flex items-end border-t border-[#D4DAE0]">
      <div className="flex gap-10 ml-[78px] h-full">
        {navItems.map(({ label, active, value }) => {
          return (
            <div
              key={value}
              onClick={() => setActiveTab(value)}
              className={clsx(
                `relative flex justify-center items-center cursor-pointer transition-colors`,
                {
                  "text-vePrimary": active,
                  "text-[#3C4E63] hover:text-vePrimary": !active,
                },
              )}
            >
              <span className="font-semibold text-sm">{label}</span>
              {active && (
                <div className="absolute top-[39px] h-[3px] w-full bg-vePrimary"></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EditorNav;
