import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";
import { UserPermissions } from "../../store/slices/userData/constants";
import { ReactFlowProvider } from "reactflow";
import UpstreamGraph from "../../pages/graph/UpstreamGraph";
import NotFoundPage from "../../pages/NotFoundPage";
import DownstreamGraph from "../../pages/graph/DownstreamGraph";

const GraphRoutes = () => {
  return (
    <Routes>
      <Route
        path=":companyId/:campaignId/:phaseId/:stepId/:key"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_CAMPAIGN_CC_GRAPH}
            isCompanyRole={true}
          >
            <ReactFlowProvider>
              <UpstreamGraph />
            </ReactFlowProvider>
          </ProtectedByRoleRoute>
        }
      />
      <Route
        path="downstream/:companyId/:campaignId/:phaseId/:stepId/:key"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_CAMPAIGN_CC_GRAPH}
            isCompanyRole={true}
          >
            <DownstreamGraph />
          </ProtectedByRoleRoute>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default GraphRoutes;
