import React, { FC } from 'react';
import { Editor, FloatingMenu } from '@tiptap/react';
import {
  AlignCenterIcon,
  AlignLeftIcon, AlignRightIcon,
  BoldIcon,
  ItalicIcon,
  OrderedListIcon,
  StrikeIcon,
  UnorderedListIcon,
} from './Icons';
import FormatButton from './FormatButton';

type Props = {
  editor: Editor;
};
const EditorNewLineFloatingMenu: FC<Props> = ({ editor })  => {
  const textFormattingButtons = [
    {
      command: "bold",
      Icon: BoldIcon,
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: editor.isActive("bold"),
    },
    {
      command: "italic",
      Icon: ItalicIcon,
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: editor.isActive("italic"),
    },
    {
      command: "strike",
      Icon: StrikeIcon,
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: editor.isActive("strike"),
    },
  ];

  const contentFormattingButtons = [
    {
      command: "ordered_list",
      Icon: OrderedListIcon,
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: editor.isActive("orderedList"),
    },
    {
      command: "unordered_list",
      Icon: UnorderedListIcon,
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: editor.isActive("bulletList"),
    },
    {
      command: "align_left",
      Icon: AlignLeftIcon,
      action: () => editor.chain().focus().setTextAlign("left").run(),
      isActive: editor.isActive({ textAlign: "left" }),
    },
    {
      command: "align_center",
      Icon: AlignCenterIcon,
      action: () => editor.chain().focus().setTextAlign("center").run(),
      isActive: editor.isActive({ textAlign: "center" }),
    },
    {
      command: "align_right",
      Icon: AlignRightIcon,
      action: () => editor.chain().focus().setTextAlign("right").run(),
      isActive: editor.isActive({ textAlign: "right" }),
    },
  ];

  return (
    <div>
      <FloatingMenu editor={editor} tippyOptions={{ duration: 100 }}>
        <div className="flex gap-2 px-4 py-2 rounded-lg bg-[#3C4E63] shadow-[0px_4px_14px_rgba(0,0,0,0.12),_0px_1px_2px_rgba(0,0,0,0.12)]">
          {textFormattingButtons.map(({ command, Icon, action, isActive }) => (
            <FormatButton
              key={command}
              Icon={Icon}
              onClick={action}
              isActive={isActive}
            />
          ))}

          <div className="w-[1px] bg-[#fff]" />

          {contentFormattingButtons.map(
            ({ command, Icon, action, isActive }) => (
              <FormatButton
                key={command}
                Icon={Icon}
                onClick={action}
                isActive={isActive}
              />
            ),
          )}
        </div>
      </FloatingMenu>
    </div>
  );
};

export default EditorNewLineFloatingMenu;