import React, { FC } from "react";
import { Select } from "antd";

type CampaignsPaginationProps = {
  page: number;
  pageSize: number;
  totalCampaigns: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
};

const PAGE_SIZE_VALUES = ["10", "20", "50", "100"];

export const Pagination: FC<CampaignsPaginationProps> = ({
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  totalCampaigns,
}) => {
  if (!totalCampaigns || !pageSize) return null;

  const handlePageSizeChange = (pageSize: string) => {
    onPageSizeChange(Number(pageSize));
  };

  const handlePageChange = async (page: string) => {
    onPageChange(Number(page));
  };

  const pagesNumber = Math.ceil(totalCampaigns / pageSize);
  const pages = Array.from({ length: pagesNumber }, (_, i) => i + 1);
  const pageOptions = pages.map((option) => ({
    value: String(option),
    label: String(option),
  }));
  const pageSizeOptions = PAGE_SIZE_VALUES.map((option) => ({
    value: option,
    label: `${option} per page`,
  }));

  return (
    <div className="flex justify-end font-instrument">
      <div className=" h-10 justify-start items-center gap-6 inline-flex">
        <div className="text-slate-600 text-sm font-medium leading-[14px]">
          {totalCampaigns} items
        </div>
        <div className="justify-start items-center gap-2 flex">
          <Select
            placeholder="Select a page"
            className="w-[80px] h-10"
            value={page.toString()}
            onChange={handlePageChange}
            options={pageOptions}
            dropdownStyle={{ width: 160 }}
            dropdownRender={(menu) => (
              <>
                <div className="text-sm font-semibold py-1.5 pl-2">
                  Select a page
                </div>
                {menu}
              </>
            )}
          />

          <div className="text-slate-600 text-sm font-medium leading-[14px]">
            of {pagesNumber}
          </div>
        </div>
        <div className="w-px h-10 bg-slate-300" />
        <Select
          placeholder="Select a page size"
          className="w-[180px] h-10"
          value={pageSize.toString()}
          onChange={handlePageSizeChange}
          options={pageSizeOptions}
          dropdownRender={(menu) => (
            <>
              <div className="text-sm font-semibold py-1.5 pl-2">
                Select a page size
              </div>
              {menu}
            </>
          )}
        />
      </div>
    </div>
  );
};