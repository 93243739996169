import { Component, ErrorInfo, ReactNode } from "react";
import { Button, Result } from "antd";
import axios from "axios";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error("Error caught Error Boundary:", error, errorInfo);

    const errorText = `
       [Error]: ${error.message}
       [Type]: ${error.name}
       [Stack]: ${error.stack}
       [Component Stack]: ${errorInfo.componentStack}
       [URL]: ${window.location.href}
       [User Agent]: ${navigator.userAgent}
       [Screen]: ${window.innerWidth}x${window.innerHeight}
       [Online]: ${navigator.onLine ? "Yes" : "No"}
       [History Length]: ${window.history.length}
       [Time Zone]: ${Intl.DateTimeFormat().resolvedOptions().timeZone}
       [Timestamp]: ${new Date().toISOString()}
    `.trim();

    axios
      .post("/api/secured/global/log/", errorText, {
        headers: { "Content-Type": "text/plain" },
      })
      .catch((err) => console.error("Failed to send error report:", err));
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="w-[100vw] h-[100vh] flex justify-center items-center">
          <Result
            status="500"
            subTitle="Sorry, something went wrong. Please report the error."
            extra={
              <Button
                type="primary"
                onClick={() => (window.location.href = "/home")}
              >
                Go to Home
              </Button>
            }
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
