import { useEffect, useMemo } from "react";
import { TCcGridItemData, TGridItemTitle } from "./CampaignGrid";
import {
  loadCcVarsThunk, setCcVariables,
  TCcVariable,
  useCcVariables,
} from '../../../store/slices/ccVariablesSlice';
import { selectCampaignLabels } from "../../../store/slices/campaignLabelsSlice";
import { selectGlobalLabels } from "../../../store/slices/globalLabelsSlice";
import handleRequestError from "../../../utils/handleRequestError";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { AppDispatch } from "../../../store/store";
import { useBoolean } from "src/hooks/useBoolean";

type UseCcGridDataProps = {
  campaignId?: number;
  stepId?: number;
};

type UseCcGridDataReturn = {
  gridData: TCcGridItemData[];
  ccVars: TCcVariable[];
  isLoading: boolean;
};

const useCcGridData = ({
  campaignId,
  stepId,
}: UseCcGridDataProps): UseCcGridDataReturn => {
  const ccVars = useCcVariables();
  const campaignLabels = useSelector(selectCampaignLabels);
  const globalLabels = useSelector(selectGlobalLabels);
  const messageApi = useSelector(selectMessageApi);
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const getCcVars = async () => {
    try {
      startLoading();

      await dispatch(loadCcVarsThunk({ campaignId, stepId })).unwrap();
    } catch (e: any) {
      dispatch(setCcVariables([]));

      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    getCcVars();
  }, [stepId]);

  const data: TCcGridItemData[] = useMemo(() => {
    return ccVars.map((variable) => {
      const { id, type, options, result, resultOvr, state } = variable;
      const varKey = id.key;
      const campaignLabel = campaignLabels[varKey];
      const globalLabel = globalLabels[varKey];

      const title: TGridItemTitle = {
        scope: campaignLabel ? "local" : globalLabel ? "global" : "local",
        value: campaignLabel || globalLabel || "",
      };

      return {
        key: varKey,
        state,
        type,
        title,
        varKey,
        content: options,
        result,
        override: resultOvr,
        action: null,
        variable,
      };
    });
  }, [ccVars, campaignLabels, globalLabels]);

  return {
    gridData: data,
    ccVars,
    isLoading,
  };
};

export default useCcGridData;
