import {
  DownstreamDepsIcon,
  UpstreamDepsIcon,
} from "src/components/common/Icons";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SolidEditIcon } from "src/components/common/Icons";
import { MenuDropdownItem } from "src/components/common/MenuDropdown";
import { useUserHasPermission } from "src/store/slices/userData/hooks/useUserHasPermission";
import { TCcVariable } from "src/store/slices/ccVariablesSlice";
import { FC, MouseEvent } from "react";

type VeeContextMenuProps = {
  ccVariables: TCcVariable[];
  ccVarKey: string;
  onOpenEditor: (variable: TCcVariable) => void;
  onClose: () => void;
};

type MenuItemWithShortcut = Omit<MenuDropdownItem, 'onClick'> & {
  shortcut?: string;
  onClick: () => void;
};

const VeeContextMenu: FC<VeeContextMenuProps> = ({
  ccVariables,
  ccVarKey,
  onOpenEditor,
  onClose,
}) => {
  const { companyId, campaignId, phaseId, stepId } = useParams();
  const variable = ccVariables.find((v) => v.id.key === ccVarKey);
  const navigate = useNavigate();
  const { hasCampaignCcUpdateRole, hasCampaignCcGraphRole } =
    useUserHasPermission({ companyId });

  const items = [
    hasCampaignCcUpdateRole && {
      key: "edit",
      label: "Edit",
      onClick: () => {
        onOpenEditor(variable!);
        onClose();
      },
      icon: SolidEditIcon,
    },
    hasCampaignCcGraphRole &&
      variable?.type === "prompt" && {
        key: "showUpstream",
        label: "Upstream Dependencies",
        icon: UpstreamDepsIcon,
        onClick: () => {
          navigate(
            `/graph/${companyId}/${campaignId}/${phaseId}/${stepId}/${variable.id.key}`,
          );
          onClose();
        },
      },
    hasCampaignCcGraphRole && {
      key: "showDownstream",
      label: "Downstream Dependencies",
      icon: DownstreamDepsIcon,
      onClick: () => {
        navigate(
          `/graph/downstream/${companyId}/${campaignId}/${phaseId}/${stepId}/${variable?.id.key}`,
        );
        onClose();
      },
    },
  ].filter((v) => !!v) as MenuItemWithShortcut[];

  return (
    <div className="flex flex-col w-full min-w-[220px] py-1">
      {items.map((item) => (
        <button
          key={item.key}
          className="flex items-center justify-between w-full px-4 py-2 text-sm text-muted-foreground hover:bg-accent hover:text-accent-foreground rounded-sm transition-colors"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            item.onClick();
          }}
        >
          <div className="flex items-center gap-2">
            <item.icon className="w-4 h-4" />
            <span className="font-medium">{item.label}</span>
          </div>
          {item.shortcut && (
            <span className="text-xs text-muted-foreground ml-4">{item.shortcut}</span>
          )}
        </button>
      ))}
    </div>
  );
};

export default VeeContextMenu;
