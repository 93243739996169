import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "src/components/ui/breadcrumb";
import { ChevronRight, Clock, Settings } from "lucide-react";
import React, { FC, useState } from "react";
import { useCampaignChatParams } from "../hooks/useCampaignChatParams";
import { useGoBack } from "src/hooks/useGoBack";
import { ChatInfo } from "../types";
import XMarkButton from "../../common/XMarkButton";
import { Button } from "../../common/ui/button";
import PromptSettingsModal from "./prompt-settings-modal";
import { useUserHasPermission } from "src/store/slices/userData/hooks/useUserHasPermission";

interface ChatBreadcrumbProps {
  chatInfo: ChatInfo;
  onHistoryClicked: () => void;
}

const ChatBreadcrumb: FC<ChatBreadcrumbProps> = ({
  chatInfo,
  onHistoryClicked,
}) => {
  const { companyId } = useCampaignChatParams();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { hasChatHistoryListRole } = useUserHasPermission({
    companyId,
  });

  const {
    campaignName,
    phaseName,
    phaseId,
    stepName,
    chat: { title, campaignId, stepId, id: chatId },
  } = chatInfo;

  const stepPath = `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}`;

  const goBack = useGoBack(stepPath);

  return (
    <>
      <div className="flex items-center gap-4 px-[16px] py-2 border-b border-[#E2E8F0]">
        <style
          dangerouslySetInnerHTML={{
            __html: "#breadcrumb * { list-style-type: none !important; }",
          }}
        />

        <XMarkButton onClick={goBack} />

        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>{campaignName}</BreadcrumbItem>
            <BreadcrumbSeparator>
              <ChevronRight className="h-4 w-4" />
            </BreadcrumbSeparator>
            <BreadcrumbItem>{phaseName}</BreadcrumbItem>
            <BreadcrumbSeparator>
              <ChevronRight className="h-4 w-4" />
            </BreadcrumbSeparator>
            <BreadcrumbItem>
              <BreadcrumbLink href={stepPath}>{stepName}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator>
              <ChevronRight className="h-4 w-4" />
            </BreadcrumbSeparator>
            <BreadcrumbItem>
              <BreadcrumbPage>{title}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <div className="ml-auto">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsSettingsOpen(true)}
            icon={Settings}
            aria-label="Chat Settings"
          />
          {hasChatHistoryListRole && (
            <Button
              variant="ghost"
              size="icon"
              onClick={onHistoryClicked}
              icon={Clock}
              aria-label="Chat History"
            />
          )}
        </div>
      </div>

      <PromptSettingsModal
        chatId={chatId}
        isOpen={isSettingsOpen}
        onOpenChange={setIsSettingsOpen}
      />
    </>
  );
};

export default ChatBreadcrumb;
