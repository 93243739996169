import { CampaignDataAssetsStructure } from "src/store/slices/campaignsSlice";
import { TStorageFileItem } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";
import { CopyFileParams } from "../types";
import { replaceBrackets } from "../utils/cm.utils";

export const getCampaignStructureApi = ({
  campaignId,
}: {
  campaignId: string;
}) => {
  return axiosInstance.get<CampaignDataAssetsStructure>(
    `/api/secured/campaign/${campaignId}/data`,
    {
      params: {
        initProps: "ASSETS_AND_STORAGE",
      },
    },
  );
};

// PRIVATE
export const getCompanyPrivateFilesListApi = ({ path }: { path: string }) => {
  return axiosInstance.get<TStorageFileItem[]>(
    `/api/secured/fs/private-companies/list`,
    {
      params: {
        path,
      },
    },
  );
};

export const uploadCompanyPrivateFileApi = ({
  formData,
}: {
  formData: FormData;
}) => {
  return axiosInstance.post<TStorageFileItem>(
    `/api/secured/fs/private-companies/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};

export const deleteCompanyPrivateFileApi = ({ path }: { path: string }) => {
  const form = new FormData();
  form.append("path", path);

  return axiosInstance.delete<void>(`/api/secured/fs/private-companies/file`, {
    data: form,
  });
};

export const createCompanyPrivateFolderApi = ({
  formData,
}: {
  formData: FormData;
}) => {
  return axiosInstance.post<TStorageFileItem>(
    `/api/secured/fs/private-companies/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};

export const getCompanyPrivateFileDataApi = ({ path }: { path: string }) => {
  return axiosInstance.get<string>(`/api/secured/fs/private-companies/file`, {
    params: {
      path,
    },
  });
};

export const downloadCompanyPrivateFileApi = ({ path }: { path: string }) => {
  return axiosInstance.get<ArrayBuffer>(
    `/api/secured/fs/private-companies/download`,
    {
      responseType: "arraybuffer",
      params: {
        path,
      },
    },
  );
};

export const copyCompanyPrivateFileApi = ({
  sourcePath,
  targetPath,
}: CopyFileParams) => {
  return axiosInstance.post<TStorageFileItem>(
    "/api/secured/fs/private-companies/copy",
    {},
    {
      params: {
        sourcePath,
        targetPath,
      },
    },
  );
};

// PUBLIC
export const getCompanyPublicFilesListApi = ({ path }: { path: string }) => {
  return axiosInstance.get<TStorageFileItem[]>(
    `/api/secured/fs/public-companies/list`,
    {
      params: {
        path: replaceBrackets(path),
      },
    },
  );
};

export const uploadCompanyPublicFileApi = ({
  formData,
}: {
  formData: FormData;
}) => {
  return axiosInstance.post<TStorageFileItem>(
    `/api/secured/fs/public-companies/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};

export const deleteCompanyPublicFileApi = ({ path }: { path: string }) => {
  const form = new FormData();
  form.append("path", path);

  return axiosInstance.delete<void>(`/api/secured/fs/public-companies/file`, {
    data: form,
  });
};

export const downloadCompanyPublicFileApi = ({ path }: { path: string }) => {
  return axiosInstance.get<Blob>(`/api/secured/fs/public-companies/download`, {
    responseType: "blob",
    params: {
      path,
    },
  });
};

export const downloadCompanyPublicPDFFileApi = ({ path }: { path: string }) => {
  return axiosInstance.get<Blob>(`/api/secured/fs/public-companies/html2Pdf`, {
    responseType: "blob",
    params: {
      path,
    },
  });
};

export const getCompanyPublicFileDataApi = ({ path }: { path: string }) => {
  return axiosInstance.get<string>(
    `/api/secured/fs/public-companies/file-url`,
    {
      params: {
        path,
      },
    },
  );
};

export const copyCompanyPublicFileApi = ({
  sourcePath,
  targetPath,
}: CopyFileParams) => {
  return axiosInstance.post<TStorageFileItem>(
    `/api/secured/fs/public-companies/copy`,
    {},
    {
      params: {
        sourcePath,
        targetPath,
      },
    },
  );
};
