import { RiAiGenerate2, RiLink, RiUploadCloud2Fill } from "@remixicon/react";
import React from "react";
import { TITLE_ICON_PROPS } from "../constants";
import { UserMyPermissions } from '../../../store/slices/userData/types';
import { buildPermissions } from '../../../store/slices/userData/utils';
import { UserPermissions } from '../../../store/slices/userData/constants';

export const getAssetIcon = (type: string) => {
  switch (type) {
    case "GENERATED":
      return <RiAiGenerate2 {...TITLE_ICON_PROPS} />;
    case "UPLOADED":
      return <RiUploadCloud2Fill {...TITLE_ICON_PROPS} />;
    default:
      return <RiLink {...TITLE_ICON_PROPS} />;
  }
};

export const getHasPermission = (
  permissions: UserMyPermissions,
  companyId: number,
) => {
  const { globalPermissions, companyPermissions, companyPermissionsDeny } =
    permissions;

  const permissionsSet = buildPermissions({
    globalPermissions,
    companyPermissions,
    companyPermissionsDeny,
    companyId,
  });

  return permissionsSet.has(UserPermissions.ROLE_CAMPAIGN_READ);
};