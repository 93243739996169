import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";
import clsx from "clsx";
import { RiCheckLine, RiFileCopyLine } from "@remixicon/react";

type CopyableProps = {
  copyValue: string;
  tooltip?: string;
  copiedTooltip?: string;
  showCopy?: boolean;
};

const CopyToClipboard: React.FC<CopyableProps> = ({
  copyValue,
  tooltip = "Copy link",
  copiedTooltip = "Copied!",
  showCopy = true,
}) => {
  const [copied, setCopied] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const tooltipTitle = copyValue
    ? copied
      ? copiedTooltip
      : tooltip
    : undefined;

  const handleCopy = async (copyValue: string) => {
    try {
      await navigator.clipboard.writeText(copyValue);

      setCopied(true);

      if (timerRef.current) clearTimeout(timerRef.current);

      timerRef.current = setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (error) {
      console.error("Copy failed:", error);
    }
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <Tooltip title={tooltipTitle}>
      {copied && (
        <RiCheckLine
          className={clsx(
            "cursor-pointer text-[#3ead81] transition-all",
            { "opacity-100": showCopy },
            { "opacity-0": !showCopy },
          )}
        />
      )}

      {!copied && (
        <RiFileCopyLine
          onClick={() => handleCopy(copyValue)}
          className={clsx(
            "cursor-pointer text-[#3C4E63] hover:text-vePrimary transition-all",
            { "opacity-100": showCopy },
            { "opacity-0": !showCopy },
          )}
        />
      )}
    </Tooltip>
  );
};

export default CopyToClipboard;
