import React, { FC, useMemo, useState } from "react";
import { VarTypeEnum } from "../../../types";
import { Popover, Typography } from "antd";
import clsx from "clsx";
import ArgumentContent from "./ArgumentContent";

type Props = {
  index: number;
  gridItemSequence: number;
  localKeysOptions: { label: string; value: string }[];
  varKeys: (string | null)[];
  setVarKeys: (keys: (string | null)[]) => void;
  postProcessingIndex: number;
};

const TemplatePromptVar: FC<Props> = ({
  index,
  gridItemSequence,
  localKeysOptions,
  varKeys,
  setVarKeys,
  postProcessingIndex,
}) => {
  const [open, setOpen] = useState(false);
  const varKey = varKeys[index];
  const varType = useMemo(() => {
    if (varKey) {
      if (varKey.startsWith("expr(")) {
        return VarTypeEnum.EXPRESSION;
      } else if (/^".*"$/.test(varKey)) {
        return VarTypeEnum.RAW;
      } else if (varKey.includes("/")) {
        return VarTypeEnum.CAMPAIGN_VAR;
      } else if (varKey.startsWith("${")) {
        return VarTypeEnum.PROMPT_RESULTS_VAR;
      }
    }

    return VarTypeEnum.LOCAL_VAR;
  }, [varKey]);

  return (
    <Popover
      destroyTooltipOnHide
      content={
        <ArgumentContent
          varKey={varKey}
          varType={varType}
          index={index}
          localKeysOptions={localKeysOptions}
          setVarKeys={setVarKeys}
          varKeys={varKeys}
          gridItemSequence={gridItemSequence}
          closePopup={() => setOpen(false)}
          postProcessingIndex={postProcessingIndex}
        />
      }
      title={
        <span className="font-bold text-[#475569] text-[14px]">
          Choose Argument
        </span>
      }
      trigger="click"
      open={open}
      onOpenChange={setOpen}
    >
      <Typography.Text
        code
        strong
        className={clsx(
          "font-bold text-[#ff761b] cursor-pointer hover:text-[#a54200] transition-colors",
          {
            "!hover:text-[#002c02] !text-[#007706]": varKey !== null,
          },
        )}
      >
        {varKey || "var"}
      </Typography.Text>
    </Popover>
  );
};

export default TemplatePromptVar;
