import React, { FC } from "react";
import RichTextEditor from "../../../common/RichTextEditor/RichTextEditor";

type Props = {
  content: string;
  richEditorDisabled: boolean;
};
const ContentCollapsed: FC<Props> = ({ content, richEditorDisabled }) => {
  return (
    <>
      {!richEditorDisabled && (
        <RichTextEditor
          content={content}
          onValueChange={() => {}}
          disabled={true}
          className="flex-1 overflow-y-auto [&_.ProseMirror]:p-4 [&_.ProseMirror]:min-h-[100%] [&_.ProseMirror:focus]:outline-none [&_.ProseMirror]:max-h-[350px]"
        />
      )}

      {richEditorDisabled && (
        <div className="max-h-[350px] p-4 overflow-y-auto">{content}</div>
      )}
    </>
  );
};

export default ContentCollapsed;
