import { CaretRightFilled } from "@ant-design/icons";
import { Segmented, Select, Spin, Tooltip } from "antd";
import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TVarType, VarTypeEnum } from "src/types";
import {
  getCcTemplateByPath,
  getPromptTemplateByKey,
  getPromptTemplatesApi,
} from "../../../api/cc-variables.api";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import TemplatePrompt from "./TemplatePrompt";
import {
  PostProcessingItem,
  TSelectPostProcessingData,
  TSelectPromptData,
} from "./types";
import {
  getSegmentedBasedOnType,
  getTypeBasedOnSegmented,
  prepareTemplate,
} from "./utils";
import SelectCCKey from "../../common/SelectCCKey";
import handleRequestError from "../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import TextArea from "antd/es/input/TextArea";

type PostProcessingItemProps = {
  index: number;
  gridItemSequence: number;
  onItemEdit: (item: PostProcessingItem) => void;
  defaultItem: PostProcessingItem;
  campaignId: number;
  localKeys: TCcVariable[];
};

const PostProcessing: React.FC<PostProcessingItemProps> = ({
  index: postProcessingIndex,
  gridItemSequence,
  onItemEdit,
  defaultItem,
  campaignId,
  localKeys,
}) => {
  const [promptData, setPromptData] = useState<Array<TSelectPromptData>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [promptTemplate, setPromptTemplate] = useState<string | undefined>(
    undefined,
  );
  const [isPromptLoading, setIsPromptLoading] = useState(false);
  const [envValue, setEnvValue] = useState<string | null>(
    defaultItem.type === "env" ? defaultItem.value : null,
  );
  const [ccValue, setCcValue] = useState<string | null>(
    defaultItem.type === "cc" ? defaultItem.value : null,
  );
  const [textValue, setTextValue] = useState<string | null>(
    defaultItem.type === "text" ? defaultItem.value : null,
  );
  const [envKeys, setEnvKeys] = useState<Array<string | null>>([]);
  const [ccKeys, setCcKeys] = useState<Array<string | null>>([]);
  const [textKeys, setTextKeys] = useState<Array<string | null>>([]);
  const [type, setType] = useState<PostProcessingItem["type"]>(
    defaultItem?.type || null,
  );
  const messageApi = useSelector(selectMessageApi);
  const timeout: MutableRefObject<any> = useRef(null);
  const currentValue: MutableRefObject<string> = useRef("");

  useEffect(() => {
    if (defaultItem.type === "cc") {
      setType("cc");
      setCcValue(defaultItem.value);
      setCcKeys(defaultItem.keys);
    }

    if (defaultItem.type === "env") {
      setType("env");
      setEnvValue(defaultItem.value);
      setEnvKeys(defaultItem.keys);
    }

    if (defaultItem.type === "text") {
      setType("text");
      setTextValue(defaultItem.value);
      setTextKeys(defaultItem.keys);
    }
  }, []);

  const handleSearchTemplate = (newValue: string) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    currentValue.current = newValue;

    const getSearchItems = async () => {
      try {
        setIsFetching(true);
        const { data } = await getPromptTemplatesApi({
          campaignId,
          searchPart: newValue,
        });

        if (currentValue.current === newValue) {
          const optionsData = data.map((item) => ({
            value: item.key,
            label: item.key,
            envData: item,
          }));
          setPromptData(optionsData);
        }
      } catch (e: any) {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);
      } finally {
        setIsFetching(false);
      }
    };

    if (newValue) {
      timeout.current = setTimeout(getSearchItems, 400);
    } else {
      setPromptData([]);
      setIsFetching(false);
    }
  };

  const handleSelectTemplate = (_: string, option: TSelectPromptData) => {
    const { varKeysArr, template } = prepareTemplate(option.envData.template);

    setPromptTemplate(template);
    setVarKeys(varKeysArr);
    setValue(option?.value || null);
  };

  const handleSelectCampaignTemplate = (
    value: string,
    option: TSelectPostProcessingData,
  ) => {
    const { varKeysArr, template } = prepareTemplate(option.template);

    setPromptTemplate(template);
    setVarKeys(varKeysArr);
    setValue(value);
  };

  const value = useMemo(() => {
    if (type === "cc") {
      return ccValue;
    }

    if (type === "env") {
      return envValue;
    }

    if (type === "text") {
      return textValue;
    }

    return null;
  }, [ccValue, envValue, textValue, type]);

  useEffect(() => {
    if (!value) {
      // if value is null, then we don't need to fetch template
      return;
    }

    // get prompt template by value
    if (type === "env") {
      const fetchTemplateByKey = async () => {
        setIsPromptLoading(true);
        try {
          const { data } = await getPromptTemplateByKey({
            campaignId,
            key: value,
          });

          setPromptTemplate(data.value);
        } catch (e: any) {
          const customError = handleRequestError(e);
          messageApi.error(customError.message);
          console.error(customError);
        } finally {
          setIsPromptLoading(false);
        }
      };

      fetchTemplateByKey();
    }

    if (type === "cc") {
      const fetchTemplateByPath = async () => {
        setIsPromptLoading(true);
        try {
          const { data } = await getCcTemplateByPath({
            campaignId,
            path: value,
          });

          setPromptTemplate(data.result);
        } catch (e: any) {
          const customError = handleRequestError(e);
          messageApi.error(customError.message);
          console.error(customError);
        } finally {
          setIsPromptLoading(false);
        }
      };

      fetchTemplateByPath();
    }

    if (type === "text") {
      const { template } = prepareTemplate(value);
      setPromptTemplate(template);
    }
  }, [type, value]);

  const setValue = (value: string | null) => {
    if (type === "cc") {
      setCcValue(value);
    }

    if (type === "env") {
      setEnvValue(value);
    }

    if (type === "text") {
      setTextValue(value);
    }
  };

  const setVarKeys = (keys: Array<string | null>) => {
    if (type === "cc") {
      setCcKeys(keys);
    }

    if (type === "env") {
      setEnvKeys(keys);
    }

    if (type === "text") {
      setTextKeys(keys);
    }
  };

  const varKeys = useMemo(() => {
    if (type === "cc") {
      return ccKeys;
    }

    if (type === "env") {
      return envKeys;
    }

    if (type === "text") {
      return textKeys;
    }

    return [];
  }, [ccKeys, envKeys, textKeys, type]);

  useEffect(() => {
    onItemEdit({
      value: value!,
      keys: varKeys,
      type: type as PostProcessingItem["type"],
    });
  }, [varKeys, type, value]);

  const renderPromptSelector = () => {
    switch (type) {
      case "env":
        return (
          <Spin spinning={isFetching}>
            <Select
              showSearch
              placeholder="Type to search keys"
              value={envValue}
              optionRender={(option) => {
                const optionData = option.data as TSelectPromptData;

                return (
                  <div>
                    <span style={{ color: "#003784" }}>{optionData.label}</span>
                    <CaretRightFilled style={{ color: "#003784" }} />
                    <span style={{ opacity: 0.5, fontStyle: "italic" }}>
                      {optionData?.envData?.template}
                    </span>
                  </div>
                );
              }}
              suffixIcon={null}
              filterOption={false}
              onSearch={handleSearchTemplate}
              onSelect={handleSelectTemplate}
              notFoundContent={null}
              options={promptData as TSelectPromptData[]}
            />
          </Spin>
        );

      case "cc":
        return (
          <div>
            <SelectCCKey
              gridItemSequence={gridItemSequence}
              onSelect={handleSelectCampaignTemplate}
            />
            <div className="ml-2 mt-4 text-gray-700">{ccValue}</div>
          </div>
        );

      case "text":
        return (
          <Tooltip title='Enter your text. Use "%s" as a placeholder for selectable variables.'>
            <TextArea
              autoSize={{ minRows: 2, maxRows: 6 }}
              placeholder="Enter text"
              value={textValue || ""}
              onChange={(e) => {
                const { varKeysArr, template } = prepareTemplate(
                  e.target.value,
                );
                setPromptTemplate(template);
                setVarKeys(varKeysArr);
                setValue(template);
              }}
            />
          </Tooltip>
        );

      default:
        return null;
    }
  };

  const handleChangeVarType = (value: any) => {
    setType(getTypeBasedOnSegmented(value as TVarType));
    setPromptTemplate(undefined);
  };

  return (
    <div className="flex flex-col flex-1 min-w-0 gap-2 break-all">
      <Segmented
        size="small"
        value={getSegmentedBasedOnType(type!)}
        onChange={handleChangeVarType}
        options={[
          VarTypeEnum.ENVIRONMENT_VAR,
          VarTypeEnum.CAMPAIGN_VAR,
          VarTypeEnum.RAW,
        ]}
      />

      {renderPromptSelector()}

      <Spin spinning={isPromptLoading}>
        {promptTemplate && (
          <TemplatePrompt
            promptTemplate={promptTemplate}
            varKeys={varKeys}
            setVarKeys={setVarKeys}
            gridItemSequence={gridItemSequence}
            postProcessingIndex={postProcessingIndex}
            localKeys={localKeys}
          />
        )}
      </Spin>
    </div>
  );
};

export default PostProcessing;
