export enum CcItemEditType {
  CONTENT = "content",
  MODEL = "model",
  PROMPT = "prompt",
}

export enum OverrideEditorMode {
  RICH_EDITOR = "RICH_EDITOR",
  DEFAULT = "DEFAULT",
}
