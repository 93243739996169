import { Button, Form, FormInstance } from "antd";
import { Trash2Icon } from "lucide-react";
import { FC, useEffect, useState } from "react";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import PostProcessing from "./PostProcessingItem";
import { PostProcessingItem } from "./types";
import { parsePostProcessingParams } from "./utils";

type Props = {
  dynElemName: string;
  required: boolean;
  hidden: boolean;
  form: FormInstance<any>;
  gridItemSequence: number;
  ccItem: TCcVariable | null;
  campaignId: number;
  localKeys: TCcVariable[];
};

const defaultPostProcessingItem: PostProcessingItem = {
  type: "text",
  value: "",
  keys: [],
};

const PostProcessingItems: FC<Props> = ({
  dynElemName,
  required,
  form,
  gridItemSequence,
  ccItem,
  campaignId,
  localKeys,
}) => {
  const [items, setItems] = useState<PostProcessingItem[]>(
    parsePostProcessingParams(ccItem),
  );

  const handleAddPostProcessing = () => {
    setItems([...items, defaultPostProcessingItem]);
  };

  const handleRemovePostProcessing = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const handleEditPostProcessing =
    (index: number) => (item: PostProcessingItem) => {
      const newItems = [...items];
      newItems[index] = item;
      setItems(newItems);
    };

  useEffect(() => {
    form.setFieldsValue({
      postProcessingParams: items,
    });
  }, [items]);

  return (
    <div>
      <div className="font-sans font-bold text-[#475569] text-[14px] flex mb-2">
        Post Processing
      </div>

      {items.map((item, index: number) => (
        <Form.Item
          key={`${index}_${item.type}`}
          name={[dynElemName, index]}
          validateTrigger="onSubmit"
          rules={[
            { required, message: "Fill in a field or delete the item" },
            {
              validator: async (_, item: PostProcessingItem) => {
                if (item.keys?.length && item.keys.includes(null)) {
                  return Promise.reject("Please fill all the keys");
                }

                if (!item.value) {
                  return Promise.reject("Please fill the prompt template");
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <div className="flex gap-3 border border-slate-300 rounded-md p-4">
            <div className="flex flex-1 gap-3">
              <div className="font-bold text-[#475569] text-[16px]">
                {`${index + 1}`}
              </div>
              <PostProcessing
                defaultItem={item}
                index={index}
                gridItemSequence={gridItemSequence}
                onItemEdit={handleEditPostProcessing(index)}
                campaignId={campaignId}
                localKeys={localKeys}
              />
            </div>
            <Button danger onClick={() => handleRemovePostProcessing(index)}>
              <Trash2Icon className="h-4 w-4" />
            </Button>
          </div>
        </Form.Item>
      ))}

      <Button shape="round" onClick={handleAddPostProcessing}>
        Add
      </Button>
    </div>
  );
};

export default PostProcessingItems;
