import {
  Form,
  Input,
  InputNumber,
  InputRef,
  Modal,
  Select,
  Skeleton,
} from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import micrositeContextFolderTooltip from "../../../constants/micrositeContextFolderTooltip";
import useSetFocus from "../../../hooks/useSetFocus";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  Campaign,
  editCampaignThunk,
} from "../../../store/slices/campaignsSlice";
import { AppDispatch } from "../../../store/store";
import { getDynFormSelectOptions } from "../../../utils/cm.utils";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { minMax, required } from "../../../utils/validations";
import { MICROSITE_INPUT_PLACEHOLDER } from "../../../constants";
import { getConfirmationMessage } from "../../../utils/getConfirmationMessage";
import { getAiModelConfigOptions } from "../../../api/model-configs.api";
import customizeFormLabels from "../../../utils/customizeFormLabels";
import { getCampaignDataApi } from "../../../api/campaigns.api";
import { useBoolean } from "../../../hooks/useBoolean";
import { AxiosResponse } from "axios";

type TCampaignFormValues = {
  name: string;
  microSiteContextFolder: string;
  aiModelId: number | null;
  auditTrailDays: number;
};

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  campaignId: number;
};

const EditCampaignModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  campaignId,
}) => {
  const messageApi = useSelector(selectMessageApi);
  const [form] = Form.useForm<TCampaignFormValues>();
  const inputRef = useRef<InputRef>(null);
  const dispatch: AppDispatch = useDispatch();
  const [campaignData, setCampaignData] = useState<Campaign | null>(null);
  const [options, setOptions] = useState<
    { label: string; value: string | number | null }[]
  >([]);
  const [submitting, startSubmitting, stopSubmitting] = useBoolean(false);

  useSetFocus(inputRef);
  useSubmitFormOnEnter(() => submitForm(), { condition: !submitting });

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const requests: [
          Promise<AxiosResponse<string[], any>>,
          Promise<AxiosResponse<Campaign, any>>,
        ] = [getAiModelConfigOptions(), getCampaignDataApi({ campaignId })];

        const [{ data: aiModelOptions }, { data: campaignData }] =
          await Promise.all(requests);
        const options = getDynFormSelectOptions({ data: aiModelOptions });

        setOptions(options);
        setCampaignData(campaignData);
      } catch (e: any) {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);

        onCancel();

        console.error(customError);
      }
    };

    loadInitialData();
  }, [campaignId]);

  const onEdit = async (values: TCampaignFormValues) => {
    if (!campaignData) return;

    try {
      startSubmitting();

      const newCampaignData: Campaign = {
        ...campaignData,
        ...values,
      };

      const hasChangedMicroSiteContextFolder =
        values.microSiteContextFolder !== campaignData.microSiteContextFolder;
      const hasChangeCampaignName = values.name !== campaignData.name;

      const handleSave = async (rebuild: boolean) => {
        try {
          await dispatch(
            editCampaignThunk({
              //TODO revise types - remove old TCampaignType
              //@ts-ignore
              campaign: newCampaignData,
              rebuild,
            }),
          ).unwrap();

          messageApi.success("The campaign has been successfully updated");
          onCancel();
        } catch (e: any) {
          const customError = handleRequestError(e);
          messageApi.error(customError.message);
          console.error(customError);
        } finally {
          stopSubmitting();
        }
      };

      const confirmationMessage = getConfirmationMessage(
        hasChangedMicroSiteContextFolder,
        hasChangeCampaignName,
        "Campaign Name",
      );

      if (confirmationMessage) {
        Modal.confirm({
          title: "Confirm campaign update",
          content: confirmationMessage,
          okText: "Confirm",
          cancelText: "Cancel",
          onOk: () => handleSave(true),
        });
      } else {
        await handleSave(false);
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      stopSubmitting();
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TCampaignFormValues) => {
      await onEdit(values);
    },
  });

  const onCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Edit Campaign"
      open={isModalOpen}
      onOk={submitForm}
      okText="Save"
      width={600}
      destroyOnClose
      okButtonProps={{ loading: submitting, disabled: !campaignData }}
      onCancel={onCancel}
    >
      {campaignData ? (
        <Form
          requiredMark={customizeFormLabels}
          form={form}
          layout="vertical"
          name="asset_form"
          initialValues={{
            name: campaignData?.name || "",
            microSiteContextFolder: campaignData?.microSiteContextFolder || "",
            aiModelId: campaignData?.aiModelId || null,
            auditTrailDays: campaignData?.auditTrailDays || 0,
          }}
        >
          <Form.Item
            name="name"
            label="Campaign Name"
            tooltip="Please enter a string up to 255 characters long."
            rules={[required(), minMax({ max: 255, text: "Campaign Name" })]}
          >
            <Input
              className="h-[48px]"
              placeholder="Enter campaign name"
              ref={inputRef}
            />
          </Form.Item>
          <Form.Item
            name="microSiteContextFolder"
            label="Microsite Context Folder"
            tooltip={micrositeContextFolderTooltip}
            rules={[minMax({ max: 255, text: "Path" })]}
          >
            <Input
              className="h-[48px]"
              placeholder={MICROSITE_INPUT_PLACEHOLDER}
            />
          </Form.Item>
          <Form.Item name="aiModelId" label="AI Model" tooltip="Please select">
            <Select
              className="h-[48px]"
              placeholder="Select the model"
              options={options}
            />
          </Form.Item>
          <Form.Item
            name="auditTrailDays"
            label="Audit Trail Days"
            tooltip="Please enter the value between 0 and 90"
            rules={[required()]}
          >
            <InputNumber
              placeholder="Enter read timeout"
              className="h-[48px] w-full py-[8px]"
              step={1}
              max={90}
              min={0}
            />
          </Form.Item>
        </Form>
      ) : (
        <Skeleton active paragraph={{ rows: 11 }} />
      )}
    </Modal>
  );
};

export default EditCampaignModal;
