import { FC, useEffect, useMemo, useState } from "react";
import { TCcVariable } from "src/store/slices/ccVariablesSlice";
import { cn } from "src/utils";
import { Button } from "../../common/ui/button";
import { Table, Tooltip } from "antd";
import { Loader2 } from "lucide-react";
import GridItemActions from "./cc-vars-grid-item-actions";
import { ChatInfo } from "../types";
import { CcItemEditor } from "../../ccItemEditor";
import { useParams } from "react-router-dom";
import ContentEditor from "./content-editor";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCCVarKey,
  selectChatCcVars,
  selectChatSelectedCCVarKeys,
  selectChatSelectedCCVars,
  selectPendingChatOverrides,
  setCcVars,
  unselectCCVarKey,
} from "src/store/slices/chatSlice";
import { useTextSelection } from "../hooks/useTextSelection";
import { HighlightedText } from "./highlighted-text";
import { StyleOverride } from "./style-override";
import { SelectedTextContextMenu } from "./selected-text-context-menu";

type CCVarsGridProps = {
  applyOverrides: () => void;
  isApplyingOverrides: boolean;
  onEditedContent: (key: string, content: string) => void;
  cancelOverrides: () => void;
  cancelOneOverride: (key: string) => void;
  chatInfo: ChatInfo;
};

export const CCVarsGrid: FC<CCVarsGridProps> = ({
  applyOverrides,
  isApplyingOverrides,
  onEditedContent,
  cancelOverrides,
  cancelOneOverride,
  chatInfo,
}) => {
  const selectedCcVarKeys = useSelector(selectChatSelectedCCVarKeys);
  const selectedCcVars = useSelector(selectChatSelectedCCVars);
  const ccVars = useSelector(selectChatCcVars);
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const [editingVar, setEditingVar] = useState<string | null>(null);
  const [editingContent, setEditingContent] = useState("");
  const [editorVariable, setEditorVariable] = useState<TCcVariable | null>(
    null,
  );
  const pendingOverrides = useSelector(selectPendingChatOverrides);

  const { selectionState, hideSelection } = useTextSelection(
    ".result-cell, .ant-table-cell:nth-child(2)",
  );

  const editDataProps = useMemo(() => {
    if (editorVariable && chatInfo) {
      return {
        ccItemKey: editorVariable.id.key,
        campaignId: chatInfo.chat.campaignId.toString(),
        stepId: chatInfo.chat.stepId.toString(),
      };
    }
    return null;
  }, [editorVariable, chatInfo]);

  const handleSaveValue = async (content: string) => {
    if (!editingVar) return;
    await onEditedContent(editingVar, content);
    handleCancelEditing();
  };

  const handleCancelEditing = (resetContent: boolean = false) => {
    setEditingVar(null);
    if (resetContent) {
      setEditingContent("");
    }
  };

  useEffect(() => {
    if (editingVar) {
      const ccVar = ccVars.find((v) => v.id.key === editingVar)!;
      const ccVarChatOverride = pendingOverrides?.find(
        (override) => override.id.key === editingVar,
      );

      const originalValue =
        ccVar.type === "string"
          ? ccVar.result
          : ccVar.resultOvr || ccVar.result;

      const value = ccVarChatOverride?.text || originalValue;

      setEditingContent(value || "");
    }
  }, [editingVar]);

  const handleClickCCVarKey = (key: string, result: string) => {
    if (selectedCcVarKeys.includes(key)) {
      dispatch(unselectCCVarKey(key));
    } else {
      dispatch(
        selectCCVarKey({
          key,
          context: {
            text: result,
            selections: [{ start: 0, end: result.length }],
          },
        }),
      );
    }
  };

  const handleAddSelection = () => {
    const selectedElement = selectionState.parentElement;
    if (!selectedElement) return;
    const resultCell = selectedElement.closest(".result-cell");
    const key = resultCell?.getAttribute("data-key") || "";

    const fullText = selectedElement.getAttribute('data-original-complete-text') || selectedElement.textContent?.trim() || "";

    dispatch(
      selectCCVarKey({
        key,
        context: {
          text: fullText,
          selections: [
            {
              start: selectionState.startIndex,
              end: selectionState.endIndex,
            },
          ],
        },
      }),
    );

    hideSelection();
  };

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      width: "30%",
      ellipsis: false,
      render: (_text: string, record: any) => {
        return (
          <div
            className={cn(
              "text-gray-600 cursor-pointer font-semibold font-geistMono leading-[13px] py-2",
            )}
            onClick={() => handleClickCCVarKey(record.key, record.result)}
          >
            <span
              className={cn(
                "leading-[28px] py-1 px-2 rounded-lg block break-words whitespace-normal overflow-wrap-anywhere max-w-full",
                selectedCcVarKeys.includes(record.key) && "bg-[#EDE9FE]",
              )}
            >
              {record.key}
            </span>
          </div>
        );
      },
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
      width: "70%",
      render: (_text: string, record: any) => {
        if (editingVar === record.key) {
          return (
            <div className="py-1 px-2 relative">
              <ContentEditor
                ccVar={record.ccVar}
                content={editingContent || ""}
                value={record.result || ""}
                onSave={handleSaveValue}
                onCancel={handleCancelEditing}
                setContent={setEditingContent}
              />
            </div>
          );
        }

        const keyContext = selectedCcVars?.[record.key];

        return (
          <div
            className={cn(
              "cursor-pointer whitespace-pre-line relative py-1 px-2 text-gray-600 font-instrument leading-[13px] !text-base rounded-lg min-h-[32px] my-2 overflow-hidden group result-cell",
            )}
            onDoubleClick={() => {
              if (record.key !== editingVar) {
                setEditingVar(record.key);
              }
            }}
            data-key={record.key}
          >
            <div
              className={cn(
                "absolute top-0.5 right-0.5 z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-200",
                record.isOverridden && "right-6",
              )}
            >
              <GridItemActions
                variable={record.ccVar}
                chatInfo={chatInfo}
                onOpenEditor={(variable) => setEditorVariable(variable)}
              />
            </div>
            {record.isOverridden && (
              <Tooltip title="Cancel Override">
                <div
                  onClick={async () => {
                    await cancelOneOverride(record.key);
                    dispatch(unselectCCVarKey(record.key));
                  }}
                  className="absolute top-[-9px] right-[-10px] w-[40px] h-[30px] bg-gradient-to-t from-transparent to-yellow-500 rotate-45 z-5"
                />
              </Tooltip>
            )}
            <div className="pr-8">
              <HighlightedText
                text={record.result || ""}
                contexts={keyContext || { text: "", selections: [] }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const tableData = useMemo(
    () =>
      ccVars.map((variable) => {
        const ccVarChatOverride = pendingOverrides?.find(
          (override) => override.id.key === variable.id.key,
        );

        const originalValue =
          variable.type === "string"
            ? variable.result
            : variable.resultOvr || variable.result;

        const result = ccVarChatOverride?.text || originalValue;
        const isOverridden = !!ccVarChatOverride;

        return {
          key: variable.id.key,
          result,
          isOverridden,
          ccVar: variable,
        };
      }),
    [ccVars, pendingOverrides],
  );

  const tableScrollY = useMemo(() => {
    if (pendingOverrides && pendingOverrides.length > 0) {
      return window.innerHeight - 84 - 58 - 60;
    }
    return window.innerHeight - 84 - 58;
  }, [pendingOverrides]);

  const updateCCVar = async (ccVar: TCcVariable) => {
    const ccVarIndex = ccVars.findIndex((v) => v.id.key === ccVar.id.key);
    if (ccVarIndex !== -1) {
      const updatedCcVars = [...ccVars];
      updatedCcVars[ccVarIndex] = ccVar;
      dispatch(setCcVars(updatedCcVars));
    }
  };

  return (
    <div
      className={cn(
        "w-full h-[calc(100vh-64px-58px)] flex flex-col p-[12px] gap-[32px] relative",
      )}
    >
      {editorVariable && companyId && editDataProps && (
        <CcItemEditor
          editDataProps={editDataProps}
          companyId={companyId.toString()}
          onAfterClose={() => setEditorVariable(null)}
          onAfterCCItemUpdate={(ccItem) => {
            updateCCVar(ccItem);
          }}
        />
      )}

      <StyleOverride />
      <Table
        className={cn(
          "max-w-[1120px] [&_.ant-table]:bg-transparent [&_.ant-table-thead]:hidden [&_.ant-table-cell]:!bg-transparent [&_.ant-table-cell]:!border-none [&_.ant-table-cell]:!p-[4px_8px] [&_.ant-table-wrapper]:border-none [&_.ant-table-tbody>tr:hover>td]:!bg-transparent",
        )}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{ y: tableScrollY }}
        virtual
      />

      <SelectedTextContextMenu
        selectionState={selectionState}
        onAddSelection={handleAddSelection}
      />

      {pendingOverrides && pendingOverrides.length > 0 && (
        <div
          className="fixed w-[calc(100vw-400px)] bottom-2 flex justify-center"
          id="apply-changed"
        >
          <div className="flex items-center gap-2 justify-between w-[864px] h-[60px] bg-white rounded-[100px] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.12)] shadow-[0px_4px_12px_0px_rgba(0,0,0,0.08)] border border-[#d4dae0] px-4 py-3">
            <div>
              {isApplyingOverrides && (
                <Loader2 className="size-4 animate-spin" />
              )}
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="lg"
                className="rounded-full"
                onClick={cancelOverrides}
                disabled={editingVar !== null || isApplyingOverrides}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                size="lg"
                className="rounded-full"
                onClick={applyOverrides}
                disabled={editingVar !== null || isApplyingOverrides}
              >
                Apply Overrides
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
