import React, { FC } from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import { CheckmarkIcon, CopyIcon } from "../../../common/Icons";
import { Button } from "antd";
import { deleteApiKeyApi } from "../api";
import handleRequestError from "../../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import { useBoolean } from "../../../../hooks/useBoolean";
import useConfirm from "../../../../hooks/useConfirm";

type Props = {
  apiKey: string;
  loadApiKeys: () => Promise<void>;
};
const ListItem: FC<Props> = ({ apiKey, loadApiKeys }) => {
  const messageApi = useSelector(selectMessageApi);
  const [isDeleting, startDeleting, stopDeleting] = useBoolean(false);
  const confirm = useConfirm();

  const deleteApiKey = async () => {
    try {
      startDeleting();

      await deleteApiKeyApi({ apiKey });
      await loadApiKeys();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      stopDeleting();
    }
  };

  return (
    <div className="flex justify-between items-center hover:bg-gray-50 transition-colors p-2 border-b last:border-b-0 last:rounded-b-md first:rounded-t-md">
      <Paragraph
        className="flex items-center !mb-0"
        copyable={{
          icon: [
            <CopyIcon key="copy-icon" />,
            <CheckmarkIcon key="copied-icon" />,
          ],
          tooltips: ["Copy the key", "Copied!"],
        }}
      >
        {apiKey}
      </Paragraph>

      <Button
        size="small"
        danger
        shape="round"
        loading={isDeleting}
        onClick={() =>
          confirm({
            action: deleteApiKey,
            title: "Delete API Key",
          })
        }
      >
        Delete
      </Button>
    </div>
  );
};

export default ListItem;
