import React, { FC } from "react";
import { Select } from "antd";
import { TCompany } from "../../../store/slices/companiesSlice";
import localStorageProvider from "../../../utils/localStorageProvider";
import { ALL_COMPANIES_OPTION_VALUE } from "../constants";

type Props = {
  companies: TCompany[];
  currentCompanyId: number | null;
  setCompanyIdParam: (id: string | null) => void;
};

export const CompanySelector: FC<Props> = ({
  companies,
  currentCompanyId,
  setCompanyIdParam,
}) => {
  const options = [
    { label: "All Companies", value: ALL_COMPANIES_OPTION_VALUE },
    ...companies.map(({ id, name }) => ({ label: name, value: id.toString() })),
  ];

  const onChange = (value: string) => {
    setCompanyIdParam(value);
    localStorageProvider.setSelectedCompanyId(value);
  };

  return (
    <Select
      showSearch
      className="w-[240px] h-10"
      placeholder="Select a company"
      optionFilterProp="label"
      value={
        currentCompanyId !== null
          ? currentCompanyId.toString()
          : ALL_COMPANIES_OPTION_VALUE
      }
      onChange={onChange}
      options={options}
      dropdownRender={(menu) => (
        <>
          <div className="text-sm font-semibold py-1.5 pl-2">
            Select Company
          </div>
          {menu}
        </>
      )}
    />
  );
};