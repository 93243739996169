import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  clonePhaseApi,
  createPhaseApi,
  deletePhaseApi,
  getPhasesApi,
  movePhaseApi,
  updatePhaseApi,
} from "../../api/phases.api";
import { getStepsApi } from "../../api/steps.api";
import { TCloneEntityFormValues } from "../../components/common/modals/CloneEntityModal/CloneEntityModal";
import { TMovePhaseStepType, TPhase } from "../../globalTypes";
import { sortBySequence } from "../../utils/cm.utils";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import { RootState } from "../store";
import { toggleIsFetching } from "./appSlice";
import { setCcVariables } from "./ccVariablesSlice";
import { setCurrentStep, setSteps, TStep } from "./stepsSlice";
import { CampaignViewTypes } from "../../types";
import determineCurrentStepAndView from "../../utils/determineCurrentStepAndView";

const initialState = {
  current: null as TPhase | null,
  list: [] as Array<TPhase>,
};

type InitialStateType = typeof initialState;

const slice = createSlice({
  name: "phases",
  initialState,
  reducers: {
    cleanUpPhases: (state: InitialStateType) => {
      state.current = null;
      state.list = [];
    },
    setPhases: (
      state: InitialStateType,
      action: PayloadAction<Array<TPhase>>,
    ) => {
      state.list = action.payload;
    },
    updatePhase: (state: InitialStateType, action: PayloadAction<TPhase>) => {
      const editedPhase = action.payload;
      const index = state.list.findIndex(
        (phase) => phase.id === editedPhase.id,
      );

      state.list[index] = editedPhase;
    },
    setCurrentPhase: (
      state: InitialStateType,
      action: PayloadAction<TPhase | null>,
    ) => {
      state.current = action.payload;
    },
  },
});

export const { cleanUpPhases, setPhases, updatePhase, setCurrentPhase } =
  slice.actions;

export default slice.reducer;

export const selectPhasesList = (state: RootState) => state.phases.list;
export const selectCurrentPhase = (state: RootState) => state.phases.current;
export const getCurrentPhase = (): TPhase | null =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelector(selectCurrentPhase);

type TDeletePhaseThunkProps = {
  campaignId: number;
  phaseId: number;
  removeFiles: boolean;
};

export const deletePhaseThunk = createAsyncThunk<
  {
    phaseId: number | undefined;
    stepId: number | undefined;
    viewToSet?: CampaignViewTypes;
  },
  TDeletePhaseThunkProps,
  { state: RootState; rejectValue: TCustomError }
>(
  "phases/deletePhase",
  async (
    { campaignId, phaseId, removeFiles },
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const { phases } = getState();
      const allPhases = phases.list;
      const currentPhase = phases.current!;

      await deletePhaseApi({
        campaignId,
        phaseId,
        removeFiles,
      });

      const updatedPhases = allPhases.filter((phase) => phase.id !== phaseId);
      let currentPhaseAfterDelete: TPhase | null = null;
      let currentStep: TStep | null = null;
      let steps: Array<TStep> = [];
      let viewToSet: CampaignViewTypes | undefined;

      if (updatedPhases.length) {
        if (currentPhase.id === phaseId) {
          //if deleted selected phase
          const index = allPhases.findIndex((phase) => phase.id === phaseId);
          const newCurrentPhase =
            index === 0 ? updatedPhases[0] : updatedPhases[index - 1];
          const { data: stepsData } = await getStepsApi({
            phaseId: newCurrentPhase.id,
            campaignId,
          });

          if (stepsData.length) {
            const sortedSteps = sortBySequence<TStep>(stepsData);
            const { validatedCurrentStep, viewToSet: view } =
              determineCurrentStepAndView({
                steps: sortedSteps,
                currentStep: sortedSteps[0],
              });
            currentStep = validatedCurrentStep;
            steps = sortedSteps;
            viewToSet = view;
          }
          currentPhaseAfterDelete = newCurrentPhase;
        }
      }

      dispatch(setCurrentPhase(currentPhaseAfterDelete));
      dispatch(setPhases(updatedPhases));

      dispatch(setCurrentStep(currentStep));
      dispatch(setSteps(steps));

      return {
        phaseId: currentPhaseAfterDelete?.id,
        stepId: currentStep?.id,
        viewToSet,
      };
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to delete the phase:`, e);

      return rejectWithValue(customError);
    }
  },
);

type TSavePhaseThunkProps = {
  phaseName: string;
  hidden: boolean;
  microSiteContextFolder: string | null;
  addType: TMovePhaseStepType;
  targetPhaseId?: number;
};

export const savePhaseThunk = createAsyncThunk<
  TPhase,
  TSavePhaseThunkProps,
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "phases/savePhase",
  async (
    { phaseName, hidden, microSiteContextFolder, targetPhaseId, addType },
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const { campaigns } = getState();
      const { data: newPhase } = await createPhaseApi({
        campaignId: campaigns.current!.id,
        name: phaseName,
        hidden,
        microSiteContextFolder,
        addType,
        targetPhaseId,
      });

      const { data: phases } = await getPhasesApi({
        campaignId: campaigns.current!.id,
      });

      dispatch(setPhases(phases));
      dispatch(setCurrentPhase(newPhase));
      dispatch(setCurrentStep(null));
      dispatch(setSteps([]));
      dispatch(setCcVariables([]));

      return newPhase;
    } catch (e: any) {
      return rejectWithValue(handleRequestError(e));
    }
  },
);

export const updatePhaseThunk = createAsyncThunk<
  string,
  { phase: TPhase; rebuild?: boolean },
  { rejectValue: TCustomError }
>(
  "phases/updatePhase",
  async ({ phase, rebuild }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      await updatePhaseApi({ phase, rebuild });

      dispatch(updatePhase(phase));
      dispatch(setCurrentPhase(phase));

      return "success";
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to update the phase:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);

type SelectPhaseProps = {
  phase: TPhase;
};

type SelectPhaseReturn = {
  phaseId: number;
  step: TStep | null;
  viewToSet?: CampaignViewTypes;
};

export const selectPhaseThunk = createAsyncThunk<
  SelectPhaseReturn,
  SelectPhaseProps,
  {
    rejectValue: TCustomError;
    state: RootState;
  }
>("phases/selectPhase", async ({ phase }, { rejectWithValue, dispatch }) => {
  try {
    dispatch(toggleIsFetching(true));

    const { data: stepsData } = await getStepsApi({
      phaseId: phase.id,
      campaignId: phase.campaignId,
    });

    if (!stepsData.length) {
      dispatch(setSteps([]));
      dispatch(setCurrentStep(null));
      dispatch(setCurrentPhase(phase));

      return {
        phaseId: phase.id,
        step: null,
        viewToSet: CampaignViewTypes.GRID,
      };
    }

    const { validatedCurrentStep, viewToSet } = determineCurrentStepAndView({
      steps: stepsData,
      currentStep: stepsData[0],
    });

    dispatch(setSteps(stepsData));
    dispatch(setCurrentStep(validatedCurrentStep));
    dispatch(setCurrentPhase(phase));

    return {
      phaseId: phase.id,
      step: validatedCurrentStep,
      viewToSet,
    };
  } catch (e: any) {
    const customError = handleRequestError(e);
    console.error(`An error occurred while trying to select the phase:`, e);

    return rejectWithValue(customError);
  } finally {
    dispatch(toggleIsFetching(false));
  }
});

export const clonePhaseThunk = createAsyncThunk<
  undefined,
  { phase: TPhase; params: TCloneEntityFormValues },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "phases/clonePhaseThunk",
  async ({ phase, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaigns, phases } = getState();
      const { data: clonedPhase } = await clonePhaseApi({
        campaignId: campaigns.current!.id,
        phaseId: phase.id,
        ...params,
      });

      const index = phases.list.findIndex(
        (phaseFormList) => phaseFormList.id === phase.id,
      );
      let updatedPhasesList: Array<TPhase> = [];

      if (index !== -1) {
        if (phases.list.length - 1 === index) {
          updatedPhasesList = [...phases.list, { ...clonedPhase }];
        } else {
          updatedPhasesList = [
            ...phases.list.slice(0, index + 1),
            { ...clonedPhase },
            ...phases.list.slice(index + 1),
          ];
        }
      }

      dispatch(setPhases(sortBySequence(updatedPhasesList)));
    } catch (e: any) {
      return rejectWithValue(handleRequestError(e));
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);

export const movePhaseThunk = createAsyncThunk<
  undefined,
  {
    targetPhaseId: number;
    campaignId: number;
    phaseId: number;
    moveType: TMovePhaseStepType;
  },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "phases/movePhaseThunk",
  async (
    { targetPhaseId, moveType, campaignId, phaseId },
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const { data: updatedPhases } = await movePhaseApi({
        targetPhaseId,
        moveType,
        campaignId,
        phaseId,
      });

      const { phases } = getState();

      if (phases.current) {
        const currentPhase = phases.current;
        const updatedCurrentPhase = updatedPhases.find(
          (phase) => phase.id === currentPhase.id,
        );

        dispatch(setCurrentPhase(updatedCurrentPhase || null));
      } else {
        console.warn("Warning, no current phase!");
      }

      dispatch(setPhases(updatedPhases));
    } catch (e: any) {
      return rejectWithValue(handleRequestError(e));
    }
  },
);
