import { Form, Modal, Select } from "antd";
import { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { updateCurrentStepTemplateThunk } from "src/store/slices/stepsSlice";
import { CampaignViewTypes } from "src/types";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { ITemplateOption } from "../../../globalTypes";
import { AppDispatch } from "../../../store/store";
import { useCampaignParams } from "src/pages/campaign/components/CampaignEditorPage/useCampaignParams";
import { useBoolean } from "../../../hooks/useBoolean";
import handleRequestError from "../../../utils/handleRequestError";
import { selectMessageApi } from "../../../store/slices/appSlice";

type TTemplatePreviewFormValues = {
  templateId: number;
};

export type TTemplatePreviewModalProps = {
  onCancel: () => void;
  options: ITemplateOption[];
  defaultOption: ITemplateOption | undefined;
};

const TEMPLATE_ID_FIELD_NAME = "templateId";

const TemplatePreviewModal: FC<TTemplatePreviewModalProps> = ({
  onCancel,
  options,
  defaultOption,
}) => {
  const { campaignId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm<TTemplatePreviewFormValues>();
  const [loading, startLoading, stopLoading] = useBoolean(false);
  const { hasDocumentTemplateListRole } = useUserHasPermission({});
  const { updateSearchParams } = useCampaignParams();
  const isEmpty = useMemo(() => options.length === 0, [options]);

  useSubmitFormOnEnter(() => submitForm(), { condition: !loading });

  useEffect(() => {
    if (defaultOption) {
      form.setFieldValue(TEMPLATE_ID_FIELD_NAME, defaultOption.value);
    }
  }, [defaultOption, form]);

  const onPreview = async ({ templateId }: TTemplatePreviewFormValues) => {
    if (!campaignId) {
      return;
    }

    try {
      startLoading();

      await dispatch(
        updateCurrentStepTemplateThunk({ templateId, campaignId: +campaignId }),
      ).unwrap();

      updateSearchParams({
        documentTemplateId: templateId || undefined,
        view: CampaignViewTypes.DOC,
      });

      onCancel();
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      stopLoading();
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TTemplatePreviewFormValues) => {
      await onPreview(values);
    },
  });

  return (
    <Modal
      title="Step Data Preview"
      open={true}
      onOk={submitForm}
      okButtonProps={{ loading }}
      okText="Preview"
      width={500}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" name="step_template_preview">
        <Form.Item
          name={TEMPLATE_ID_FIELD_NAME}
          label="Select Template"
          rules={[
            {
              required: true,
              message: "Required field!",
            },
          ]}
        >
          <Select
            placeholder={isEmpty ? "No templates available" : "Choose template"}
            options={options}
            disabled={isEmpty}
          />
        </Form.Item>
      </Form>

      {isEmpty && (
        <div className="text-red-500 text-sm mt-1 mb-4">
          There are no templates for this class. Please ask an admin to add a
          class template from the template library{" "}
          {hasDocumentTemplateListRole ? (
            <NavLink
              to="/templates/documents"
              target="_blank"
              className="text-veSecondary hover:text-vePrimary transition-colors"
            >
              VelocityEngine
            </NavLink>
          ) : (
            <span>VelocityEngine</span>
          )}
        </div>
      )}
    </Modal>
  );
};

export default TemplatePreviewModal;
