import React, { FC, useEffect, useRef } from "react";
import { Button } from "../../../common/ui/button";
import {
  bulkUpdateCcVariables,
  TCcVariable,
} from "../../../../store/slices/ccVariablesSlice";
import {
  saveResultOvrApi,
  updateCcVarApi,
} from "../../../../api/cc-variables.api";
import handleRequestError from "../../../../utils/handleRequestError";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import { AppDispatch } from "../../../../store/store";
import { RichEditorModeSwitcher } from "../../../ccItemEditor";
import { OverrideEditorMode } from "../../../ccItemEditor/constants";
import RichTextEditor from "../../../common/RichTextEditor/RichTextEditor";
import { removeOuterParagraphTags } from "../../../../utils";

type Props = {
  editorMode: OverrideEditorMode;
  ccItem: TCcVariable;
  content: string;
  onCancelEditing: (resetContent?: boolean) => void;
  setEditorMode: (value: OverrideEditorMode) => void;
  setContent: (value: string) => void;
  richEditorDisabled: boolean;
  value: string;
};
const ContentExpanded: FC<Props> = ({
  editorMode,
  ccItem,
  content,
  onCancelEditing,
  setEditorMode,
  richEditorDisabled,
  setContent,
  value,
}) => {
  const {
    supportOverride,
    id: { key, stepId, campaignId },
    type,
    modifiedTime,
  } = ccItem;
  const messageApi = useSelector(selectMessageApi);
  const dispatch = useDispatch<AppDispatch>();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [loading, startLoading, stopLoading] = useBoolean(false);

  //set textarea full height
  useEffect(() => {
    if (textareaRef.current && editorMode === OverrideEditorMode.DEFAULT) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [content, editorMode]);

  const handleSaveValue = async () => {
    try {
      startLoading();
      let updatedCCItem: TCcVariable | null;
      const sanitizedContent = removeOuterParagraphTags(content);

      if (supportOverride) {
        const { data } = await saveResultOvrApi({
          campaignId,
          key,
          stepId,
          value: sanitizedContent,
          recomputeDownStream: false,
        });

        updatedCCItem = data;
      } else {
        const { data } = await updateCcVarApi({
          ccItem,
          values: {
            result: sanitizedContent,
            type,
            key,
            modifiedTime,
            ...ccItem.options,
          },
        });

        updatedCCItem = data;
      }

      if (updatedCCItem) {
        dispatch(bulkUpdateCcVariables([updatedCCItem]));
      }

      onCancelEditing();
      messageApi.success("Successfully saved");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      stopLoading();
    }
  };

  const handleOverrideValueChange = (newValue: string | undefined) => {
    if (newValue !== undefined) {
      setContent(newValue);
    }
  };

  return (
    <>
      <div className="flex items-center justify-end h-[38px] px-4 text-base font-medium border border-b-0 text-[#0F172A] bg-[#F8FAFC] rounded-t-lg">
        <RichEditorModeSwitcher
          itemClassName="size-7"
          iconClassName="!size-4"
          editorMode={editorMode}
          setEditorMode={setEditorMode}
          richEditorDisabled={richEditorDisabled}
        />
      </div>

      <div className="flex-1 flex flex-col border border-indigo-600 rounded-b-lg">
        {editorMode === OverrideEditorMode.RICH_EDITOR && (
          <RichTextEditor
            autofocus="end"
            content={content}
            onValueChange={handleOverrideValueChange}
            className="flex-1 overflow-y-auto [&_.ProseMirror]:p-4 [&_.ProseMirror]:min-h-[100%] [&_.ProseMirror:focus]:outline-none"
          />
        )}

        {editorMode === OverrideEditorMode.DEFAULT && (
          <textarea
            rows={1}
            ref={textareaRef}
            className="p-4 resize-none rounded-b-lg text-sm/5.5 bg-background disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200 placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-none"
            onChange={(e) => handleOverrideValueChange(e.target.value)}
            value={content}
          />
        )}
      </div>

      <div className="flex gap-2 mt-2">
        <Button
          variant="primary"
          size="sm"
          className="h-7 rounded-full px-3 text-sm font-medium"
          onClick={() => handleSaveValue()}
          disabled={content === value}
          loading={loading}
        >
          Apply
        </Button>

        <Button
          variant="outline"
          size="sm"
          className="h-7 rounded-full px-3 text-sm font-medium"
          onClick={() => onCancelEditing(true)}
          disabled={loading}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default ContentExpanded;
