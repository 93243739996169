import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCompanyApi,
  getCompanyStatisticsApi,
} from "../../../api/companies.api";
import { selectMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import {
  selectCurrentCompany,
  setCurrentCompany,
} from "../../../store/slices/companiesSlice";
import { TCompanyStatistics } from "../../../globalTypes";
import { AppDispatch } from "../../../store/store";

const useGetCompanyDetailsHook = () => {
  const { companyId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [companyStatistics, setCompanyStatistics] =
    useState<TCompanyStatistics | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const currentCompany = useSelector(selectCurrentCompany);

  useEffect(() => {
    if (companyId) {
      getCompanyData(+companyId);
    } else {
      messageApi.error("Couldn't load the page (company id is missing)");
    }
  }, []);

  const getCompanyData = async (companyId: number) => {
    try {
      const [{ data: company }, { data: companyStatistics }] =
        await Promise.all([
          getCompanyApi({ companyId }),
          getCompanyStatisticsApi({ companyId }),
        ]);

      setCompanyStatistics(companyStatistics);
      setError("");

      dispatch(setCurrentCompany(company));
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);

      setError(customError.message);
    } finally {
      setIsInitialized(true);
    }
  };

  return {
    currentCompany,
    activeCampaigns: companyStatistics?.campaignsCount || 0,
    activeUsers: companyStatistics?.usersCount || 0,
    isInitialized,
    error,
  };
};

export default useGetCompanyDetailsHook;
