import React, { FC, useState } from "react";
import { Modal } from "antd";
import localStorageProvider from "../../../utils/localStorageProvider";
import DownstreamDepsButton from "../DownstreamDepsButton";
import {
  executeCcVarApi,
  validateCcVarApi,
} from "../../../api/cc-variables.api";
import { resetComputationMessages } from "../../../store/slices/computationMessages/slice";
import handleRequestError from "../../../utils/handleRequestError";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { AppDispatch } from "../../../store/store";
import { Checkbox } from "../ui/checkbox";

type Props = {
  closeModal: () => void;
  isOpen: boolean;
  ccItemKey: string;
  campaignId: string;
  stepId: string;
  showDownstream?: boolean;
};
const ExecuteCcItemModal: FC<Props> = ({
  closeModal,
  isOpen,
  ccItemKey,
  stepId,
  campaignId,
  showDownstream = true,
}) => {
  const [updateDownstreamDependencies, setUpdateDownstreamDependencies] =
    useState(localStorageProvider.getUpdateDownstreamDependencies());
  const messageApi = useSelector(selectMessageApi);
  const dispatch: AppDispatch = useDispatch();
  const checkboxId = `${new Date().getTime()}_downstream`;

  const onExecute = async () => {
    try {
      const { data: isExecuting } = await validateCcVarApi({
        campaignId,
      });

      if (!isExecuting) {
        dispatch(resetComputationMessages());

        await executeCcVarApi({
          campaignId,
          key: ccItemKey,
          stepId,
          updateDownstreamDependencies,
        });

        messageApi.success("Execution started successfully");
      } else {
        messageApi.error(
          "There are some active computation elements in progress, please wait before execute",
        );
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const handleModalOk = async () => {
    closeModal();
    await onExecute();
  };

  const handleModalCancel = () => {
    closeModal();
  };

  return (
    <Modal
      title="Execute Item"
      open={isOpen}
      onOk={handleModalOk}
      onCancel={handleModalCancel}
      okText="Execute"
      okButtonProps={{ shape: "round" }}
      cancelButtonProps={{ shape: "round" }}
    >
      <div className="flex flex-col gap-2">
        {showDownstream && <DownstreamDepsButton ccItemKey={ccItemKey} />}

        <div
          className="flex gap-2 items-center"
          onClick={() => {
            setUpdateDownstreamDependencies(!updateDownstreamDependencies);
            localStorageProvider.setUpdateDownstreamDependencies(
              !updateDownstreamDependencies,
            );
          }}
        >
          <Checkbox id={checkboxId} checked={updateDownstreamDependencies} />
          <label
            htmlFor={checkboxId}
            className="cursor-pointer text-[#475569]"
            onClick={(e) => e.stopPropagation()}
          >
            Update Downstream Dependencies
          </label>
        </div>
      </div>
    </Modal>
  );
};

export default ExecuteCcItemModal;
