import { FC, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CampaignEntityType, TrackPostHogData } from "../types";
import { getCampaignEntitiesInfoApi } from "../api";
import posthog from "posthog-js";

type LoadInfoProps = {
  companyId: string | undefined;
  campaignId: string | undefined;
  phaseId: string | undefined;
  stepId: string | undefined;
};

const TrackCampaignPostHog: FC = () => {
  const { companyId, campaignId, phaseId, stepId } = useParams();
  const location = useLocation();

  const trackPageView = async ({
    companyId,
    campaignId,
    phaseId,
    stepId,
  }: LoadInfoProps) => {
    let trackData: Partial<TrackPostHogData> = {
      ...(companyId && { company_id: companyId }),
      ...(campaignId && { campaign_id: campaignId }),
      ...(phaseId && { phase_id: phaseId }),
      ...(stepId && { step_id: stepId }),
    };

    try {
      let entityType: CampaignEntityType | null = null;
      let entityId: string | null = null;

      if (stepId !== undefined) {
        entityType = "STEP";
        entityId = stepId;
      } else if (phaseId !== undefined) {
        entityType = "PHASE";
        entityId = phaseId;
      } else if (campaignId !== undefined) {
        entityType = "CAMPAIGN";
        entityId = campaignId;
      } else if (companyId !== undefined) {
        entityType = "COMPANY";
        entityId = companyId;
      }

      if (entityType && entityId) {
        const { data } = await getCampaignEntitiesInfoApi({
          entityType,
          entityId,
        });

        const [company_name, campaign_name, phase_name, step_name] = data;

        trackData = {
          ...trackData,
          ...(company_name && { company_name }),
          ...(campaign_name && { campaign_name }),
          ...(phase_name && { phase_name }),
          ...(step_name && { step_name }),
        };
      }
    } catch (e) {
      console.warn("Couldn't load info for PostHog (campaign page)", e);
    } finally {
      if (Object.keys(trackData).length > 0) {
        posthog?.capture("page_view", trackData);
      }
    }
  };

  useEffect(() => {
    trackPageView({ companyId, campaignId, phaseId, stepId });
  }, [companyId, campaignId, phaseId, stepId, location.pathname]);

  return null;
};

export default TrackCampaignPostHog;
