import { ArrowLeft, Loader2 } from "lucide-react";

import { Accordion } from "src/components/ui/accordion";
import { Dialog, DialogContent } from "src/components/common/ui/dialog";
import { ChatInfo } from "../../types";
import { FC, memo, useEffect, useState } from "react";
import { handleRequestError } from "src/utils/handleRequestError";
import { message } from "antd";
import { Button } from "src/components/common/ui/button";
import {
  useGetAllRevisionsForStepQuery,
  useGetRevisionCCItemsQuery,
  selectGroupedRevisions,
  historyApi,
} from "src/store/slices/historySlice";
import { createChatOverrideApi } from "../../../../api/chat.api";
import { useSelector } from "react-redux";
import { DayGroup } from "./day-group";
import { useErrors } from "src/hooks/useErrors";

interface DocumentModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  chatInfo: ChatInfo;
  ccVariables: string[];
  chatId: number;
}

const HistoryModal: FC<DocumentModalProps> = ({
  open,
  onOpenChange,
  chatInfo,
  ccVariables,
  chatId,
}) => {
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    string | null
  >(null);
  const [selectedRevisionId, setSelectedRevisionId] = useState<number | null>(
    null,
  );
  const [expandedDays, setExpandedDays] = useState<string[]>([]);
  const [isApplyingVersion, setIsApplyingVersion] = useState(false);

  const {
    data: ccItemsData,
    isFetching: isLoadingCCItems,
    error: ccItemsError,
  } = useGetRevisionCCItemsQuery(
    {
      uuid: selectedTransactionId,
      revisionId: selectedRevisionId,
      keys: ccVariables,
    },
    {
      skip: !selectedRevisionId || !selectedTransactionId,
      refetchOnMountOrArgChange: true,
    },
  );

  const { isFetching: isLoadingRevisions, error: revisionsError } =
    useGetAllRevisionsForStepQuery(
      { stepId: chatInfo.chat.stepId },
      {
        skip: !chatInfo.chat.stepId,
        refetchOnMountOrArgChange: true,
      },
    );

  useErrors([ccItemsError, revisionsError]);

  const groupedRevisions = useSelector(selectGroupedRevisions);

  useEffect(() => {
    if (!open) {
      setSelectedTransactionId(null);
      setSelectedRevisionId(null);
      setExpandedDays([]);
      historyApi.util.resetApiState();
    }
  }, [open]);

  // Update CC items when data is available
  useEffect(() => {
    if (ccItemsData) {
      setSelectedRevisionId(
        ccItemsData[0]?.id?.key ? selectedRevisionId : null,
      );
    }
  }, [ccItemsData, selectedRevisionId]);

  const handleDayExpand = (day: string) => {
    setExpandedDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day],
    );
  };

  const handleRevisionClick = (transactionId: string, revisionId: number) => {
    setSelectedRevisionId(null);
    setSelectedTransactionId(transactionId);
    setTimeout(() => {
      setSelectedRevisionId(revisionId);
    }, 0);
  };

  const handleApplyVersion = async () => {
    if (!selectedRevisionId || !ccItemsData?.length) return;

    try {
      setIsApplyingVersion(true);
      // Create chat overrides for each CC item
      const overridePromises = ccItemsData.map((item) =>
        createChatOverrideApi({
          chatOverride: {
            id: {
              chatId: chatId,
              key: item.id.key,
            },
            text: item.resultOvr || item.result || "",
            status: "READY",
          },
        }),
      );

      await Promise.all(overridePromises);
      message.success("Version applied successfully");
      onOpenChange(false);
      setIsApplyingVersion(false);
    } catch (e: any) {
      const customError = handleRequestError(e);
      message.error(customError.message);
      console.error(customError);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className="max-w-full w-screen h-screen p-0 m-0 !rounded-none"
        showCloseButton={false}
      >
        <div className="flex h-full overflow-hidden">
          <div className="flex-1 flex flex-col h-full overflow-hidden">
            <header className="flex items-center justify-start p-4 gap-3">
              <div className="flex items-center gap-4">
                <Button
                  variant="secondary"
                  className="bg-[#F1F5F9] text-[#64748B] w-[40px] h-[40px] rounded-full"
                  size="icon"
                  onClick={() => onOpenChange(false)}
                >
                  <ArrowLeft className="h-[24px] w-[24px] " />
                </Button>
                <h2 className="text-sm font-medium">
                  {selectedRevisionId
                    ? `Revision Details #${selectedRevisionId}`
                    : "History"}
                </h2>
              </div>
              {selectedRevisionId && ccItemsData && ccItemsData.length > 0 && (
                <Button
                  onClick={handleApplyVersion}
                  variant="outline"
                  size="sm"
                  className="rounded-full"
                  disabled={isApplyingVersion}
                >
                  {isApplyingVersion ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" /> Applying
                      Version
                    </>
                  ) : (
                    "Apply Version"
                  )}
                </Button>
              )}
            </header>
            <div className="flex-1 p-6 overflow-y-auto">
              {!selectedRevisionId && (
                <div className="text-center text-muted-foreground">
                  Select a revision to view details
                </div>
              )}
              {selectedRevisionId && (
                <>
                  <div className="w-full max-w-[860px] mx-auto flex flex-col gap-[32px]">
                    {isLoadingCCItems ? (
                      <div className="flex items-center justify-center h-32">
                        <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
                      </div>
                    ) : (
                      ccItemsData?.map((item) => (
                        <div key={item.id.key} className="flex w-full">
                          <div className="w-[340px] justify-start text-[#3c4e63] text-[13px] font-semibold font-geistMono leading-[13px]">
                            {item.id.key}
                          </div>
                          <div
                            className={
                              "justify-start text-slate-900 text-base font-normal font-instrumentSans leading-relaxed w-1/2"
                            }
                          >
                            {item.resultOvr || item.result || ""}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="w-[480px] border-l flex flex-col bg-[#F8FAFC] overflow-hidden">
            <div className="p-4 border-b">
              <h3 className="font-medium">Version History</h3>
            </div>
            <div className="flex-1 overflow-y-auto">
              {isLoadingRevisions ? (
                <div className="flex items-center justify-center h-32">
                  <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
                </div>
              ) : (
                <Accordion
                  type="multiple"
                  value={expandedDays}
                  onValueChange={setExpandedDays}
                  className="w-full"
                >
                  {groupedRevisions.map((group) => (
                    <DayGroup
                      key={group.day}
                      day={group.day}
                      revisions={group.revisions}
                      selectedRevisionId={selectedRevisionId}
                      onRevisionSelect={handleRevisionClick}
                      isExpanded={expandedDays.includes(group.day)}
                      onToggle={() => handleDayExpand(group.day)}
                    />
                  ))}
                </Accordion>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(HistoryModal, (prevProps, nextProps) => {
  return prevProps.chatInfo.chat.stepId === nextProps.chatInfo.chat.stepId;
});
