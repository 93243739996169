import { SYMBOLS_PER_SECOND } from "../constants";
import { ChatMessage, ChatOverride } from "../types";
import { TCcVariable } from "src/store/slices/ccVariablesSlice";
import { ChatState } from "src/store/slices/chatSlice";

export const getSelectedText = (
  ccVar: TCcVariable,
  ccVarChatOverride?: ChatOverride,
) => {
  return ccVarChatOverride?.text || ccVar.resultOvr || ccVar.result || "";
};

export const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.round(time % 60);
  if (minutes === 0) {
    return `${seconds} seconds`;
  }
  return `${minutes} minutes, ${seconds} seconds`;
};

export const calculateTime = (text: string): number => {
  const length = text.length;
  const time = length / SYMBOLS_PER_SECOND;
  return time;
};

export const getMessageVariant = (type: string) =>
  type === "REQUEST" ? "sent" : "received";

export const hasNewResponseMessage = (messages: ChatMessage[]): boolean => {
  if (messages.length === 0) return false;
  const lastMessage = messages[messages.length - 1];
  return lastMessage.type === "RESPONSE";
};

export const processKeysFromRequest = (
  state: ChatState,
  newChatOverrides: ChatOverride[],
) => {
  const keys = Object.keys(state.selectedCCVarKeys);

  keys.forEach((key) => {
    const ccVar = state.ccVars.find(
      (ccVar: TCcVariable) => ccVar.id.key === key,
    );
    if (!ccVar) return;

    const ccVarChatOverride = newChatOverrides.find(
      (override: ChatOverride) => override.id.key === key,
    );

    const text = getSelectedText(ccVar, ccVarChatOverride);
    console.log("processKeysFromRequest: text -> ", text);
    state.selectedCCVarKeys[key] = {
      text,
      selections: [{ start: 0, end: text.length }],
    };
  });
};
