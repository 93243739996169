import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TCcVariable } from "./ccVariablesSlice";
import {
  ChatOverride,
  ChatMessage,
  ChatMessageRequest,
  ChatInfo,
  KeyContext,
} from "src/components/CampaignChat/types";
import { RootState } from "../store";
import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utils/axios/axiosBaseQuery";
import {
  getSelectedText,
  hasNewResponseMessage,
} from "src/components/CampaignChat/utils";

export type ChatState = {
  selectedCCVarKeys: Record<string, KeyContext>;
  ccVars: TCcVariable[];
  chatOverrides: ChatOverride[];
  messages: ChatMessage[];
  chatInfo?: ChatInfo;
  isSelectKeysFullyAfterResponseReceived: boolean;
  lastModifiedOverrideKeys: string[];
};

const initialState: ChatState = {
  selectedCCVarKeys: {},
  ccVars: [],
  chatOverrides: [],
  messages: [],
  chatInfo: undefined,
  isSelectKeysFullyAfterResponseReceived: false,
  lastModifiedOverrideKeys: [],
};

enum CHAT_API_TAGS {
  ChatOverrides = "ChatOverrides",
  ChatMessages = "ChatMessages",
  CCVars = "CCVars",
}

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    CHAT_API_TAGS.ChatOverrides,
    CHAT_API_TAGS.ChatMessages,
    CHAT_API_TAGS.CCVars,
  ],
  endpoints: (builder) => ({
    getDocumentsCcVars: builder.query<
      TCcVariable[],
      { documentTemplateId: string | number; stepId: string | number }
    >({
      query: ({ documentTemplateId, stepId }) => ({
        url: `/api/secured/document-template/${documentTemplateId}/${stepId}/keys`,
        method: "GET",
      }),
      providesTags: [CHAT_API_TAGS.CCVars],
    }),
    getChatOverrides: builder.query<ChatOverride[], { chatId: number }>({
      query: ({ chatId }) => ({
        url: `/api/secured/chat/override/${chatId}`,
        method: "GET",
      }),
      providesTags: [CHAT_API_TAGS.ChatOverrides],
    }),
    getChatMessages: builder.query<
      ChatMessage[],
      { chatId: number; lastTimestamp?: string }
    >({
      query: ({ chatId, lastTimestamp }) => ({
        url: `/api/secured/chat/${chatId}/messages`,
        method: "GET",
        params: { lastTimestamp },
      }),
      providesTags: [CHAT_API_TAGS.ChatMessages],
    }),
    postChatMessage: builder.mutation<
      void,
      { chatId: number; message: ChatMessageRequest }
    >({
      query: ({ chatId, message }) => ({
        url: `/api/secured/chat/${chatId}/message`,
        method: "POST",
        data: message,
      }),
      async onQueryStarted(arg, { dispatch, getState }) {
        // Always set this flag to true when a new message is sent
        // This ensures we'll fully select keys that get updated by the AI
        dispatch(setSelectKeysFullyAfterResponseReceived(true));

        // Record the keys that were sent in this message
        if (arg.message.keysRequestContext) {
          const keys = Object.keys(arg.message.keysRequestContext);
          if (keys.length > 0) {
            console.log("Tracking keys sent in message:", keys);
          }
        }
      },
      invalidatesTags: [CHAT_API_TAGS.ChatMessages],
    }),
    updateChatOverride: builder.mutation<
      ChatOverride,
      { chatOverride: ChatOverride }
    >({
      query: ({ chatOverride }) => ({
        url: `/api/secured/chat/override`,
        method: "PUT",
        data: chatOverride,
      }),
      invalidatesTags: [CHAT_API_TAGS.ChatOverrides],
    }),
    createChatOverride: builder.mutation<
      ChatOverride,
      { chatOverride: ChatOverride }
    >({
      query: ({ chatOverride }) => ({
        url: `/api/secured/chat/override`,
        method: "POST",
        data: chatOverride,
      }),
      invalidatesTags: [CHAT_API_TAGS.ChatOverrides],
    }),
    updateChatContentBulk: builder.mutation<
      void,
      {
        campaignId: number;
        stepId: number;
        content: Record<string, string>;
        recomputeDownStream?: boolean;
      }
    >({
      query: ({ campaignId, stepId, content, recomputeDownStream }) => ({
        url: `/api/secured/${campaignId}/cc/${stepId}/content/bulk`,
        method: "PUT",
        data: content,
        params: {
          recomputeDownStream,
        },
      }),
      invalidatesTags: [CHAT_API_TAGS.CCVars],
    }),
  }),
});

export const {
  useGetDocumentsCcVarsQuery,
  useGetChatOverridesQuery,
  useGetChatMessagesQuery,
  usePostChatMessageMutation,
  useUpdateChatOverrideMutation,
  useCreateChatOverrideMutation,
  useUpdateChatContentBulkMutation,
} = chatApi;

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setCcVars: (state: ChatState, action: PayloadAction<TCcVariable[]>) => {
      state.ccVars = action.payload;
    },
    setChatOverrides: (
      state: ChatState,
      action: PayloadAction<ChatOverride[]>,
    ) => {
      state.chatOverrides = action.payload;
    },
    setMessages: (state: ChatState, action: PayloadAction<ChatMessage[]>) => {
      state.messages = action.payload;
    },
    setSelectedCCVarKeys: (
      state: ChatState,
      action: PayloadAction<string[]>,
    ) => {
      state.selectedCCVarKeys = action.payload.reduce(
        (acc, key) => {
          acc[key] = { text: "", selections: [] };
          return acc;
        },
        {} as Record<string, KeyContext>,
      );
    },
    selectCCVarKey: (
      state: ChatState,
      action: PayloadAction<{ key: string; context: KeyContext }>,
    ) => {
      state.selectedCCVarKeys[action.payload.key] = action.payload.context;
    },
    selectCCVarItemCompletely: (
      state: ChatState,
      action: PayloadAction<string>,
    ) => {
      const ccVar = state.ccVars.find(
        (ccVar) => ccVar.id.key === action.payload,
      );
      const ccVarChatOverride = state.chatOverrides.find(
        (override) => override.id.key === action.payload,
      );
      if (!ccVar) return;

      const text = getSelectedText(ccVar, ccVarChatOverride);

      console.log(
        `Selecting key ${action.payload} completely, text length: ${text.length}`,
      );

      // Always select the full text when explicitly calling this function
      state.selectedCCVarKeys[action.payload] = {
        text,
        selections: [{ start: 0, end: text.length }],
      };
    },
    selectKeysFully: (state: ChatState, action: PayloadAction<string[]>) => {
      action.payload.forEach((key) => {
        const ccVar = state.ccVars.find((ccVar) => ccVar.id.key === key);
        const ccVarChatOverride = state.chatOverrides.find(
          (override) => override.id.key === key,
        );
        if (!ccVar) return;

        const text = getSelectedText(ccVar, ccVarChatOverride);

        // Always select the full text
        state.selectedCCVarKeys[key] = {
          text,
          selections: [{ start: 0, end: text.length }],
        };
      });
    },
    unselectCCVarKey: (state: ChatState, action: PayloadAction<string>) => {
      delete state.selectedCCVarKeys[action.payload];
    },
    addChatOverride: (
      state: ChatState,
      action: PayloadAction<ChatOverride>,
    ) => {
      state.chatOverrides.push(action.payload);
    },
    deleteChatOverride: (
      state: ChatState,
      action: PayloadAction<ChatOverride>,
    ) => {
      state.chatOverrides = state.chatOverrides.filter(
        (override) => override.id !== action.payload.id,
      );
    },
    setSelectKeysFullyAfterResponseReceived: (
      state: ChatState,
      action: PayloadAction<boolean>,
    ) => {
      state.isSelectKeysFullyAfterResponseReceived = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      chatApi.endpoints.getDocumentsCcVars.matchFulfilled,
      (state, { payload }) => {
        state.ccVars = payload;
      },
    );
    builder.addMatcher(
      chatApi.endpoints.getChatOverrides.matchFulfilled,
      (state, { payload }) => {
        const newChatOverrides = [...payload];
        const currentChatOverrides = state.chatOverrides || [];
        const hasNewResponse = hasNewResponseMessage(state.messages);

        // Find any new or modified overrides by comparing the payload with current state
        const modifiedOverrides = newChatOverrides.filter((newOverride) => {
          const existingOverride = currentChatOverrides.find(
            (oldOverride) => oldOverride.id.key === newOverride.id.key,
          );

          return (
            !existingOverride ||
            existingOverride.text !== newOverride.text ||
            existingOverride.status !== newOverride.status
          );
        });

        // Only select overrides with READY status - these are the ones we want to fully select
        const readyOverrideKeys = modifiedOverrides
          .filter((override) => override.status === "READY")
          .map((override) => override.id.key);

        // Other statuses (APPLIED, CANCELED) should not trigger full selection
        const nonReadyOverrideKeys = modifiedOverrides
          .filter((override) => override.status !== "READY")
          .map((override) => override.id.key);

        state.lastModifiedOverrideKeys = readyOverrideKeys;
        state.chatOverrides = [...newChatOverrides];

        // Check for keys to update if there are modified overrides
        if (modifiedOverrides.length > 0) {
          // Get all keys that were previously selected by the user
          const selectedKeys = Object.keys(state.selectedCCVarKeys);

          // Only process ready overrides for selection
          // If no keys are selected, do not auto-select even when overrides are received
          const shouldSelectFully =
            (hasNewResponse || state.isSelectKeysFullyAfterResponseReceived) &&
            selectedKeys.length > 0; // Only auto-select if user already had selections

          // Filter keys to only include those with READY status
          const keysToProcess = shouldSelectFully
            ? readyOverrideKeys
            : selectedKeys.filter((key) => readyOverrideKeys.includes(key));

          // For non-ready overrides, unselect them if they were previously selected
          nonReadyOverrideKeys.forEach((key) => {
            if (selectedKeys.includes(key)) {
              console.log(`Unselecting non-ready key: ${key}`);
              delete state.selectedCCVarKeys[key];
            }
          });

          if (keysToProcess.length > 0) {
            console.log(
              "Selecting keys fully after override update:",
              keysToProcess,
            );

            // Process each key that needs full selection
            keysToProcess.forEach((key) => {
              const ccVar = state.ccVars.find((ccVar) => ccVar.id.key === key);
              const ccVarChatOverride = newChatOverrides.find(
                (override) => override.id.key === key,
              );

              if (ccVar) {
                const text = getSelectedText(ccVar, ccVarChatOverride);
                console.log(
                  `Selecting key ${key} fully, text length: ${text.length}`,
                );

                // ALWAYS select the ENTIRE text for READY overrides
                state.selectedCCVarKeys[key] = {
                  text,
                  selections: [{ start: 0, end: text.length }],
                };
              }
            });
          }

          // Reset the flag after processing
          state.isSelectKeysFullyAfterResponseReceived = false;
        }
      },
    );
    builder.addMatcher(
      chatApi.endpoints.getChatMessages.matchFulfilled,
      (state, { payload }) => {
        state.messages = payload;
      },
    );
  },
});

export const {
  setCcVars,
  setChatOverrides,
  setMessages,
  addChatOverride,
  deleteChatOverride,
  setSelectedCCVarKeys,
  selectCCVarKey,
  unselectCCVarKey,
  selectKeysFully,
  selectCCVarItemCompletely,
  setSelectKeysFullyAfterResponseReceived,
} = chatSlice.actions;

export const selectChatState = createSelector(
  (state: RootState) => state.chat,
  (chat) => chat,
);

export const selectChatCcVars = createSelector(
  (state: RootState) => state.chat.ccVars,
  (ccVars) => ccVars,
);

export const selectChatOverrides = createSelector(
  (state: RootState) => state.chat.chatOverrides,
  (chatOverrides) => chatOverrides,
);

export const selectChatMessages = createSelector(
  (state: RootState) => state.chat.messages,
  (messages) => messages,
);

export const selectChatSelectedCCVars = createSelector(
  (state: RootState) => state.chat.selectedCCVarKeys,
  (selectedCCVarKeys) => selectedCCVarKeys,
);

export const selectChatSelectedCCVarKeys = createSelector(
  (state: RootState) => state.chat.selectedCCVarKeys,
  (selectedCCVarKeys) => Object.keys(selectedCCVarKeys),
);

export const selectSelectedCCVars = createSelector(
  selectChatCcVars,
  selectChatSelectedCCVarKeys,
  (ccVars, selectedCCVarKeys) =>
    ccVars.filter((ccVar) =>
      Object.keys(selectedCCVarKeys).includes(ccVar.id.key),
    ),
);

export const selectPendingChatOverrides = createSelector(
  selectChatOverrides,
  (chatOverrides) =>
    chatOverrides.filter((override) => override.status === "READY"),
);

export default chatSlice.reducer;
