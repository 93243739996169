import { Route, Routes } from "react-router-dom";
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";
import { UserPermissions } from "../../store/slices/userData/constants";
import NotFoundPage from "../../pages/NotFoundPage";
import React from "react";
import UserApiKeysPage from "../../pages/account/UserApiKeysPage";

const AccountRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_UI_SHOW_USER_API_KEY}
          >
            <UserApiKeysPage />
          </ProtectedByRoleRoute>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AccountRoutes;
