import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type TCompany = {
  createdTime: null | string;
  id: number;
  logo: null | string;
  name: string;
  website: null | string;
  disabled: boolean;
};

const initialState = {
  current: null as TCompany | null,
};

type InitialStateType = typeof initialState;

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCurrentCompany: (
      state: InitialStateType,
      action: PayloadAction<TCompany | null>,
    ) => {
      state.current = action.payload;
    },
  },
});

export const { setCurrentCompany } = companiesSlice.actions;

export default companiesSlice.reducer;

export const selectCurrentCompany = (state: RootState) =>
  state.companies.current;
