import { FormInstance, Popover, Typography } from "antd";
import clsx from "clsx";
import React, { FC, useState } from "react";
import { TVarType } from "src/types";
import ArgumentContent from "./ArgumentContent";

type Props = {
  varKey: string | null;
  varType: TVarType;
  index: number;
  form: FormInstance<any>;
  formPromptArgsKey: string;
  gridItemSequence: number;
  localKeysOptions: { label: string; value: string }[];
};

const TemplatePromptVar: FC<Props> = ({
  varKey,
  index,
  form,
  formPromptArgsKey,
  gridItemSequence,
  varType,
  localKeysOptions,
}) => {
  const [open, setOpen] = useState(false);
  const argumentPlaceholder = varKey || "var";

  return (
    <Popover
      destroyTooltipOnHide
      content={
        <ArgumentContent
          form={form}
          varKey={varKey}
          varType={varType}
          index={index}
          localKeysOptions={localKeysOptions}
          formPromptArgsKey={formPromptArgsKey}
          gridItemSequence={gridItemSequence}
          closePopup={() => setOpen(false)}
        />
      }
      title={
        <span className="font-bold text-[#475569] text-[14px]">
          Choose Argument
        </span>
      }
      trigger="click"
      open={open}
      onOpenChange={setOpen}
    >
      <Typography.Text
        code
        strong
        className={clsx(
          "font-bold text-[#ff761b] cursor-pointer hover:text-[#a54200] transition-colors",
          {
            "!hover:text-[#002c02] !text-[#007706]": varKey !== null,
          },
        )}
      >
        {argumentPlaceholder}
      </Typography.Text>
    </Popover>
  );
};

export default TemplatePromptVar;
