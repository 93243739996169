import React, { FC, useState } from "react";
import { DeleteIcon, Icons } from "../../common/Icons";
import MenuDropdown, { MenuDropdownItem } from "../../common/MenuDropdown";
import { AssetListItem, TAsset } from "../../../globalTypes";
import { Button } from "../../common/ui/button";
import DeleteWithFilesModal from "../../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { deleteAssetApi } from "../../../api/assets.api";
import handleRequestError from "../../../utils/handleRequestError";
import { EditAssetModal } from "./edit-asset-modal";

type Props = {
  asset: TAsset;
  loadAndSetAssets: () => Promise<void>;
  setAssets: React.Dispatch<React.SetStateAction<AssetListItem[]>>;
};

export const AssetMenu: FC<Props> = ({
  asset,
  loadAndSetAssets,
  setAssets,
}) => {
  const { companyId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { hasAssetDeleteRole, hasAssetUpdateRole } = useUserHasPermission({
    companyId,
  });

  const { id, type, campaignId } = asset;

  const onDeleteAsset = async (assetId: number, removeFiles?: boolean) => {
    try {
      await deleteAssetApi({
        campaignId,
        assetId,
        removeFiles,
      });

      messageApi.success("The asset has been deleted");

      await loadAndSetAssets();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const menuItems = [
    hasAssetUpdateRole &&
      type !== "GENERATED" && {
        key: "edit",
        label: "Edit",
        onClick: () => setIsEditModalOpen(true),
        icon: Icons.edit,
      },
    hasAssetDeleteRole && {
      key: "delete",
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  if (menuItems.length === 0) return null;

  return (
    <>
      <MenuDropdown items={menuItems} dropdownClassName="w-[190px]" align="end">
        <Button variant="ghostPrimary" className="size-10 p-2">
          <Icons.MoreDots className="size-[24px]" />
        </Button>
      </MenuDropdown>

      {hasAssetDeleteRole && isDeleteModalOpen && (
        <DeleteWithFilesModal
          isModalOpen={isDeleteModalOpen}
          entity="Asset"
          title={asset.title}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={(removeFiles) => onDeleteAsset(id, removeFiles)}
        />
      )}

      {hasAssetUpdateRole && isEditModalOpen && (
        <EditAssetModal
          assetId={asset.id}
          setAssets={setAssets}
          isModalOpen={isEditModalOpen}
          closeModal={() => setIsEditModalOpen(false)}
        />
      )}
    </>
  );
};
