import { getNumOfVarsInPrompt } from "../../../utils/cm.utils";
import { TVarType, VarTypeEnum } from "../../../types";
import { PostProcessingItem } from "./types";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";

export const prepareTemplate = (template: string) => {
  const count = getNumOfVarsInPrompt(template);
  const varKeysArr = Array(count).fill(null);

  return {
    count,
    varKeysArr,
    template,
  };
};

export const getTypeBasedOnSegmented = (
  varType: VarTypeEnum,
): PostProcessingItem["type"] => {
  if (varType === VarTypeEnum.CAMPAIGN_VAR) {
    return "cc";
  }

  if (varType === VarTypeEnum.ENVIRONMENT_VAR) {
    return "env";
  }

  return "text";
};

export const getSegmentedBasedOnType = (type: string): TVarType => {
  if (type === "cc") {
    return VarTypeEnum.CAMPAIGN_VAR;
  }

  if (type === "env") {
    return VarTypeEnum.ENVIRONMENT_VAR;
  }

  return VarTypeEnum.RAW;
};

type PostProcessingOption = { label: string; value: string };
export const getPostProcessingOptions = (
  postProcessingIndex: number,
): PostProcessingOption[] => {
  const options: PostProcessingOption[] = [
    {
      label: "Source computation result",
      // eslint-disable-next-line no-template-curly-in-string
      value: "${0}",
    },
  ];

  for (let i = 0; i < postProcessingIndex; i++) {
    options.push({
      label: `Post Processing Key ${i + 1}`,
      value: "${" + (i + 1) + "}",
    });
  }

  return options;
};

export const parsePostProcessingParams = (
  ccItem: TCcVariable | null,
): PostProcessingItem[] => {
  const postProcessingParams = ccItem?.options?.postProcessingParams;

  if (postProcessingParams) {
    try {
      return JSON.parse(postProcessingParams);
    } catch (e) {
      console.error("Failed to parse post processing params", e);
      return [];
    }
  }

  return [];
};
