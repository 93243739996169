import { useEffect, useState } from "react";

interface TextSelectionState {
  x: number;
  y: number;
  visible: boolean;
  selectedText: string;
  startIndex: number;
  endIndex: number;
  parentElement: HTMLElement | null;
}

const initialSelectionState: TextSelectionState = {
  x: 0,
  y: 0,
  visible: false,
  selectedText: "",
  startIndex: 0,
  endIndex: 0,
  parentElement: null,
};

export const useTextSelection = (selector: string) => {
  const [selectionState, setSelectionState] = useState<TextSelectionState>(
    initialSelectionState,
  );

  // Handle selection in the target element
  const handleTextSelection = (event: MouseEvent) => {
    const selection = window.getSelection();

    if (selection && selection.toString().trim().length > 0) {
      // Only show if there's actual text selected
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      // Get the parent element containing the selection
      let parentElement = range.commonAncestorContainer as HTMLElement;
      if (parentElement.nodeType === Node.TEXT_NODE) {
        parentElement = parentElement.parentElement as HTMLElement;
      }

      // Get the container with the data-original-complete-text attribute (if any)
      const container = parentElement.closest('[data-original-complete-text]') as HTMLElement || parentElement;
      const selectedText = selection.toString();

      // Find the start and end indices based on the contents or data attributes
      let startIndex = 0;
      let endIndex = 0;

      if (container.hasAttribute('data-original-complete-text')) {
        const originalText = container.getAttribute('data-original-complete-text') || '';

        // Find all text spans within the container
        const spans = Array.from(container.querySelectorAll('span[data-start-index]'));

        if (spans.length > 0) {
          // Try to find which spans contain the start and end of the selection
          const startContainer = range.startContainer.nodeType === Node.TEXT_NODE
            ? range.startContainer.parentElement
            : range.startContainer as HTMLElement;

          const endContainer = range.endContainer.nodeType === Node.TEXT_NODE
            ? range.endContainer.parentElement
            : range.endContainer as HTMLElement;

          // Get start index
          if (startContainer && startContainer.hasAttribute('data-start-index')) {
            const spanStartIndex = parseInt(startContainer.getAttribute('data-start-index') || '0', 10);
            startIndex = spanStartIndex + range.startOffset;
          } else {
            // Fallback - look for the selected text in the original text
            startIndex = originalText.indexOf(selectedText);
          }

          // Get end index
          if (endContainer && endContainer.hasAttribute('data-start-index')) {
            const spanStartIndex = parseInt(endContainer.getAttribute('data-start-index') || '0', 10);
            endIndex = spanStartIndex + range.endOffset;
          } else {
            // Fallback
            endIndex = startIndex + selectedText.length;
          }
        } else {
          // If there are no spans with data-start-index, try to find the text directly
          startIndex = originalText.indexOf(selectedText);
          endIndex = startIndex + selectedText.length;
        }
      } else {
        // Basic behavior for elements without data attributes
        const fullText = parentElement.textContent || '';
        startIndex = fullText.indexOf(selectedText);
        endIndex = startIndex + selectedText.length;
      }

      // Make sure we have valid indices
      if (startIndex < 0) startIndex = 0;
      if (endIndex <= startIndex) endIndex = startIndex + selectedText.length;

      setSelectionState({
        x: event.clientX,
        y: rect.bottom, // Position right under the selected text
        visible: true,
        selectedText,
        startIndex,
        endIndex,
        parentElement: container,
      });
    } else {
      setSelectionState(initialSelectionState);
    }
  };

  // Function to handle clicking outside the dropdown
  const handleClickOutside = (event: MouseEvent) => {
    if (selectionState.visible) {
      const target = event.target as HTMLElement;
      const dropdown = document.getElementById("selection-dropdown");

      if (dropdown && !dropdown.contains(target)) {
        setSelectionState({ ...selectionState, visible: false });
      }
    }
  };

  // Set up event listeners
  useEffect(() => {
    const handleMouseUp = (event: MouseEvent) => {
      // Check if the selection is within the specified selector
      const target = event.target as HTMLElement;
      const isTargetElement = target.closest(selector);

      if (isTargetElement) {
        setTimeout(() => handleTextSelection(event), 0);
      }
    };

    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selector, selectionState.visible]);

  // Helper to hide the selection UI
  const hideSelection = () => {
    setSelectionState({ ...selectionState, visible: false });
  };

  return {
    selectionState,
    hideSelection,
  };
};
