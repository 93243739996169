import React from "react";
import { Menu, MenuProps } from "antd";
import { useGetNavigationData } from "../hooks/useGetNavigationData";
import { Link } from "react-router-dom";
import clsx from "clsx";

export const Navigation = () => {
  const { navDataList, activeNavKeys } = useGetNavigationData();

  const menuItems: MenuProps["items"] = [
    ...navDataList.map(({ path, label, active }) => ({
      key: path,
      label: (
        <Link
          to={path}
          onClick={(e) => active && e.preventDefault()}
          className={clsx(
            "text-sm font-semibold",
            {
              "text-vePrimary pointer-events-none": active,
            },
            { "text-slate-700": !active },
          )}
        >
          {label}
        </Link>
      ),
    })),
  ];

  return (
    <Menu
      mode="horizontal"
      selectedKeys={activeNavKeys}
      items={menuItems}
      className="h-16 border-none w-[70%] flex pt-2.5 gap-3"
    />
  );
};
