import { TStep } from "../store/slices/stepsSlice";
import axiosInstance from "../utils/axios/axiosInstance";
import { TMovePhaseStepType } from "../globalTypes";

export const createStepApi = ({
  phaseId,
  addType,
  targetStepId,
  campaignId,
  ...step
}: {
  campaignId: number;
  phaseId: number;
  name: string;
  hidden: boolean;
  classes: string[];
  microSiteContextFolder: null | string;
  hiddenDocView: boolean;
  addType: TMovePhaseStepType;
  targetStepId?: number;
}) => {
  return axiosInstance.post<TStep>(
    `/api/secured/${campaignId}/${phaseId}/step/`,
    step,
    {
      params: {
        addType,
        targetStepId,
      },
    },
  );
};

export const updateStepApi = ({
  step,
  rebuild,
  campaignId,
}: {
  step: TStep;
  campaignId: number;
  rebuild?: boolean;
}) => {
  return axiosInstance.put<TStep>(
    `/api/secured/${campaignId}/${step.phaseId}/step/`,
    step,
    {
      params: {
        rebuild,
      },
    },
  );
};

export const updateDocVisibleStepApi = ({
  stepId,
  phaseId,
  enable,
  campaignId,
}: {
  campaignId: number;
  stepId: number;
  phaseId: number;
  enable: boolean;
}) => {
  return axiosInstance.put<TStep>(
    `/api/secured/${campaignId}/${phaseId}/step/${stepId}/enableDocView/${enable}`,
    {},
  );
};

export const deleteStepApi = ({
  phaseId,
  stepId,
  removeFiles,
  campaignId,
}: {
  campaignId: number;
  phaseId: number;
  stepId: number;
  removeFiles: boolean;
}) => {
  return axiosInstance.delete<string>(
    `/api/secured/${campaignId}/${phaseId}/step/${stepId}`,
    {
      params: { removeFiles },
    },
  );
};

export const exportCSVStepApi = ({
  phaseId,
  stepId,
  campaignId,
}: {
  campaignId: number;
  phaseId: number;
  stepId: number;
}) => {
  return axiosInstance.get<string>(
    `/api/secured/${campaignId}/${phaseId}/step/${stepId}/export-csv`,
  );
};

export const getStepsApi = ({
  phaseId,
  campaignId,
}: {
  phaseId: number;
  campaignId: number;
}) => {
  return axiosInstance.get<TStep[]>(
    `/api/secured/${campaignId}/${phaseId}/step/`,
  );
};

export const cloneStepApi = ({
  phaseId,
  stepId,
  name,
  resetResult,
  resetOvr,
  campaignId,
}: {
  campaignId: number;
  phaseId: number;
  stepId: number;
  name: string;
  resetResult: boolean;
  resetOvr: boolean;
}) => {
  return axiosInstance.put<TStep>(
    `/api/secured/${campaignId}/${phaseId}/step/${stepId}/clone`,
    { name },
    {
      params: {
        resetResult,
        resetOvr,
      },
    },
  );
};

export const publishStepToMicroSiteApi = ({
  phaseId,
  stepId,
  campaignId,
}: {
  campaignId: number;
  phaseId: number;
  stepId: number;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/${campaignId}/${phaseId}/step/${stepId}/microsite-deploy`,
    {},
  );
};

export const copyStepToApi = ({
  phaseId,
  targetPhase,
  stepId,
  name,
  newSeq,
  resetResult,
  resetOvr,
  campaignId,
}: {
  campaignId: number;
  phaseId: number;
  targetPhase: number;
  stepId: number;
  name: string;
  newSeq: number;
  resetResult: boolean;
  resetOvr: boolean;
}) => {
  return axiosInstance.put<TStep>(
    `/api/secured/${campaignId}/${phaseId}/step/${stepId}/copy`,
    {},
    {
      params: {
        resetResult,
        resetOvr,
        name,
        newSeq,
        targetPhase,
      },
    },
  );
};

export const getTemplatePreview = ({
  phaseId,
  stepId,
  documentTemplateId,
  campaignId,
}: {
  campaignId: number;
  phaseId: number;
  stepId: number;
  documentTemplateId: number;
}) => {
  return axiosInstance.get<ArrayBuffer>(
    `/api/secured/${campaignId}/${phaseId}/step/${stepId}/${documentTemplateId}/preview`,
    {
      responseType: "arraybuffer",
    },
  );
};

export const moveStepApi = ({
  moveType,
  targetStepId,
  stepId,
  phaseId,
  campaignId,
}: {
  targetStepId: number;
  stepId: number;
  phaseId: number;
  campaignId: number;
  moveType: TMovePhaseStepType;
}) => {
  return axiosInstance.put<TStep[]>(
    `/api/secured/${campaignId}/${phaseId}/step/move/${stepId}/`,
    {},
    {
      params: {
        targetStepId,
        moveType,
      },
    },
  );
};

export const getTemplatePreviewDataApi = ({
  content,
  stepId,
  phaseId,
  campaignId,
}: {
  content: string;
  stepId: number;
  phaseId: number;
  campaignId: number;
}) => {
  return axiosInstance.post<string>(
    `/api/secured/${campaignId}/${phaseId}/step/${stepId}/preview`,
    content,
    {
      headers: {
        "Content-Type": "text/html",
      },
    },
  );
};

export const downloadStepTemplatePDFApi = ({
  phaseId,
  stepId,
  template,
  campaignId,
}: {
  campaignId: number;
  phaseId: number;
  stepId: number;
  template: number;
}) => {
  return axiosInstance.get<ArrayBuffer>(
    `/api/secured/${campaignId}/${phaseId}/step/${stepId}/${template}/preview-pdf`,
    {
      responseType: "arraybuffer",
    },
  );
};
