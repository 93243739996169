import React, { FC } from "react";
import { Button, Popover, Select, Spin } from "antd";
import useGetSecurityGroups from "./useGetSecurityGroups.hook";
import {
  AddGroupAndUpdateList,
  LoadPermissionGroups,
} from "../../../types/securityGroups";

type Props = {
  addGroupAndUpdateList: AddGroupAndUpdateList;
  loadPermissionGroups: LoadPermissionGroups;
};
const AddSecurityGroup: FC<Props> = ({
  addGroupAndUpdateList,
  loadPermissionGroups,
}) => {
  const {
    isInitialized,
    options,
    setSelectedGroupId,
    selectedGroupId,
    addPermission,
    isFetchingAddGroup,
    loadPermissions,
    open,
    isFetchingGroups,
    handleOpenChange,
  } = useGetSecurityGroups({ addGroupAndUpdateList, loadPermissionGroups });

  return (
    <div>
      <Popover
        content={
          <div className="flex flex-col gap-[12px] w-[355px]">
            <div className="flex flex-col gap-[8px]">
              <span className="font-bold text-[14px] text-[#475569]">
                Security Groups
              </span>
              <Spin spinning={isFetchingGroups}>
                <Select
                  loading={!isInitialized}
                  options={options}
                  size="middle"
                  placeholder="Select the group to add"
                  onChange={(value: number) => setSelectedGroupId(value)}
                  value={selectedGroupId}
                  className="w-[300px]"
                />
              </Spin>
            </div>

            <Button
              className="self-end"
              size="small"
              type="primary"
              loading={isFetchingAddGroup}
              disabled={!selectedGroupId}
              onClick={() => addPermission(selectedGroupId)}
            >
              Save
            </Button>
          </div>
        }
        trigger="click"
        open={open}
        destroyTooltipOnHide
        onOpenChange={handleOpenChange}
      />
      <Button
        type="primary"
        size="small"
        onClick={loadPermissions}
      >
        Add Group
      </Button>
    </div>
  );
};

export default AddSecurityGroup;
