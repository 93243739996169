import React, { FC, useCallback, useState } from "react";
import { Dropdown, MenuProps } from "antd";
import {
  ASSET_STATE_MENU_ITEMS,
  STATE_OPTIONS_CONFIG,
  VISIBLE_ASSET_STATE_ITEMS_MAP,
} from "../constants";
import { AssetListItem, AssetPublishState, TAsset } from "../../../globalTypes";
import { RiArrowDownSLine } from "@remixicon/react";
import clsx from "clsx";
import handleRequestError from "../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { updateAssetApi } from "../../../api/assets.api";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import { Loader2 } from "lucide-react";

type Props = {
  asset: TAsset;
  setAssets: React.Dispatch<React.SetStateAction<AssetListItem[]>>;
};

export const ApproveAssetSelector: FC<Props> = ({ asset, setAssets }) => {
  const { publishedState, id } = asset;
  const { title, className } = STATE_OPTIONS_CONFIG[publishedState];
  const messageApi = useSelector(selectMessageApi);
  const { companyId } = useParams();
  const [isPublishing, setIsPublishing] = useState(false);
  const [loading, setLoading] = useState(false);
  const { hasPermission } = useUserHasPermission({ companyId });

  const getFilteredMenuItems = useCallback((): MenuProps["items"] => {
    return ASSET_STATE_MENU_ITEMS.filter(
      ({ key, permission }) =>
        VISIBLE_ASSET_STATE_ITEMS_MAP[publishedState]?.includes(key) &&
        hasPermission(permission),
    ).map(({ title, key, className, state }) => ({
      label: (
        <div
          className={clsx(
            "inline-flex items-center justify-center rounded-[4px] px-1 py-[5.5px] text-[13px] leading-[13px] font-bold my-[3px]",
            className,
          )}
        >
          {title}
        </div>
      ),
      key: key,
      onClick: () => handleUpdateState(state),
    }));
  }, [publishedState]);

  const handleUpdateState = useCallback(
    async (state: AssetPublishState) => {
      setLoading(true);

      if (state === "PUBLISHED") {
        setIsPublishing(true);
      }

      try {
        const { data } = await updateAssetApi({
          ...asset,
          publishedState: state,
        });
        const newState = data.asset.publishedState;

        setAssets((prevItems) =>
          prevItems.map((item) => (item.asset.id === id ? data : item)),
        );

        messageApi[newState === "ERROR" ? "error" : "success"](
          `Status has been changed to ${STATE_OPTIONS_CONFIG[data.asset.publishedState].title}`,
        );
      } catch (e: any) {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);
      } finally {
        setLoading(false);
        setIsPublishing(false);
      }
    },
    [asset, id, setAssets, messageApi],
  );

  const menuItems = getFilteredMenuItems();
  const isMenuEmpty = menuItems?.length === 0;

  return (
    <Dropdown
      menu={{ items: getFilteredMenuItems() }}
      trigger={["click"]}
      disabled={loading || isMenuEmpty}
    >
      <div
        className={clsx(
          "inline-flex items-center gap-2 px-3 py-2 rounded-[100px] font-bold leading-[100%] transition-all",
          className,
          { "opacity-50": loading },
          { "hover:opacity-80": !loading && !isMenuEmpty },
          { "cursor-pointer": !isMenuEmpty },
        )}
      >
        {isPublishing ? "Publishing..." : <>{title}</>}
        {!isMenuEmpty && loading ? (
          <Loader2 className="h-4 w-4 animate-spin" />
        ) : (
          <RiArrowDownSLine className="size-4" />
        )}
      </div>
    </Dropdown>
  );
};
