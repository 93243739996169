import React from "react";
import { Skeleton } from "antd";
import { PageHeader } from "../../components/common/PageHeader";
import { EmptyResult } from "../../components/common/EmptyResult";
import {
  ActionsMenu,
  ArchiveSelector,
  CampaignTable,
  CompanySelector,
  Pagination,
  StartCampaign,
  useInitCampaignList,
} from "../../components/campaignsList";

const CampaignListPage = () => {
  const {
    campaignParams,
    initialized,
    companies,
    setCompanyIdParam,
    setArchivedParam,
    setPageSizeParam,
    setPageNumParam,
    setSortingParams,
    reloadCampaignList,
    totalCampaigns,
    campaigns,
    loading,
    error,
  } = useInitCampaignList();

  const header = (
    <PageHeader
      title="Campaigns"
      showBackButton={!!campaignParams.backUrl}
      backUrl={campaignParams.backUrl}
    />
  );

  if (!initialized)
    return (
      <div className="flex flex-col gap-4">
        {header}

        <Skeleton active paragraph={{ rows: 10 }} />
      </div>
    );

  if (error)
    return (
      <div className="flex flex-col gap-4">
        {header}

        <span className="text-red-600">{error}</span>
      </div>
    );

  if (!companies.length)
    return (
      <div className="flex flex-col gap-4">
        {header}
        <EmptyResult message="You don't have any campaigns available, ask your admin to grant access." />
      </div>
    );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        {header}

        <div className="flex items-center flex-wrap gap-3">
          <div className="flex flex-wrap gap-3">
            <CompanySelector
              companies={companies}
              currentCompanyId={campaignParams.companyId}
              setCompanyIdParam={setCompanyIdParam}
            />
            <ArchiveSelector
              archive={campaignParams.archive}
              setArchiveParam={setArchivedParam}
            />

            <div className="flex gap-3">
              <ActionsMenu companyId={campaignParams.companyId} />
              <StartCampaign companyId={campaignParams.companyId} />
            </div>
          </div>

          <div className="ml-auto">
            <Pagination
              totalCampaigns={totalCampaigns}
              pageSize={campaignParams.pageSize}
              page={campaignParams.page}
              onPageChange={setPageNumParam}
              onPageSizeChange={setPageSizeParam}
            />
          </div>
        </div>
      </div>

      <CampaignTable
        campaigns={campaigns}
        loading={loading}
        setSortingParams={setSortingParams}
        orderByParam={campaignParams.orderBy}
        sortByParam={campaignParams.sortBy}
        reloadCampaignList={reloadCampaignList}
      />

      <Pagination
        totalCampaigns={totalCampaigns}
        pageSize={campaignParams.pageSize}
        page={campaignParams.page}
        onPageChange={setPageNumParam}
        onPageSizeChange={setPageSizeParam}
      />
    </div>
  );
};

export default CampaignListPage;
