import React, { FC } from "react";
import { Button } from "../../common/ui/button";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { CampaignType } from "../types";
import { createCampaignThunk } from "../../../store/slices/campaignsSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { selectMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";

type Props = {
  companyId: number | null;
  campaignType: CampaignType;
};

export const CreateDraftCampaign: FC<Props> = ({ companyId, campaignType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = useSelector(selectMessageApi);
  const { hasCampaignCreateRole } = useUserHasPermission({ companyId });

  const onCampaignCreate = async (companyId: number) => {
    try {
      const newCampaign = await dispatch(
        createCampaignThunk({ companyId }),
      ).unwrap();

      navigate(
        `/campaigns/company/${newCampaign.companyId}/campaign/${newCampaign.id}`,
      );
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  return (
    <>
      {hasCampaignCreateRole && (
        <Button
          className="rounded-full"
          onClick={
            companyId !== null ? () => onCampaignCreate(companyId) : undefined
          }
          disabled={companyId === null || campaignType === "PUBLISHED_TEMPLATE"}
        >
          <DocumentPlusIcon className="mr-[4px] size-4" />
          Add Draft Template
        </Button>
      )}
    </>
  );
};
