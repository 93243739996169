import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import useGetPrompt from "../../paneCCItem/hooks/useGetPrompt";
import { Button, Skeleton } from "antd";
import { updateEnvVarApi } from "../../../api/env-variables.api";
import handleRequestError from "../../../utils/handleRequestError";
import { OnAfterCCItemUpdate } from "../types";

type Props = {
  onAfterCCItemUpdate: OnAfterCCItemUpdate;
  ccItem: TCcVariable;
  hasCampaignEnvItemUpdateRole: boolean;
};

const PromptTabContent: React.FC<Props> = ({
  ccItem,
  onAfterCCItemUpdate,
  hasCampaignEnvItemUpdateRole,
}) => {
  const { campaignId, stepId, key } = ccItem.id;
  const editableDivRef = useRef<HTMLDivElement>(null);
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const { initialized, splitPrompt, error } = useGetPrompt({
    campaignId,
    stepId,
    key,
  });

  if (!initialized) return <Skeleton active />;
  if (error) return <span className="text-red-600">{error}</span>;

  const handleSaveClick = async () => {
    try {
      if (editableDivRef.current && ccItem.options?.promptKey) {
        setIsFetching(true);

        const spans = editableDivRef.current.querySelectorAll("span");
        let updatedText = "";

        spans.forEach((span) => {
          updatedText += span.textContent || "";
        });

        const promptContent = updatedText.replace(/<\|[^|]+\|>/g, "%s");
        const values = {
          key: ccItem.options.promptKey,
          type: ccItem.type,
          value: promptContent,
        };

        await updateEnvVarApi({ campaignId, values });

        await onAfterCCItemUpdate(ccItem);

        messageApi.success("The prompt has been successfully updated");
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div ref={editableDivRef} className="whitespace-pre-wrap">
        {splitPrompt.map((element, index) => (
          <React.Fragment key={index}>{element} </React.Fragment>
        ))}
      </div>

      {hasCampaignEnvItemUpdateRole && (
        <Button
          size="large"
          type="primary"
          className="font-semibold text-sm !px-8 self-end"
          loading={isFetching}
          onClick={handleSaveClick}
          shape="round"
        >
          Save
        </Button>
      )}
    </div>
  );
};

export default PromptTabContent;
