import React from "react";
import { Button } from "../../components/common/ui/button";
import { Icons } from "../../components/common/Icons";
import { useGoBack } from "../../hooks/useGoBack";
import { UserApiKeys } from '../../components/account/userApiKeys';

const UserApiKeysPage = () => {
  const goBack = useGoBack("/campaigns/");

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4 items-center">
        <Button
          variant="ghost"
          icon={Icons.back}
          onClick={goBack}
          className="p-3 h-auto"
        />
        <h1 className="text-slate-900 text-2xl font-semibold leading-normal">
          API Keys
        </h1>
      </div>

      <UserApiKeys />
    </div>
  );
};

export default UserApiKeysPage;
