import React, { FC, useMemo } from "react";
import { Spin, Table, TableProps, Tooltip } from "antd";
import { campaignBadgeSettings } from "../../../constants";
import { format } from "date-fns";
import { TCampaign } from "../../../store/slices/campaignsSlice";
import { CompanyLogo } from "../../companies/CompanyLogo";
import { Badge } from "../../common/ui/badge";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ArchiveIcon } from "lucide-react";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { SortingDirection } from "../../../types/docTemplates";
import { AllowedSortFields, CampaignsTableItem } from "../types";
import { SortOrder } from "antd/es/table/interface";
import { ItemMenu } from "./item-menu";
import { useSelector } from "react-redux";
import { selectUserPermissions } from "../../../store/slices/userData/slice";
import { getHasCampaignReadPerm } from "../utils";

type Props = {
  campaigns: TCampaign[];
  loading: boolean;
  setSortingParams: (sortBy?: string, orderBy?: SortingDirection) => void;
  sortByParam: AllowedSortFields | null;
  orderByParam: SortingDirection | null;
  reloadCampaignList: () => Promise<void>;
  isTypeColSortable?: boolean;
};
export const CampaignTable: FC<Props> = ({
  campaigns,
  loading,
  setSortingParams,
  sortByParam,
  orderByParam,
  reloadCampaignList,
  isTypeColSortable = false,
}) => {
  const userPermissions = useSelector(selectUserPermissions);
  const navigate = useNavigate();
  const { companyId } = useParams();
  const { hasCampaignReadRole, hasCampaignArchiveRole } = useUserHasPermission({
    companyId,
  });
  const dataSource: CampaignsTableItem[] = useMemo(() => {
    return campaigns.map((item) => ({ ...item, key: item.id }));
  }, [campaigns]);

  const validatedSortOrder: SortOrder =
    orderByParam === "asc"
      ? "ascend"
      : orderByParam === "desc"
        ? "descend"
        : null;

  const columns: TableProps<CampaignsTableItem>["columns"] = [
    {
      title: "Campaign",
      key: "name",
      dataIndex: "name",
      sorter: true,
      sortOrder: sortByParam === "name" ? validatedSortOrder : undefined,
      render: (_, { name, archive, id, companyId }) => (
        <>
          {hasCampaignReadRole ? (
            <NavLink
              className="flex items-center gap-2 font-medium text-[#0F172A] hover:text-[#0F172A] break-all"
              to={`/campaigns/company/${companyId}/campaign/${id}`}
              onClick={(e) => e.preventDefault()}
            >
              {hasCampaignArchiveRole && archive && (
                <Tooltip title="Campaign is archived">
                  <ArchiveIcon className="size-4 flex-shrink-0" />
                </Tooltip>
              )}
              {name}
            </NavLink>
          ) : (
            <div className="flex items-center gap-2 font-medium text-[#0F172A] break-all">
              {hasCampaignArchiveRole && archive && (
                <Tooltip title="Campaign is archived">
                  <ArchiveIcon className="size-4 flex-shrink-0" />
                </Tooltip>
              )}
              {name}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Company",
      key: "companyName",
      dataIndex: "companyName",
      sorter: true,
      sortOrder: sortByParam === "companyName" ? validatedSortOrder : undefined,
      width: 240,
      render: (_, { companyName, companyLogo }) => (
        <div className="flex items-center gap-2">
          <CompanyLogo url={companyLogo} />
          <span className="break-all">{companyName}</span>
        </div>
      ),
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      sorter: isTypeColSortable,
      sortOrder:
        isTypeColSortable && sortByParam === "type"
          ? validatedSortOrder
          : undefined,
      width: 110,
      render: (_, { type }) => (
        <Badge
          className={campaignBadgeSettings[type].className}
          variant="table"
        >
          {campaignBadgeSettings[type].label}
        </Badge>
      ),
    },
    {
      title: "Last Modified",
      key: "modifiedTime",
      dataIndex: "modifiedTime",
      sorter: true,
      sortOrder:
        sortByParam === "modifiedTime" ? validatedSortOrder : undefined,
      width: 180,
      render: (_, { modifiedTime }) => (
        <span className="text-[#0F172A] text-xs font-medium">
          {format(modifiedTime, "MMM d, yyyy, h:mm:ss a")}
        </span>
      ),
    },
    {
      key: "menu",
      width: 56,
      render: (_, campaign) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ItemMenu
            campaign={campaign}
            reloadCampaignList={reloadCampaignList}
          />
        </div>
      ),
    },
  ];

  const onEditCampaign = async ({
    companyId,
    campaignId,
  }: {
    companyId: number;
    campaignId: number;
  }) => {
    navigate(`/campaigns/company/${companyId}/campaign/${campaignId}`);
  };

  const handleTableChange: TableProps<CampaignsTableItem>["onChange"] = (
    _pagination: any,
    _filters: any,
    sorter,
  ) => {
    if (!Array.isArray(sorter)) {
      const { field, order } = sorter;
      const orderBy: SortingDirection | undefined =
        order === "ascend"
          ? SortingDirection.ASC
          : order === "descend"
            ? SortingDirection.DESC
            : undefined;
      const sortBy = (orderBy ? field : undefined) as string | undefined;

      setSortingParams(sortBy, orderBy);
    }
  };

  return (
    <Spin spinning={loading}>
      <Table<CampaignsTableItem>
        columns={columns}
        onRow={({ companyId, id }) => ({
          onClick: getHasCampaignReadPerm(userPermissions, companyId)
            ? () =>
                onEditCampaign({
                  companyId,
                  campaignId: id,
                })
            : undefined,
          className: "cursor-pointer hover:bg-muted/50 transition-colors",
        })}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: 780 }}
        className="redesignAntTable"
        onChange={handleTableChange}
      />
    </Spin>
  );
};
