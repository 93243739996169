import React, { FC, useEffect, useMemo, useState } from "react";
import {
  saveCcItemResultOvrThunk,
  TCcVariable,
} from "../../../store/slices/ccVariablesSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { selectMessageApi } from "../../../store/slices/appSlice";
import useManageCCItemOverride from "../../../hooks/useManageCCItemOverride";
import handleRequestError from "../../../utils/handleRequestError";
import { Button, Checkbox, Tooltip } from "antd";
import clsx from "clsx";
import { OnAfterCCItemUpdate } from "../types";
import { updateCcVarApi } from "../../../api/cc-variables.api";
import { LockOutlined } from "@ant-design/icons";
import { Icons } from "../../common/Icons";
import RichTextEditor from "../../common/RichTextEditor/RichTextEditor";
import { OverrideEditorMode } from "../constants";
import RichEditorModeSwitcher from "./RichEditorModeSwitcher";
import { removeOuterParagraphTags } from '../../../utils';

/*
  Override Dialog Behavior:

  1. No Override Present on Open:
     - Show the original text in "Override Version" text area as grayed-out placeholder.
     - Disable the "Save" button.
     - When user edits text, text turns dark and "Save" button becomes enabled.
     - On clicking "Save," the override is saved, and dialog closes.

  2. Override Already in Place on Open:
     - Show current override text as dark text in "Override Version" area.
     - Disable "Save" button initially.
     - When user edits text, enable the "Save" button.
     - On clicking "Save," update the override and close the dialog.

  3. Removing the Override:
     - Option 1: "Restore Original" button:
       - Replace override text with original text, set text to gray, and enable "Save" button to remove override.
     - Option 2: Clear all text in "Override Version" area and click "Save."
       - This action removes the override.
*/

/*
  Override Dialog Behavior:

  1. No Override Present on Open:
     - Show the original text in "Override Version" text area as grayed-out placeholder.
     - Disable the "Save" button.
     - When user edits text, text turns dark and "Save" button becomes enabled.
     - On clicking "Save," the override is saved, and dialog closes.

  2. Override Already in Place on Open:
     - Show current override text as dark text in "Override Version" area.
     - Disable "Save" button initially.
     - When user edits text, enable the "Save" button.
     - On clicking "Save," update the override and close the dialog.

  3. Removing the Override:
     - Option 1: "Restore Original" button:
       - Replace override text with original text, set text to gray, and enable "Save" button to remove override.
     - Option 2: Clear all text in "Override Version" area and click "Save."
       - This action removes the override.
*/

type Props = {
  ccItem: TCcVariable;
  onAfterCCItemUpdate: OnAfterCCItemUpdate;
  hasCampaignCcOverrideRole: boolean;
  hasCampaignCcUpdateRole: boolean;
};

const ContentTabContent: FC<Props> = ({
  ccItem,
  onAfterCCItemUpdate,
  hasCampaignCcOverrideRole,
  hasCampaignCcUpdateRole,
}) => {
  const { id, result, resultOvr, supportOverride, type, options } = ccItem;
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = useSelector(selectMessageApi);
  const [isFetching, setIsFetching] = useState(false);
  const [resultValue, setResultValue] = useState(ccItem.result || "");
  const richEditorDisabled = useMemo(() => {
    return (
      type === "json" ||
      type === "xml" ||
      (type === "string" && !options?.htmlContent)
    );
  }, [type, options]);
  const [editorMode, setEditorMode] = useState<OverrideEditorMode>(
    richEditorDisabled
      ? OverrideEditorMode.DEFAULT
      : OverrideEditorMode.RICH_EDITOR,
  );
  const {
    handleResetDependenciesChange,
    handleOverrideValueChange,
    setOverrideValue,
    shouldSaveOverride,
    isSaveButtonDisabled,
    handleRestoreOriginalClick,
    overrideValue,
    resetDependencies,
  } = useManageCCItemOverride({ result, resultOvr });

  useEffect(() => {
    setOverrideValue(ccItem.resultOvr || ccItem.result || "");
    setResultValue(ccItem.result || "");
  }, [ccItem]);

  const handleSaveOverride = async () => {
    try {
      setIsFetching(true);

      const value = removeOuterParagraphTags(shouldSaveOverride ? overrideValue : "");

      await dispatch(
        saveCcItemResultOvrThunk({
          varId: id,
          value,
          resetDependencies,
        }),
      ).unwrap();

      const updateCcItem: TCcVariable = { ...ccItem, resultOvr: value };

      await onAfterCCItemUpdate(updateCcItem);
      messageApi.success("Successfully saved");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const handleSaveResult = async () => {
    try {
      setIsFetching(true);
      const updateCcItem: TCcVariable = { ...ccItem, result: resultValue };

      await updateCcVarApi({
        ccItem: updateCcItem,
        values: {
          result: updateCcItem.result,
          type: updateCcItem.type,
          key: updateCcItem.id.key,
          modifiedTime: updateCcItem.modifiedTime,
          ...ccItem.options
        },
      });

      await onAfterCCItemUpdate(updateCcItem);
      messageApi.success("Successfully saved");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="h-full flex flex-col gap-4">
      <div className="flex-1 flex gap-8">
        <div className="flex-1 flex flex-col">
          <div className="h-[48px] px-4 flex shrink-0 justify-between items-center border border-b-0 bg-[#F8FAFC] rounded-t-lg">
            <div className="flex items-center gap-1 text-base font-medium text-[#0F172A]">
              <span>Result</span>

              {supportOverride && (
                <LockOutlined className="size-4 text-[#3C4E63]" />
              )}
            </div>

            {!supportOverride && (
              <RichEditorModeSwitcher
                editorMode={editorMode}
                setEditorMode={setEditorMode}
                richEditorDisabled={richEditorDisabled}
              />
            )}
          </div>

          {editorMode === OverrideEditorMode.RICH_EDITOR && !supportOverride ? (
            <div className="flex-1 flex flex-col rounded-b-lg border">
              <RichTextEditor
                content={resultValue}
                onValueChange={(value) => value && setResultValue(value)}
                disabled={!hasCampaignCcUpdateRole}
                className="flex-1 overflow-y-auto [&_.ProseMirror]:p-4 [&_.ProseMirror]:min-h-[100%] [&_.ProseMirror]:max-h-[350px] [&_.ProseMirror:focus]:outline-none"
              />
            </div>
          ) : (
            <textarea
              className="w-full h-full p-[14px] rounded-b-lg resize-none min-h-[80px] border text-sm border-input bg-background disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-none"
              onChange={(e) => setResultValue(e.target.value)}
              value={resultValue}
              disabled={!hasCampaignCcUpdateRole || supportOverride}
            />
          )}
        </div>

        {supportOverride && (
          <div className="flex-1 flex flex-col">
            <div className="relative overflow-hidden h-[48px] flex shrink-0 justify-between items-center gap-1 px-6 text-base font-medium border border-b-0 text-[#0F172A] bg-[#F8FAFC] rounded-t-lg">
              <span>Override</span>
              {shouldSaveOverride && (
                <Tooltip title="Override">
                  <div className="absolute top-[-5px] right-[-10px] w-[30px] h-[20px] bg-gradient-to-t from-white to-yellow-500 rotate-45" />
                </Tooltip>
              )}

              <RichEditorModeSwitcher
                editorMode={editorMode}
                setEditorMode={setEditorMode}
                richEditorDisabled={richEditorDisabled}
              />
            </div>

            {editorMode === OverrideEditorMode.RICH_EDITOR ? (
              <div className="flex-1 flex flex-col rounded-b-lg border">
                <RichTextEditor
                  content={overrideValue}
                  onValueChange={handleOverrideValueChange}
                  disabled={!hasCampaignCcOverrideRole}
                  className="flex-1 overflow-y-auto [&_.ProseMirror]:p-4 [&_.ProseMirror]:min-h-[100%] [&_.ProseMirror]:max-h-[350px] [&_.ProseMirror:focus]:outline-none"
                />
              </div>
            ) : (
              <textarea
                className={clsx(
                  "w-full h-full p-[14px] rounded-b-lg resize-none min-h-[80px] border text-sm border-input bg-background disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-none",
                  {
                    "text-black/40": overrideValue === result,
                  },
                )}
                onChange={(e) => handleOverrideValueChange(e.target.value)}
                value={overrideValue}
                disabled={!hasCampaignCcOverrideRole}
              />
            )}
          </div>
        )}
      </div>

      {!supportOverride && hasCampaignCcUpdateRole && (
        <Button
          size="large"
          type="primary"
          className="font-semibold text-sm !px-8 self-end"
          loading={isFetching}
          disabled={resultValue.length === 0 || resultValue === result}
          onClick={handleSaveResult}
          shape="round"
        >
          Save
        </Button>
      )}

      {supportOverride && hasCampaignCcOverrideRole && (
        <div className="flex justify-between items-start gap-2">
          <Button
            size="large"
            className="font-semibold text-[#475569] text-sm"
            onClick={handleRestoreOriginalClick}
            disabled={overrideValue === result}
            icon={<Icons.ResetArrowIcon />}
            shape="round"
          >
            Restore Original
          </Button>

          <div className="flex flex-col items-end gap-4">
            <Button
              size="large"
              type="primary"
              className="font-semibold text-sm !px-8"
              loading={isFetching}
              disabled={isSaveButtonDisabled}
              onClick={handleSaveOverride}
              shape="round"
            >
              Save
            </Button>
            <Checkbox
              onChange={(e) => handleResetDependenciesChange(e.target.checked)}
            >
              <span className="font-normal text-[#475569] text-sm">
                Reset downstream dependencies
              </span>
            </Checkbox>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentTabContent;
