import { FC } from "react";
import { useSelector } from "react-redux";
import { selectCurrentStep } from "src/store/slices/stepsSlice";

type Props = {};

const CampaignClasses: FC<Props> = () => {
  const step = useSelector(selectCurrentStep);

  if (!step) return null;

  const { classes } = step;

  return (
    <div className="flex font-sans gap-[8px] items-center justify-between">
      <div className="flex gap-[8px] items-center">
        <div className="text-[#475569] font-bold text-[14px]">Classes:</div>
        <div className="flex gap-[4px] text-[#0F172A]">
          {classes && classes.length ? (
            classes.map((stepClass, index) => (
              <span
                key={stepClass}
              >{`[${stepClass}]${index !== classes.length - 1 ? "," : ""}`}</span>
            ))
          ) : (
            <span>empty</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignClasses;
