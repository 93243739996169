import { FC, useState } from "react";
import { Button } from "../../common/ui/button";
import { TCcVariable } from "src/store/slices/ccVariablesSlice";
import { OverrideEditorMode } from "../../ccItemEditor/constants";
import { useBoolean } from "src/hooks/useBoolean";
import { RichEditorModeSwitcher } from "../../ccItemEditor";
import RichTextEditor from "../../common/RichTextEditor/RichTextEditor";
import { removeOuterParagraphTags } from "src/utils";

type ContentEditorProps = {
  ccVar: TCcVariable;
  content: string;
  value: string;
  onSave: (content: string) => Promise<void>;
  onCancel: (resetContent?: boolean) => void;
  setContent: (content: string) => void;
};

const ContentEditor: FC<ContentEditorProps> = ({
  content,
  value,
  onSave,
  onCancel,
  setContent,
}) => {
  const [editorMode, setEditorMode] = useState<OverrideEditorMode>(
    OverrideEditorMode.RICH_EDITOR,
  );
  const [loading, startLoading, stopLoading] = useBoolean(false);

  const handleSave = async () => {
    try {
      startLoading();
      const sanitizedContent = removeOuterParagraphTags(content);
      await onSave(sanitizedContent);
    } catch (error) {
      console.error("Error saving content:", error);
    } finally {
      stopLoading();
    }
  };

  return (
    <>
      <div className="flex items-center justify-end h-[38px] px-4 text-base font-medium border border-b-0 text-[#0F172A] bg-[#F8FAFC] rounded-t-lg">
        <RichEditorModeSwitcher
          itemClassName="size-7"
          iconClassName="!size-4"
          editorMode={editorMode}
          setEditorMode={setEditorMode}
          richEditorDisabled={false}
        />
      </div>

      <div className="flex-1 flex flex-col border border-indigo-600 rounded-b-lg">
        {editorMode === OverrideEditorMode.RICH_EDITOR && (
          <RichTextEditor
            autofocus="start"
            content={content}
            onValueChange={(value) => setContent(value || "")}
            className="flex-1 overflow-y-auto [&_.ProseMirror]:p-4 [&_.ProseMirror]:min-h-[100%] [&_.ProseMirror:focus]:outline-none"
          />
        )}

        {editorMode === OverrideEditorMode.DEFAULT && (
          <textarea
            rows={1}
            className="p-4 resize-none rounded-b-lg text-sm/5.5 bg-background disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200 placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-none"
            onChange={(e) => setContent(e.target.value)}
            value={content}
          />
        )}
      </div>

      <div className="flex gap-2 mt-2">
        <Button
          variant="primary"
          size="sm"
          className="h-7 rounded-full px-3 text-sm font-medium"
          onClick={handleSave}
          disabled={content === value}
          loading={loading}
        >
          Apply
        </Button>

        <Button
          variant="outline"
          size="sm"
          className="h-7 rounded-full px-3 text-sm font-medium"
          onClick={() => onCancel(true)}
          disabled={loading}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default ContentEditor;
