import { Tooltip } from "antd";
import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import { TCcVariable } from "../../../../store/slices/ccVariablesSlice";
import { OverrideEditorMode } from "../../../ccItemEditor/constants";
import ContentCollapsed from "./ContentCollapsed";
import ContentExpanded from "./ContentExpanded";

type Props = {
  value: string;
  ccItem: TCcVariable;
};

const GridItemResult: React.FC<Props> = ({ value, ccItem }) => {
  const { resultOvr, type, options } = ccItem;
  const { companyId } = useParams();
  const [animate, setAnimate] = useState(false);
  const [editDisabled, setEditDisabled] = useState(true);
  const [content, setContent] = useState(value);
  const { hasCampaignCcOverrideRole } = useUserHasPermission({ companyId });
  const isFirstRender = useRef(true);
  const richEditorDisabled = useMemo(() => {
    return (
      type === "json" ||
      type === "xml" ||
      (type === "string" && !options?.htmlContent)
    );
  }, [type, options]);
  const [editorMode, setEditorMode] = useState<OverrideEditorMode>(
    richEditorDisabled
      ? OverrideEditorMode.DEFAULT
      : OverrideEditorMode.RICH_EDITOR,
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // skip highlight animation on first render
    }

    setAnimate(true);
    setContent(value);

    const timeout = setTimeout(() => setAnimate(false), 2500);
    return () => clearTimeout(timeout);
  }, [value]);

  const handleCancelEditing = (resetContent?: boolean) => {
    setEditDisabled(true);
    setEditorMode(
      richEditorDisabled
        ? OverrideEditorMode.DEFAULT
        : OverrideEditorMode.RICH_EDITOR,
    );

    if (resetContent) {
      setContent(value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      handleCancelEditing(true);
    }
  };

  return (
    <div
      className={clsx("flex flex-col", {
        "animate-highlight-cc-updated": animate,
        "hover:border-indigo-600 border border-transparent transition-colors rounded-[6px]":
          editDisabled,
      })}
      onDoubleClick={
        hasCampaignCcOverrideRole ? () => setEditDisabled(false) : undefined
      }
      onKeyDown={handleKeyDown}
    >
      {resultOvr && (
        <Tooltip title="Override">
          <div
            className="absolute top-0 right-0 w-[20px] h-[20px] bg-gradient-to-bl from-yellow-500 to-white"
            style={{ clipPath: "polygon(100% 0, 0 0, 100% 100%)" }}
          />
        </Tooltip>
      )}

      {editDisabled && (
        <ContentCollapsed
          content={content}
          richEditorDisabled={richEditorDisabled}
        />
      )}

      {!editDisabled && (
        <ContentExpanded
          content={content}
          richEditorDisabled={richEditorDisabled}
          setContent={setContent}
          ccItem={ccItem}
          editorMode={editorMode}
          setEditorMode={setEditorMode}
          value={value}
          onCancelEditing={handleCancelEditing}
        />
      )}
    </div>
  );
};

export default GridItemResult;
