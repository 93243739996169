import {
  TUser,
  TUserCompanyPermissions,
  TUserCompanySecurityGroup,
  TUserForm,
  TUserGlobalPermissions,
  TUsersListItem,
} from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";
import { UserMyPermissions } from "../store/slices/userData/types";

export const userLoginWithGoogleApi = ({
  credential,
}: {
  credential: string;
}) => {
  return axiosInstance.get<TUser>(`/api/public/auth/login?g=${credential}`);
};

export const userLoginApi = () => {
  return axiosInstance.get<TUser>(`/api/secured/user/auth`);
};

export const userLogoutApi = () => {
  return axiosInstance.get<string>(`/api/public/auth/logout`);
};

export const getUsersListApi = ({
  pageNumber,
  pageSize,
  search,
}: {
  pageNumber: number;
  pageSize: number;
  search?: string;
}) => {
  return axiosInstance.get<{ items: TUsersListItem[]; totalItems: number }>(
    `/api/secured/user/list/`,
    {
      params: {
        pageNumber,
        pageSize,
        search,
      },
    },
  );
};

export const getUserDataApi = ({ userId }: { userId: number | string }) => {
  return axiosInstance.get<TUser>(`/api/secured/user/${userId}`);
};

export const getUserIntercomHmacApi = () => {
  return axiosInstance.get<string>("/api/secured/intercom/hmac");
};

export const deleteUserApi = (userId: number) => {
  return axiosInstance.delete<string>(`/api/secured/user/${userId}`);
};

export const createUserApi = ({ userData }: { userData: TUserForm }) => {
  return axiosInstance.post<TUser>(`/api/secured/user/`, userData);
};

export const createCompanyUserApi = ({
  userData,
  companyId,
  permissionGroupId,
}: {
  userData: TUserForm;
  companyId: string;
  permissionGroupId: number;
}) => {
  return axiosInstance.post<TUser>(
    `/api/secured/user/create-and-assign/${companyId}/${permissionGroupId}`,
    userData,
  );
};

export const deleteCompanyUserApi = ({
  userId,
  companyId,
}: {
  userId: number;
  companyId: number;
}) => {
  return axiosInstance.delete<string>(
    `/api/secured/user/${userId}/company/${companyId}`,
  );
};

export const enableCompanyUserApi = ({
  userId,
  companyId,
}: {
  userId: number;
  companyId: number;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/user/${userId}/company-enable/${companyId}`,
    {},
  );
};
export const disableCompanyUserApi = ({
  userId,
  companyId,
}: {
  userId: number;
  companyId: number;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/user/${userId}/company-disable/${companyId}`,
    {},
  );
};

export const enableUserApi = ({ userId }: { userId: number }) => {
  return axiosInstance.put<string>(`/api/secured/user/${userId}/enable`, {});
};
export const disableUserApi = ({ userId }: { userId: number }) => {
  return axiosInstance.put<string>(`/api/secured/user/${userId}/disable`, {});
};

export const updateUserApi = ({
  userData,
  userId,
}: {
  userData: TUserForm;
  userId: number | string;
}) => {
  return axiosInstance.put<TUser>(`/api/secured/user/${userId}`, userData);
};

export const getUserSessionsCountApi = ({
  userId,
}: {
  userId: number | string;
}) => {
  return axiosInstance.get<number>(
    `/api/secured/user/${userId}/sessions-count`,
  );
};

export const resetUserSessionsApi = ({
  userId,
}: {
  userId: number | string;
}) => {
  return axiosInstance.post<number>(
    `/api/secured/user/${userId}/logout-sessions`,
  );
};

export const getUserPermissionsApi = () => {
  return axiosInstance.get<UserMyPermissions>(
    `/api/secured/user/my-permissions`,
  );
};

export const getUserGlobalPermissionsApi = ({
  userId,
}: {
  userId: string | number;
}) => {
  return axiosInstance.get<TUserGlobalPermissions>(
    `/api/secured/user/${userId}/permissions-global/`,
  );
};

export const addSecurityGroupToUserApi = ({
  companyId,
  permissionsGroupId,
  userId,
}: {
  companyId: number | string | null;
  userId: number | string;
  permissionsGroupId: number;
}) => {
  return axiosInstance.post<string>(
    `/api/secured/user/${userId}/permissions-global/`,
    { companyId, permissionsGroupId },
  );
};

export const deleteSecurityGroupFromUserApi = ({
  permissionsGroupId,
  userId,
}: {
  userId: number | string;
  permissionsGroupId: number;
}) => {
  return axiosInstance.delete<string>(
    `/api/secured/user/${userId}/permissions-global/${permissionsGroupId}`,
  );
};

export const addCompanyPermissionGroupApi = ({
  permissionGroupId,
  companyId,
  userId,
}: {
  userId: number | string;
  companyId: number | string;
  permissionGroupId: number;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/user/${userId}/assign-company/${companyId}/${permissionGroupId}`,
    {},
  );
};

export const getCompanyPermissionGroupListApi = ({
  userId,
}: {
  userId: number | string;
}) => {
  return axiosInstance.get<TUserCompanySecurityGroup[]>(
    `/api/secured/user/${userId}/assigned-companies`,
  );
};

export const deleteCompanyPermissionGroupApi = ({
  userId,
  companyId,
}: {
  userId: number | string;
  companyId: number | string;
}) => {
  return axiosInstance.delete<TUserCompanySecurityGroup[]>(
    `/api/secured/user/${userId}/unassign-company/${companyId}`,
  );
};

export const getUserCompanyPermissionsApi = ({
  userId,
  companyId,
}: {
  userId: number | string;
  companyId: number | string;
}) => {
  return axiosInstance.get<TUserCompanyPermissions>(
    `/api/secured/user/${userId}/permissions-company/${companyId}/`,
  );
};

export const deleteUserCompanyPermissionGroupApi = ({
  userId,
  companyId,
  permissionGroupCompanyId,
}: {
  userId: number | string;
  companyId: number | string;
  permissionGroupCompanyId: number | string;
}) => {
  return axiosInstance.delete<string>(
    `/api/secured/user/${userId}/permissions-company/${companyId}/${permissionGroupCompanyId}`,
  );
};
