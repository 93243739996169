import {
  loadDynamicItemsThunk,
  TCcVariable,
  useCcDynamicFormItems,
} from "../../../store/slices/ccVariablesSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { Form } from "antd";
import { useEffect, useMemo, useState } from "react";
import { getCcVarsApi } from "../../../api/cc-variables.api";
import handleRequestError from "../../../utils/handleRequestError";
import { AppDispatch } from "../../../store/store";

const useGetModelData = (ccItem: TCcVariable) => {
  const { id, type } = ccItem;
  const dynamicFormItems = useCcDynamicFormItems();
  const messageApi = useSelector(selectMessageApi);
  const [form] = Form.useForm();
  const [stepCCVars, setStepCCVars] = useState<TCcVariable[]>([]);
  const [initialized, setInitialized] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    getCCStepVars();
  }, []);

  const getCCStepVars = async () => {
    try {
      const { data } = await getCcVarsApi({
        campaignId: id.campaignId,
        stepId: id.stepId,
      });

      await dispatch(loadDynamicItemsThunk()).unwrap();

      setStepCCVars(data);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setInitialized(true);
    }
  };

  const dynamicItems = useMemo(
    () =>
      dynamicFormItems[type]
        ? [...dynamicFormItems[type].items].sort((a, b) => a.order - b.order)
        : [],
    [dynamicFormItems],
  );

  return {
    dynamicItems,
    form,
    stepCCVars,
    initialized,
  };
};

export default useGetModelData;
