import "reactflow/dist/style.css";
import { Icons } from "../../components/common/Icons";
import { Button } from "../../components/common/ui/button";
import { useGoBack } from "../../hooks/useGoBack";
import { useParams } from "react-router-dom";
import { UpstreamDependencies } from "../../components/graph";
import useConnectSocket from "../../store/slices/computationMessages/hooks/useConnectSocket";

const UpstreamGraph = () => {
  const { companyId, campaignId } = useParams();

  useConnectSocket();

  const goBack = useGoBack(
    `/campaigns/company/${companyId}/campaign/${campaignId}/`,
  );

  return (
    <div className="relative w-full h-full">
      <div className="flex justify-between items-center">
        <div className="flex gap-[16px] items-center mb-[12px]">
          <Button
            variant={"ghost"}
            icon={Icons.back}
            onClick={goBack}
            className="p-[12px] h-auto"
          />
          <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal">
            Upstream Dependencies
          </h1>
        </div>
      </div>

      <UpstreamDependencies />
    </div>
  );
};

export default UpstreamGraph;
