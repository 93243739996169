import { useLocation, useSearchParams } from "react-router-dom";
import { CampaignListSearchParams, DEFAULT_PAGE_NUMBER } from "../constants";
import { useMemo } from "react";
import { ArchiveStatus, CampaignListQueryParams } from "../types";
import { SortingDirection } from "../../../types/docTemplates";
import {
  getValidatedArchiveParamValue,
  getValidatedCampaignTypeParamValue,
  getValidatedCompanyIdParamValue,
  getValidatedPageNumParamValue,
  getValidatedPageSizeParamValue,
  getValidatedSortOrderByParamValues,
} from "../utils";

export const useCampaignListQueryParams = () => {
  const isTemplatesPage = useLocation().pathname.startsWith("/templates");
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo<CampaignListQueryParams>(() => {
    const companyId = getValidatedCompanyIdParamValue(
      searchParams.get(CampaignListSearchParams.COMPANY_ID),
    );
    const archive = getValidatedArchiveParamValue(
      searchParams.get(CampaignListSearchParams.ARCHIVE),
    );
    const { sortBy, orderBy } = getValidatedSortOrderByParamValues(
      searchParams.get(CampaignListSearchParams.SORT_BY),
      searchParams.get(CampaignListSearchParams.ORDER_BY),
    );
    const page = getValidatedPageNumParamValue(
      searchParams.get(CampaignListSearchParams.PAGE_NUM),
    );
    const pageSize = getValidatedPageSizeParamValue(
      searchParams.get(CampaignListSearchParams.PAGE_SIZE),
    );
    const backUrl = searchParams.get(CampaignListSearchParams.BACK_URL);
    const type = getValidatedCampaignTypeParamValue(
      isTemplatesPage,
      searchParams.get(CampaignListSearchParams.TYPE),
    );

    return {
      companyId,
      page,
      pageSize,
      archive,
      sortBy,
      orderBy,
      backUrl,
      type,
    };
  }, [searchParams]);

  const setCompanyIdParam = (companyId: string | null) => {
    setSearchParams(
      (prev) => {
        if (!companyId) {
          prev.delete(CampaignListSearchParams.COMPANY_ID);
        } else {
          prev.set(CampaignListSearchParams.COMPANY_ID, companyId);
        }

        prev.set(
          CampaignListSearchParams.PAGE_NUM,
          DEFAULT_PAGE_NUMBER.toString(),
        );
        return prev;
      },
      { replace: true },
    );
  };

  const setArchivedParam = (value: ArchiveStatus) => {
    setSearchParams(
      (prev) => {
        if (value === "all") {
          prev.delete(CampaignListSearchParams.ARCHIVE);
        } else {
          prev.set(CampaignListSearchParams.ARCHIVE, value);
        }

        prev.set(
          CampaignListSearchParams.PAGE_NUM,
          DEFAULT_PAGE_NUMBER.toString(),
        );
        return prev;
      },
      { replace: true },
    );
  };

  const setPageSizeParam = (value: number) => {
    setSearchParams(
      (prev) => {
        prev.set(CampaignListSearchParams.PAGE_SIZE, value.toString());
        prev.set(
          CampaignListSearchParams.PAGE_NUM,
          DEFAULT_PAGE_NUMBER.toString(),
        );

        return prev;
      },
      { replace: true },
    );
  };

  const setPageNumParam = (value: number) => {
    setSearchParams(
      (prev) => {
        prev.set(CampaignListSearchParams.PAGE_NUM, value.toString());

        return prev;
      },
      { replace: true },
    );
  };

  const setSortingParams = (sortBy?: string, orderBy?: SortingDirection) => {
    setSearchParams(
      (prev) => {
        if (!sortBy) {
          prev.delete(CampaignListSearchParams.SORT_BY);
        } else {
          prev.set(CampaignListSearchParams.SORT_BY, sortBy);
        }

        if (!orderBy) {
          prev.delete(CampaignListSearchParams.ORDER_BY);
        } else {
          prev.set(CampaignListSearchParams.ORDER_BY, orderBy);
        }

        return prev;
      },
      { replace: true },
    );
  };

  const setCampaignTypeParam = (type: string) => {
    setSearchParams(
      (prev) => {
        prev.set(CampaignListSearchParams.TYPE, type);

        return prev;
      },
      { replace: true },
    );
  };

  return {
    params,
    setCompanyIdParam,
    setArchivedParam,
    setPageSizeParam,
    setPageNumParam,
    setSortingParams,
    setCampaignTypeParam,
  };
};
