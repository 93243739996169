import { Spin } from "antd";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { getIsFetching } from "../store/slices/appSlice";
import CompanyStorageRoutes from "./protectedRoutes/CompanyStorageRoutes";
import CampaignRoutes from "./protectedRoutes/CampaignRoutes";
import CampaignStorageRoutes from "./protectedRoutes/CampaignStorageRoutes";
import SecurityRoutes from "./protectedRoutes/SecurityRoutes";
import ModelConfigsRoutes from "./protectedRoutes/ModelConfigsRoutes";
import TemplatesRoutes from "./protectedRoutes/TemplatesRoutes";
import GraphRoutes from "./protectedRoutes/GraphRoutes";
import CampaignLabelsRoutes from "./protectedRoutes/CampaignLabelsRoutes";
import GlobalLabelsRoutes from "./protectedRoutes/GlobalLabelsRoutes";
import GlobalStorageRoutes from "./protectedRoutes/GlobalStorageRoutes";
import UsersRoutes from "./protectedRoutes/UsersRoutes";
import CompaniesRoutes from "./protectedRoutes/CompaniesRoutes";
import NotFoundPage from "../pages/NotFoundPage";
import HomePage from "../pages/HomePage";
import useInitializeIntercomChat from "../hooks/useInitializeIntercomChat";
import { CampaignChatPage } from "src/pages/campaign-chat";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { cn } from "src/utils";
import AccountRoutes from './protectedRoutes/AccountRoutes';

const HIDDEN_LAYOUT_PATHS = ["/chat"];

const getIsHiddenLayoutPath = (pathname: string) => {
  return HIDDEN_LAYOUT_PATHS.some((path) => pathname.includes(path));
};

const MainRoutes: React.FC = () => {
  const isFetching = getIsFetching();
  const location = useLocation();

  useInitializeIntercomChat();

  const currentPath = location.pathname;

  const isLayoutHidden = getIsHiddenLayoutPath(currentPath);

  return (
    <div
      className={cn(
        !isLayoutHidden && "h-[calc(100vh-4px)] flex flex-col justify-between",
      )}
    >
      <div>
        <Header />
        <div className={!isLayoutHidden ? "w-full py-[24px] px-[48px]" : ""}>
          <Spin spinning={isFetching}>
            <Routes location={location}>
              {/* Companies */}
              <Route path="companies/*" element={<CompaniesRoutes />} />

              {/* Users */}
              <Route path="users/*" element={<UsersRoutes />} />

              {/* Graph */}
              <Route path="graph/*" element={<GraphRoutes />} />

              {/* Security */}
              <Route path="security-groups/*" element={<SecurityRoutes />} />

              {/* Global Labels */}
              <Route
                path="campaigns/globalLabels/*"
                element={<GlobalLabelsRoutes />}
              />

              {/* Global Storage */}
              <Route
                path="campaigns/storage/*"
                element={<GlobalStorageRoutes />}
              />

              {/* Company Storage */}
              <Route
                path="campaigns/company/:companyId/storage/*"
                element={<CompanyStorageRoutes />}
              />

              {/* CAMPAIGN STORAGE */}
              <Route
                path="campaigns/company/:companyId/campaign/:campaignId/storage/*"
                element={<CampaignStorageRoutes />}
              />

              <Route
                path="campaigns/company/:companyId/chat/:chatId"
                element={<CampaignChatPage />}
              />

              {/* CAMPAIGN */}
              <Route path="campaigns/*" element={<CampaignRoutes />} />

              {/* Campaign Labels */}
              <Route
                path="campaigns/company/:companyId/campaign/:campaignId/labels/*"
                element={<CampaignLabelsRoutes />}
              />

              {/* Templates */}
              <Route path="templates/*" element={<TemplatesRoutes />} />

              {/* AI Models Config */}
              <Route path="model-configs/*" element={<ModelConfigsRoutes />} />

              <Route path="/account/*" element={<AccountRoutes />} />

              <Route path="/home" element={<HomePage />} />
              <Route path="/sign-in" element={<Navigate to="/home" />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Spin>
        </div>
      </div>
      {!isLayoutHidden && <Footer />}
    </div>
  );
};

export default MainRoutes;
