import { TMovePhaseStepType, TPhase } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";

export const getPhasesApi = ({
  campaignId,
}: {
  campaignId: number | string;
}) => {
  return axiosInstance.get<TPhase[]>(`/api/secured/${campaignId}/phase/`);
};

export const createPhaseApi = ({
  campaignId,
  microSiteContextFolder,
  hidden,
  name,
  addType,
  targetPhaseId,
}: {
  campaignId: number;
  name: string;
  hidden: boolean;
  microSiteContextFolder: string | null;
  addType: TMovePhaseStepType;
  targetPhaseId?: number;
}) => {
  return axiosInstance.post<TPhase>(
    `/api/secured/${campaignId}/phase/`,
    { name, microSiteContextFolder, hidden },
    {
      params: {
        addType,
        targetPhaseId,
      },
    },
  );
};

export const updatePhaseApi = ({
  phase,
  rebuild,
}: {
  phase: TPhase;
  rebuild?: boolean;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/${phase.campaignId}/phase/`,
    phase,
    {
      params: {
        rebuild,
      },
    },
  );
};

export const deletePhaseApi = ({
  campaignId,
  phaseId,
  removeFiles,
}: {
  campaignId: number;
  phaseId: number;
  removeFiles: boolean;
}) => {
  return axiosInstance.delete<string>(
    `/api/secured/${campaignId}/phase/${phaseId}`,
    {
      params: {
        removeFiles,
      },
    },
  );
};

export const exportCSVPhaseApi = ({
  campaignId,
  phaseId,
}: {
  campaignId: number;
  phaseId: number;
}) => {
  return axiosInstance.get<string>(
    `/api/secured/${campaignId}/phase/${phaseId}/export-csv`,
  );
};

export const clonePhaseApi = ({
  phaseId,
  resetOvr,
  resetResult,
  name,
  campaignId,
}: {
  campaignId: number;
  phaseId: number;
  name: string;
  resetResult: boolean;
  resetOvr: boolean;
}) => {
  return axiosInstance.put<TPhase>(
    `/api/secured/${campaignId}/phase/${phaseId}/clone`,
    { name },
    {
      params: {
        resetResult,
        resetOvr,
      },
    },
  );
};

export const publishPhaseToMicroSiteApi = ({
  phaseId,
  campaignId,
}: {
  phaseId: number;
  campaignId: number;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/${campaignId}/phase/${phaseId}/microsite-deploy`,
    {},
  );
};

export const copyPhaseToApi = ({
  phaseId,
  campaignId,
  targetCampaignId,
  name,
  newSeq,
  resetResult,
  resetOvr,
}: {
  phaseId: number;
  campaignId: number;
  targetCampaignId: number;
  name: string;
  newSeq: number;
  resetResult: boolean;
  resetOvr: boolean;
}) => {
  return axiosInstance.put<TPhase>(
    `/api/secured/${campaignId}/phase/${phaseId}/copy`,
    {},
    {
      params: {
        resetResult,
        resetOvr,
        name,
        newSeq,
        targetCampaignId,
      },
    },
  );
};

export const movePhaseApi = ({
  moveType,
  targetPhaseId,
  campaignId,
  phaseId,
}: {
  targetPhaseId: number;
  campaignId: number;
  phaseId: number;
  moveType: TMovePhaseStepType;
}) => {
  return axiosInstance.put<TPhase[]>(
    `/api/secured/${campaignId}/phase/move/${phaseId}/`,
    {},
    {
      params: {
        targetPhaseId,
        moveType,
      },
    },
  );
};
