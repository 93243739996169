import { ArchiveOption, CampaignTypeOption } from "../types";

export enum CampaignListSearchParams {
  PAGE_NUM = "page",
  PAGE_SIZE = "pageSize",
  COMPANY_ID = "companyId",
  SORT_BY = "sortBy",
  ORDER_BY = "orderBy",
  ARCHIVE = "archive",
  BACK_URL = "backUrl",
  TYPE = "type",
}

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 50;

export const ALL_COMPANIES_OPTION_VALUE = "ALL";

export const CAMPAIGN_TYPE_OPTIONS: CampaignTypeOption[] = [
  { value: "DRAFT_TEMPLATE,PUBLISHED_TEMPLATE", label: "All Templates" },
  { value: "DRAFT_TEMPLATE", label: "Draft Templates" },
  { value: "PUBLISHED_TEMPLATE", label: "Published Templates" },
];

export const CAMPAIGN_ARCHIVE_OPTIONS: ArchiveOption[] = [
  {
    label: "All Statuses",
    value: "all",
  },
  {
    label: "Active",
    value: "false",
  },
  {
    label: "Archived",
    value: "true",
  },
];
