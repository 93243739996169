import React, { FC, useEffect, useRef, useState } from "react";
import { selectMessageApi } from "../../store/slices/appSlice";
import {
  selectComputationInfo,
  selectEventMessages,
} from "../../store/slices/computationMessages/slice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { stopExecuteCampaignApi } from "../../api/campaigns.api";
import handleRequestError from "../../utils/handleRequestError";
import { Empty, Modal, Segmented, Table } from "antd";
import ComputationProgress from "./ComputationProgress";
import { Button } from "../common/ui/button";
import { TComputationState } from "../../store/slices/ccVariablesSlice";
import Column from "antd/es/table/Column";
import {
  getClassNameForTypeCol,
  getFilteredMessages,
  getPathParts,
  getStateConfig,
} from "./utils";
import { useDispatch, useSelector } from "react-redux";
import {
  TComputationMessage,
  TComputationStatus,
  TSocketMsgType,
} from "../../store/slices/computationMessages/types";
import localStorageProvider from "../../utils/localStorageProvider";
import { FilterOption } from "./constants";
import { isValidInteger } from "../../utils";
import { CampaignViewTypes } from "../../types";
import { getStepsApi } from "../../api/steps.api";
import { setCurrentStep, setSteps } from "../../store/slices/stepsSlice";
import {
  selectPhasesList,
  setCurrentPhase,
} from "../../store/slices/phasesSlice";
import { AppDispatch } from "../../store/store";

type Props = {
  onCloseAndClear: () => void;
  setIsListOfMessagesOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isListOfMessagesOpened: boolean;
  campaignId: number;
};

const EventsLogModal: FC<Props> = ({
  onCloseAndClear,
  setIsListOfMessagesOpened,
  isListOfMessagesOpened,
  campaignId,
}) => {
  const computationInfo = useSelector(selectComputationInfo);
  const messages = useSelector(selectEventMessages);
  const phases = useSelector(selectPhasesList);
  const messageApi = useSelector(selectMessageApi);

  const dispatch = useDispatch<AppDispatch>();
  const { companyId } = useParams();
  const tableRef = useRef<any>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [filterValue, setFilterValue] = useState<FilterOption>(
    localStorageProvider.getEventLogFilter() || FilterOption.ALL,
  );
  const [filteredMessages, setFilteredMessages] = useState<
    TComputationMessage[]
  >([]);
  const { hasCampaignComputationStopRole } = useUserHasPermission({
    companyId,
  });
  const filterOptions = Object.values(FilterOption);

  useEffect(() => {
    const filtered = getFilteredMessages({
      filter: filterValue,
      messages,
    });

    setFilteredMessages(filtered);
  }, [messages]);

  const onStopCampaignExecution = async () => {
    try {
      setIsLoading(true);
      await stopExecuteCampaignApi({ campaignId });

      messageApi.success(
        "Your request to stop the execution of the campaign has been sent",
      );
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const onFilterChange = (filter: FilterOption) => {
    const filtered = getFilteredMessages({
      filter,
      messages,
    });

    if (tableRef.current && tableRef.current.scrollTo) {
      tableRef.current.scrollTo({ top: 0 });
    }

    localStorageProvider.setEventLogFilter(filter);
    setFilteredMessages(filtered);
    setFilterValue(filter);
  };

  const onGoToKey = async ({
    phaseId,
    stepId,
    navigatePath,
  }: {
    phaseId: number;
    stepId: number;
    navigatePath: string;
  }) => {
    if (!navigatePath) return;
    // When we are in the grid, we need to load steps and set currentStep/currentPhase in redux before the redirect.
    // TODO The bottleneck - we need to refactor the approach of loading phases and steps and use url parameters instead of redux state

    try {
      if (
        pathname.startsWith("/templates") ||
        pathname.startsWith("/campaigns")
      ) {
        const currentPhase = phases.find((phase) => phase.id === phaseId);

        if (!currentPhase) {
          messageApi.error("Failed to navigate to the link.");
          return;
        } else {
          const { data: stepsData } = await getStepsApi({
            phaseId,
            campaignId,
          });

          const currentStep = stepsData.find((step) => step.id === stepId);

          dispatch(setSteps(stepsData));
          dispatch(setCurrentStep(currentStep || null));
          dispatch(setCurrentPhase(currentPhase));
        }
      }

      navigate(navigatePath);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  return (
    <Modal
      title="Events log"
      open={isListOfMessagesOpened}
      onCancel={() => setIsListOfMessagesOpened(false)}
      width={1150}
      centered
      footer={
        <div className="flex justify-between items-end flex-wrap">
          <ComputationProgress />
          <div className="flex gap-[6px]">
            {computationInfo?.status !== "finished" &&
              computationInfo?.status !== "interrupted" &&
              hasCampaignComputationStopRole && (
                <Button
                  size="sm"
                  loading={isLoading}
                  onClick={onStopCampaignExecution}
                  className="rounded-full"
                  variant="outline"
                >
                  Stop Execution
                </Button>
              )}

            <Button
              size="sm"
              onClick={onCloseAndClear}
              className="rounded-full"
              variant="primaryOutline"
            >
              Clear and Close
            </Button>
          </div>
        </div>
      }
    >
      <div className="h-[550px]">
        {messages.length ? (
          <div className="flex flex-col gap-2">
            <Segmented
              className="self-start"
              size="small"
              onChange={onFilterChange}
              value={filterValue}
              options={filterOptions}
            />
            <Table
              size="small"
              ref={tableRef}
              dataSource={filteredMessages}
              pagination={false}
              scroll={{ x: 1100, y: 480 }}
              virtual
            >
              <Column
                title="Type"
                dataIndex="type"
                key="type"
                width={200}
                render={(type: TSocketMsgType) => {
                  const className = getClassNameForTypeCol(type);

                  return <div className={className}>{type}</div>;
                }}
              />
              <Column
                title="Time"
                dataIndex="localTime"
                key="localTime"
                width={200}
              />
              <Column
                title="Item"
                dataIndex="path"
                key="key"
                render={(
                  value: string | null,
                  {
                    errorMessage,
                    computationTime,
                    message,
                    phaseId,
                    stepId,
                    ccItemKey,
                  }: TComputationMessage,
                ) => {
                  const { firstPart, secondPart } = getPathParts(value);
                  let pathToStep = "";

                  if (
                    [companyId, campaignId, phaseId, stepId].every(
                      isValidInteger,
                    )
                  ) {
                    const rootSubPath = pathname.startsWith("/templates")
                      ? "templates"
                      : "campaigns";

                    pathToStep = `/${rootSubPath}/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}?view=${CampaignViewTypes.GRID}`;

                    if (ccItemKey) {
                      pathToStep += `#${ccItemKey}`;
                    }
                  }

                  return (
                    <div className="flex flex-col">
                      <div className="flex items-center gap-[12px]">
                        <div>
                          <span className="text-slate-300">{firstPart}/</span>
                          {pathToStep && phaseId && stepId ? (
                            <span
                              className="font-semibold text-veSecondary cursor-pointer hover:text-vePrimary transition-colors"
                              onClick={async () => {
                                setIsListOfMessagesOpened(false);

                                await onGoToKey({
                                  phaseId,
                                  stepId,
                                  navigatePath: pathToStep,
                                });
                              }}
                            >
                              {secondPart}
                            </span>
                          ) : (
                            <span className="font-semibold text-[#475569]">
                              {secondPart}
                            </span>
                          )}
                        </div>

                        {computationTime !== undefined && (
                          <span className="text-blue-800 text-[12px]">
                            {`(${computationTime}ms)`}
                          </span>
                        )}
                      </div>

                      {message && (
                        <div className="text-blue-800 text-[12px]">
                          {message}
                        </div>
                      )}

                      {errorMessage && (
                        <div className="text-red-800 text-[12px]">
                          {errorMessage}
                        </div>
                      )}
                    </div>
                  );
                }}
              />
              <Column
                title="Status"
                dataIndex="status"
                key="status"
                width={80}
                align="center"
                render={(state: TComputationState | TComputationStatus) => {
                  const { icon, className } = getStateConfig(state);

                  return (
                    <div className={`flex justify-center ${className}`}>
                      {icon}
                    </div>
                  );
                }}
              />
            </Table>
          </div>
        ) : (
          <Empty
            imageStyle={{ height: "400px" }}
            className="font-sans font-semibold text-[14px]"
            description="There are no computation events"
          />
        )}
      </div>
    </Modal>
  );
};

export default EventsLogModal;
