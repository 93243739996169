import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { UserPermissions } from "../../../store/slices/userData/constants";
import { HeaderNavItem } from "../types";
import { useLocation } from "react-router-dom";
import { getCampaignListRedirectUrl } from "../../campaignsList";

export const useGetNavigationData = () => {
  const { pathname } = useLocation();
  const {
    hasAiConfigurationListRole,
    hasPermGroupListRole,
    hasPermissionAnywhere,
  } = useUserHasPermission({});
  const hasCompanyListAnywhereRole = hasPermissionAnywhere(
    UserPermissions.ROLE_COMPANY_LIST,
  );
  const hasUserListAnywhereRole = hasPermissionAnywhere(
    UserPermissions.ROLE_USER_LIST,
  );
  const hasCampaignListAnywhereRole = hasPermissionAnywhere(
    UserPermissions.ROLE_CAMPAIGN_LIST,
  );
  const hasDocTemplatesListAnywhereRole = hasPermissionAnywhere(
    UserPermissions.ROLE_DOCUMENT_TEMPLATE_LIST,
  );
  const hasCampaignTemplatesListAnywhereRole = hasPermissionAnywhere(
    UserPermissions.ROLE_CAMPAIGNTEMPLATES_LIST,
  );
  const campaignsURL = getCampaignListRedirectUrl();

  const navDataList = [
    hasCompanyListAnywhereRole && {
      label: "Companies",
      active: pathname.startsWith("/companies"),
      path: "/companies",
    },
    hasUserListAnywhereRole && {
      label: "Users",
      active: pathname.startsWith("/users"),
      path: "/users",
    },
    hasCampaignListAnywhereRole && {
      label: "Campaigns",
      active: pathname.startsWith("/campaigns"),
      path: campaignsURL,
    },
    (hasDocTemplatesListAnywhereRole ||
      hasCampaignTemplatesListAnywhereRole) && {
      label: "Templates",
      active: pathname.startsWith("/templates/"),
      path: hasDocTemplatesListAnywhereRole
        ? "/templates/documents"
        : "/templates/campaigns",
    },
    hasAiConfigurationListRole && {
      label: "Model Configs",
      active: pathname.startsWith("/model-configs"),
      path: "/model-configs",
    },
    hasPermGroupListRole && {
      label: "Security",
      active: pathname.startsWith("/security-groups"),
      path: "/security-groups",
    },
  ].filter(Boolean) as HeaderNavItem[];

  const activeNavKeys = navDataList
    .filter((item) => item.active)
    .map((item) => item.path);

  return {
    navDataList,
    activeNavKeys,
  };
};
