import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DownstreamDepsIcon,
  Icons,
  SolidEditIcon,
  UpstreamDepsIcon,
} from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { Button } from "src/components/common/ui/button";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { ChatInfo } from "../types";

type Props = {
  variable: TCcVariable;
  chatInfo: ChatInfo;
  onOpenEditor: (variable: TCcVariable) => void;
};

const GridItemActions: FC<Props> = ({ variable, chatInfo, onOpenEditor }) => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { hasCampaignCcUpdateRole, hasCampaignCcGraphRole } =
    useUserHasPermission({ companyId });

  const items = [
    hasCampaignCcUpdateRole && {
      key: "edit",
      label: "Edit",
      onClick: () => onOpenEditor(variable),
      icon: SolidEditIcon,
    },
    hasCampaignCcGraphRole &&
      variable.type === "prompt" && {
        key: "showUpstream",
        label: "Upstream Dependencies",
        icon: UpstreamDepsIcon,
        onClick: () =>
          navigate(
            `/graph/${companyId}/${chatInfo.chat.campaignId}/${chatInfo.phaseId}/${chatInfo.chat.stepId}/${variable.id.key}`,
          ),
      },
    hasCampaignCcGraphRole && {
      key: "showDownstream",
      label: "Downstream\u00A0Dependencies",
      icon: DownstreamDepsIcon,
      onClick: () =>
        navigate(
          `/graph/downstream/${companyId}/${chatInfo.chat.campaignId}/${chatInfo.phaseId}/${chatInfo.chat.stepId}/${variable.id.key}`,
        ),
    },
  ].filter((v) => !!v) as MenuDropdownItem[];

  return (
    <>
      <MenuDropdown items={items} align="end">
        <Button
          variant={"ghostPrimary"}
          className="p-[4px] size-[28px]"
          onClick={(e) => e.stopPropagation()}
        >
          <Icons.MoreDots className="size-4" />
        </Button>
      </MenuDropdown>
    </>
  );
};

export default GridItemActions;
