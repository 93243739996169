import React, { FC } from "react";
import { Button, Popover, Select, Spin } from "antd";
import useGetCompanySecurityGroups from "./useGetCompanySecurityGroups.hook";
import { UpdateUserCompanySecurityGroupList } from "../../../types/securityGroups";

type Props = {
  updateGroupsList: UpdateUserCompanySecurityGroupList;
  assignedCompanies: number[];
};
const AddCompanySecurityGroup: FC<Props> = ({
  updateGroupsList,
  assignedCompanies,
}) => {
  const {
    selectedGroupId,
    setSelectedGroupId,
    handleOpenChange,
    open,
    companyOptions,
    loadCompanies,
    selectedCompanyId,
    setSelectedCompanyId,
    globalGroupsOptions,
    onAddCompanyGroup,
    loading,
  } = useGetCompanySecurityGroups({ updateGroupsList, assignedCompanies });

  return (
    <div>
      <Popover
        placement="topRight"
        content={
          <div className="flex flex-col gap-[12px] w-[355px]">
            <div className="flex flex-col gap-[12px]">
              <div className="flex flex-col gap-[8px]">
                <span className="font-bold text-[14px] text-[#475569]">
                  Company
                </span>
                <Spin spinning={loading === "COMPANIES"}>
                  <Select
                    showSearch
                    options={companyOptions}
                    size="middle"
                    optionFilterProp="label"
                    autoFocus
                    placeholder="Select the company to add"
                    onChange={(value: number) => setSelectedCompanyId(value)}
                    value={selectedCompanyId}
                    className="w-[300px]"
                  />
                </Spin>
              </div>
              <div className="flex flex-col gap-[8px]">
                <span className="font-bold text-[14px] text-[#475569]">
                  Security Groups
                </span>
                <Spin spinning={loading === "GROUPS"}>
                  <Select
                    options={globalGroupsOptions}
                    size="middle"
                    placeholder={
                      selectedCompanyId === null
                        ? "First, choose a company"
                        : "Select the group to add"
                    }
                    onChange={(value: number) => setSelectedGroupId(value)}
                    value={selectedGroupId}
                    disabled={selectedCompanyId === null}
                    className="w-[300px]"
                  />
                </Spin>
              </div>

              <Button
                className="self-end ml-[12px]"
                size="small"
                type="primary"
                loading={loading === "SUBMIT"}
                disabled={!selectedGroupId || !selectedCompanyId}
                onClick={onAddCompanyGroup}
              >
                Save
              </Button>
            </div>
          </div>
        }
        trigger="click"
        open={open}
        destroyTooltipOnHide
        onOpenChange={handleOpenChange}
      />

      <Button
        type="primary"
        size="small"
        onClick={loadCompanies}
      >
        Add Company Permissions
      </Button>
    </div>
  );
};

export default AddCompanySecurityGroup;
