import { AssetPublishState } from "../../../globalTypes";
import { AssetStateMenuItem } from "../types";
import { UserPermissions } from "../../../store/slices/userData/constants";

export enum AssetStates {
  READY_FOR_REVIEW = "Ready For Review",
  ASSET_APPROVED = "Asset Approved",
  PUBLICATION_APPROVED = "Publication Approved",
  PUBLISHED = "Published",
  UN_PUBLISHED = "Unpublished",
}

export const STATE_OPTIONS_CONFIG: Record<
  AssetPublishState,
  { className: string; title: string }
> = {
  CREATED: {
    className: "bg-[#E2E8F0] text-[#3C4E63]",
    title: "Created",
  },
  PUBLISHED: {
    className: "bg-[#5B21B6] text-[#fff]",
    title: AssetStates.PUBLISHED,
  },
  READY_FOR_REVIEW: {
    className: "bg-[#1E40AF] text-[#fff]",
    title: AssetStates.READY_FOR_REVIEW,
  },
  ASSET_APPROVED: {
    className: "bg-[#4D7C0F] text-[#fff]",
    title: AssetStates.ASSET_APPROVED,
  },
  PUBLICATION_APPROVED: {
    className: "bg-[#047857] text-[#fff]",
    title: AssetStates.PUBLICATION_APPROVED,
  },
  ERROR: {
    className: "bg-red-500 text-[#fff]",
    title: "Error",
  },
};

export const VISIBLE_ASSET_STATE_ITEMS_MAP: Record<
  AssetPublishState,
  AssetStates[]
> = {
  CREATED: [AssetStates.READY_FOR_REVIEW, AssetStates.ASSET_APPROVED],
  READY_FOR_REVIEW: [AssetStates.ASSET_APPROVED],
  ASSET_APPROVED: [
    AssetStates.READY_FOR_REVIEW,
    AssetStates.PUBLICATION_APPROVED,
  ],
  PUBLICATION_APPROVED: [AssetStates.READY_FOR_REVIEW, AssetStates.PUBLISHED],
  PUBLISHED: [AssetStates.UN_PUBLISHED],
  ERROR: [AssetStates.READY_FOR_REVIEW, AssetStates.ASSET_APPROVED],
};

export const TITLE_ICON_PROPS = { className: "size-4" };

export const ASSET_STATE_MENU_ITEMS: AssetStateMenuItem[] = [
  {
    title: AssetStates.READY_FOR_REVIEW,
    key: AssetStates.READY_FOR_REVIEW,
    state: "READY_FOR_REVIEW",
    className: "text-[#1E40AF] bg-[#DBEAFE]",
    permission: UserPermissions.ROLE_ASSET_UPDATE,
  },
  {
    title: AssetStates.ASSET_APPROVED,
    key: AssetStates.ASSET_APPROVED,
    state: "ASSET_APPROVED",
    className: "text-[#3F6212] bg-[#ECFCCB]",
    permission: UserPermissions.ROLE_ASSET_APPROVE,
  },
  {
    title: AssetStates.PUBLICATION_APPROVED,
    key: AssetStates.PUBLICATION_APPROVED,
    state: "PUBLICATION_APPROVED",
    className: "text-[#065F46] bg-[#D1FAE5]",
    permission: UserPermissions.ROLE_ASSET_APPROVE_PUB,
  },
  {
    title: AssetStates.PUBLISHED,
    key: AssetStates.PUBLISHED,
    state: "PUBLISHED",
    className: "text-[#5B21B6] bg-[#EDE9FE]",
    permission: UserPermissions.ROLE_ASSET_DEPLOY,
  },
  {
    title: AssetStates.UN_PUBLISHED,
    key: AssetStates.UN_PUBLISHED,
    state: "PUBLICATION_APPROVED",
    className: "text-[#DC2626] bg-[#FEE2E2]",
    permission: UserPermissions.ROLE_ASSET_UPDATE,
  },
];
