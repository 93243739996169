import { Spin, TreeSelect } from "antd";
import React, { useEffect, useState } from "react";
import { getCcVarsApi } from "../../api/cc-variables.api";
import { getPhasesApi } from "../../api/phases.api";
import { getStepsApi } from "../../api/steps.api";
import { getMessageApi } from "../../store/slices/appSlice";
import { getCurrentPhase } from "../../store/slices/phasesSlice";
import { getCurrentStep } from "../../store/slices/stepsSlice";
import handleRequestError from "../../utils/handleRequestError";
import { useParams } from "react-router-dom";

type TreeDataItem = {
  title: React.ReactNode;
  value: string;
  children?: TreeDataItem[];
  isLeaf?: boolean;
  selectable: boolean;
  disabled?: boolean;
  //if stepId - it is step item
  stepId?: number;
  //if phaseId - it is phase item
  phaseId?: number;
};

type Props = {
  gridItemSequence: number;
  onSelect?: (value: string, option?: any) => void;
};

const SelectCCKey: React.FC<Props> = ({ gridItemSequence, onSelect }) => {
  const { campaignId } = useParams();
  const [treeData, setTreeData] = useState<TreeDataItem[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const currentPhase = getCurrentPhase();
  const currentStep = getCurrentStep();
  const messageApi = getMessageApi();

  useEffect(() => {
    if (!campaignId) {
      messageApi.error("Unable to get [campaignId] from URL");
      return;
    }

    if (!currentPhase) {
      messageApi.error("Unable to get [currentPhase]");
      return;
    }

    fetchTreeData({
      campaignId,
      currentPhaseSeq: currentPhase.seq,
    });
  }, [campaignId, currentPhase]);

  if (!currentPhase || !currentStep) {
    messageApi.error("Error: No [currentPhase] or [currentStep]");
    return null;
  }

  const handleExpandCollapse = (key: string) => {
    setExpandedKeys((prevExpandedKeys) =>
      prevExpandedKeys.includes(key)
        ? prevExpandedKeys.filter((k) => k !== key)
        : [...prevExpandedKeys, key],
    );
  };

  const fetchTreeData = async ({
    campaignId,
    currentPhaseSeq,
  }: {
    campaignId: string;
    currentPhaseSeq: number;
  }) => {
    try {
      setIsFetching(true);
      const { data: phasesData } = await getPhasesApi({ campaignId });

      const filteredPhases = phasesData.filter(
        (phase) => phase.seq <= currentPhaseSeq,
      );

      const formattedData: TreeDataItem[] = filteredPhases.map(
        ({ name, id }) => {
          return {
            title: (
              <div
                className="font-bold cursor-pointer"
                onClick={() => handleExpandCollapse(name)}
              >
                {name}
              </div>
            ),
            value: name,
            isLeaf: false,
            selectable: false,
            phaseId: id,
          };
        },
      );

      setTreeData(formattedData);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onLoadData = async ({
    value,
    phaseId,
    stepId,
    children,
  }: TreeDataItem) => {
    if (!campaignId) {
      messageApi.error("Unable to get [campaignId] from URL");
      return;
    }

    const [phaseName, stepName] = value.split("/");

    //skip loading if there is already loaded children
    if (!children) {
      try {
        let childrenData: TreeDataItem[] = [];

        //if phaseId - load steps
        if (phaseId !== undefined) {
          const { data: stepsData } = await getStepsApi({
            phaseId,
            campaignId: +campaignId,
          });

          //if the steps from the current phase are loaded, save only those steps that are before the current step
          const filteredSteps =
            currentPhase.id === phaseId
              ? stepsData.filter((step) => step.seq <= currentStep.seq)
              : stepsData;

          childrenData = filteredSteps.length
            ? filteredSteps.map(({ name, id }) => {
                const stepValue = `${phaseName}/${name}`;

                return {
                  title: (
                    <div
                      className="font-semibold cursor-pointer"
                      onClick={() => handleExpandCollapse(stepValue)}
                    >
                      {name}
                    </div>
                  ),
                  value: stepValue,
                  isLeaf: false,
                  selectable: false,
                  stepId: id,
                };
              })
            : [
                {
                  title: (
                    <span className="font-semibold">
                      There are no steps in phase
                    </span>
                  ),
                  value: `${phaseName}/no-steps`,
                  isLeaf: true,
                  selectable: false,
                  disabled: true,
                },
              ];
          //if stepId - load keys
        } else if (stepId !== undefined) {
          if (!campaignId) {
            messageApi.error("Unable to get [campaignId] from URL");
            return;
          }

          const { data: keysData } = await getCcVarsApi({ campaignId, stepId });

          //if the keys from the current step are loaded, save only those keys that are before the current key
          const filteredKeys =
            currentStep.id === stepId
              ? keysData.filter((key) => key.seq < gridItemSequence)
              : keysData;

          childrenData = filteredKeys.length
            ? filteredKeys.map((key) => ({
                title: `${key.id.key}`,
                value: `${phaseName}/${stepName}/${key.id.key}`,
                isLeaf: true,
                selectable: true,
              }))
            : [
                {
                  title: "There are no keys in step",
                  value: `${phaseName}/${stepName}/no-keys`,
                  isLeaf: true,
                  selectable: false,
                  disabled: true,
                },
              ];
        }

        const updatedTreeData = updateTreeData(treeData, value, childrenData);

        setTreeData(updatedTreeData);
      } catch (e: any) {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);
      }
    }
  };

  const updateTreeData = (
    treeData: TreeDataItem[],
    nodeValue: string,
    children: TreeDataItem[],
  ): TreeDataItem[] => {
    return treeData.map((node) => {
      if (node.value === nodeValue) {
        return { ...node, children, isLeaf: children.length === 0 };
      } else if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, nodeValue, children),
        };
      } else {
        return node;
      }
    });
  };

  return (
    <Spin spinning={isFetching}>
      <TreeSelect
        showSearch
        treeData={treeData}
        //@ts-ignore
        loadData={onLoadData}
        placeholder="Select a Key"
        onChange={onSelect}
        treeExpandedKeys={expandedKeys}
        onTreeExpand={(keys) => setExpandedKeys(keys as string[])}
      />
    </Spin>
  );
};

export default SelectCCKey;
