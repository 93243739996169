import GoogleAuth from "src/components/login/GoogleAuth";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Link } from "react-router-dom";
import { getCurrentEnv } from "src";
import { Env } from "src/types";

const LoginPage = () => {
  const isProd = getCurrentEnv() === Env.PROD;
  const currentYear = new Date().getFullYear();

  return (
    <div className="w-full h-screen px-[24px] pt-[16px] relative bg-loginBg overflow-hidden flex flex-col !font-instrument">
      <div className="w-full flex-grow bg-white rounded-3xl flex justify-center items-center">
        <div className="flex-col justify-start items-center gap-12">
          <div className="flex-col justify-start items-center flex gap-[48px]">
            <Link to={!isProd ? "/" : "https://www.velocityengine.co/"}>
              <img src={"/logo.svg"} alt="logo" className="h-[48px]" />
            </Link>
            <div className="flex-col justify-start items-center flex gap-[16px]">
              <div className="text-center">
                <span className="text-loginTitle text-[40px] font-semibold leading-[48px]">
                  Welcome to{" "}
                </span>
                <span className="text-[40px] font-semibold leading-[48px] bg-gradient-to-r from-veSecondary from-[48.35%] to-vePrimary to-[80.27%] bg-clip-text text-transparent">
                  VelocityEngine
                </span>
              </div>
              <span className="  text-center text-loginText text-2xl font-semibold leading-normal">
                To get started, please sign in
              </span>
            </div>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || ""}
            >
              <GoogleAuth />
            </GoogleOAuthProvider>
            <div className="justify-center items-center gap-2 flex">
              <div className="text-loginText text-base">
                Don&apos;t have an account yet?
              </div>
              <Link
                className="text-veSecondary text-base font-medium underline leading-normal decoration-solid underline-offset-auto decoration-auto decoration-skip-ink underline-position-from-font"
                to="mailto:support@velocityengine.co?subject=Login Help"
                rel="noreferrer nofollower"
                target="_blank"
              >
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex py-[40px] justify-between items-center text-loginText text-base px-[104px]">
        <span>© {currentYear} VelocityEngine. All Rights Reserved.</span>
        <span>
          <Link
            className="text-loginText text-base font-normal underline leading-normal decoration-solid underline-offset-auto decoration-auto decoration-skip-ink underline-position-from-font"
            to="https://www.velocityengine.co/privacy"
            rel="noreferrer nofollower"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </span>
      </div>
    </div>
  );
};

export default LoginPage;
