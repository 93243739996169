import { Modal } from "antd";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  editCampaignThunk,
  TCampaign,
} from "../../../store/slices/campaignsSlice";
import { AppDispatch } from "../../../store/store";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import useSaveOnBlurWithEscape from "../../../hooks/useSaveOnBlurWithEscape";
import { selectPhasesList } from "src/store/slices/phasesSlice";
import Spin from "antd/lib/spin";

type Props = {
  currentCampaign: TCampaign;
  companyId: number;
};

const EditCampaignName: FC<Props> = ({ currentCampaign, companyId }) => {
  const messageApi = getMessageApi();
  const dispatch: AppDispatch = useDispatch();
  const [validationErr, setValidationErr] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { hasCampaignUpdateRole } = useUserHasPermission({ companyId });
  const phases = useSelector(selectPhasesList);
  const campaignName = currentCampaign.name;
  const { value, setValue, handleBlur, handleKeyDown } = useSaveOnBlurWithEscape({
    initialValue: campaignName,
    onSave: () => onSaveName(),
    onCancel: () => setValidationErr(""),
  });

  const onSaveName = async () => {
    if (validationErr) {
      resetEditingState();
      return;
    }

    phases.length ? setIsModalOpen(true) : saveName();
  };

  const onNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    const trimmed = newName ? newName.trim() : "";
    let err = "";

    if (trimmed.length < 4) {
      err = "The name must not be less than 4 characters!";
    }

    if (trimmed.length > 255) {
      err = "The name must contain no more than 255 characters!";
    }

    if (/[\\/]/.test(trimmed)) {
      err = "The name must not contain slashes!";
    }

    setValidationErr(err);
    setValue(newName);
  };

  const resetEditingState = () => {
    setValidationErr("");
    setValue(campaignName);
  };

  const saveName = async () => {
    setIsLoading(true);

    try {
      const newCampaignData: TCampaign = {
        ...currentCampaign,
        name: value.trim().replace(/ +/g, " "),
      };

      await dispatch(
        editCampaignThunk({
          campaign: newCampaignData,
          rebuild: true,
        }),
      ).unwrap();

      messageApi.success("The campaign has been successfully renamed");
    } catch (e: any) {
      messageApi.error(e?.message);
      resetEditingState();
      console.error(e);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const onCancelModal = () => {
    resetEditingState();
    setIsModalOpen(false);
  };

  return (
    <div className="w-full relative">
      {!!validationErr && (
        <span className="absolute left-0 top-[-20px] text-[10px] text-red-600">
          {`${validationErr} The result will not be saved.`}
        </span>
      )}

      <Spin spinning={isLoading && !isModalOpen}>
        <input
          className="w-full min-w-[100px] px-[8px] py-[4px] text-slate-900 text-xl font-semibold font-sans leading-tight rounded-lg border border-transparent hover:border-gray-500 transition-all"
          value={value}
          type="text"
          autoComplete="off"
          readOnly={!hasCampaignUpdateRole}
          style={{ pointerEvents: hasCampaignUpdateRole ? "auto" : "none" }}
          tabIndex={hasCampaignUpdateRole ? 0 : -1}
          onChange={onNameInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
      </Spin>
      <Modal
        title="Confirm campaign name change"
        okText="Confirm"
        width="416px"
        open={isModalOpen}
        closable={false}
        maskClosable={false}
        keyboard={!isLoading}
        confirmLoading={isLoading}
        onOk={saveName}
        onCancel={onCancelModal}
        cancelButtonProps={{ disabled: isLoading }}
      >
        <p>Campaign name has changed, do you want to republish resources?</p>
      </Modal>
    </div>
  );
};

export default EditCampaignName;
