import { TAsset } from "../../../globalTypes";
import { getAssetApi } from "../../../api/assets.api";
import handleRequestError from "../../../utils/handleRequestError";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";

type Props = {
  assetId: number;
  closeModal: () => void;
};
export const useGetAsset = ({ assetId, closeModal }: Props) => {
  const { campaignId } = useParams();
  const [loading, setLoading] = useState(false);
  const [asset, setAsset] = useState<TAsset | null>(null);
  const messageApi = useSelector(selectMessageApi);

  useEffect(() => {
    onEditAsset();
  }, [assetId]);

  const onEditAsset = async () => {
    if (!campaignId) {
      console.error("Unable to get campaignId");
      return;
    }

    try {
      setLoading(true);

      const { data } = await getAssetApi({
        campaignId,
        assetId,
      });

      setAsset(data);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);

      closeModal();
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    asset,
  };
};
