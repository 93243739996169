import { useEffect, useState } from "react";
import { ApiVersionData } from "../../../globalTypes";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import axios from "axios";
import handleRequestError from "../../../utils/handleRequestError";

export const useGetApiData = () => {
  const [apiData, setApiData] = useState<ApiVersionData | null>(null);
  const [initialized, setInitialized] = useState(false);
  const messageApi = useSelector(selectMessageApi);

  useEffect(() => {
    getReleaseData();
  }, []);

  const getReleaseData = async () => {
    try {
      const { data } = await axios.get<ApiVersionData>(`/api/public/version`);

      setApiData(data);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setInitialized(true);
    }
  };

  return {
    apiData,
    initialized,
  };
};
