import axiosInstance from "../../../utils/axios/axiosInstance";
import { CreateApiKeyRes, UserApiKey } from './types';

export const getApiKeysApi = () => {
  return axiosInstance.get<UserApiKey[]>("/api/secured/user/api-key/list");
};

export const createApiKeyApi = () => {
  return axiosInstance.post<CreateApiKeyRes>("/api/secured/user/api-key/");
};

export const deleteApiKeyApi = ({ apiKey }: { apiKey: string }) => {
  return axiosInstance.delete<void>("/api/secured/user/api-key/", {
    data: apiKey,
  });
};
