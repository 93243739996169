import clsx from "clsx";
import { FC } from "react";

type Props = {
  Icon: FC;
  onClick: () => void;
  isActive: boolean;
};

const FormatButton: FC<Props> = ({ Icon, onClick, isActive }) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "size-6 flex items-center justify-center text-[#fff] font-semibold rounded-md transition-colors",
        {
          "bg-[#f4f4f5] !text-[#3C4E63]": isActive,
          "hover:bg-[#E2E0F8] hover:text-[#3C4E63]": !isActive,
        },
      )}
    >
      <Icon />
    </button>
  );
};

export default FormatButton;
