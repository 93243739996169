import {
  Button,
  Form,
  Input,
  Modal,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { importCampaignApi } from "../../../api/campaigns.api";
import { TImportCampaignFormValues } from "../../../globalTypes";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { selectMessageApi } from "../../../store/slices/appSlice";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import customizeFormLabels from "../../../utils/customizeFormLabels";
import { UploadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { required } from "../../../utils/validations";

type Props = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  companyId: number;
};

export const ImportCampaignModal: FC<Props> = ({
  setIsModalOpen,
  isModalOpen,
  companyId,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const messageApi = useSelector(selectMessageApi);
  const [form] = Form.useForm<TImportCampaignFormValues>();
  const navigate = useNavigate();

  useSubmitFormOnEnter(() => submitForm());

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "application/zip",
    fileList,
    onChange(info) {
      setFileList(info.fileList);
    },
    onRemove(_) {
      //need timeout to reset form, research how to improve
      setTimeout(() => {
        form.resetFields(["file"]);
        setFileList([]);
      }, 0);
    },
    beforeUpload(file) {
      form.setFieldValue("file", file);
      return false;
    },
  };
  const onCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onImport = async (values: TImportCampaignFormValues) => {
    try {
      setIsFetching(true);

      const { file, companyId } = values;
      const formData = new FormData();

      formData.append("file", file.file);

      const { data: newCampaign } = await importCampaignApi({
        formData,
        companyId,
      });

      navigate(
        `/campaigns/company/${newCampaign.companyId}/campaign/${newCampaign.id}`,
      );

      messageApi.success("Successfully imported");
    } catch (e: any) {
      messageApi.error(e.response.data.message);
      console.error("Can't import campaign", e);
    } finally {
      setIsModalOpen(false);
      setIsFetching(false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TImportCampaignFormValues) => {
      await onImport(values);
    },
  });

  return (
    <Modal
      title="Import Campaign"
      open={isModalOpen}
      okText="Import"
      cancelText="Cancel"
      confirmLoading={isFetching}
      onOk={submitForm}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: isFetching }}
      maskClosable={!isFetching}
      closable={!isFetching}
      keyboard={!isFetching}
    >
      <Form
        form={form}
        layout="vertical"
        name="campaign_import_form"
        requiredMark={customizeFormLabels}
        disabled={isFetching}
      >
        <Form.Item
          name="file"
          tooltip={
            <span>
              Supported formats: <strong>.zip</strong>.<br />
              To export a campaign in .zip format, go to the campaign and select{" "}
              <strong>Actions &gt; Export Campaign</strong>
            </span>
          }
          label="File"
          rules={[required()]}
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item name="companyId" noStyle initialValue={companyId}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
