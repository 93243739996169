import { useSearchParams } from "react-router-dom";
import {
  EDIT_CAMPAIGN_ID_SEARCH_PARAM,
  EDIT_KEY_SEARCH_PARAM,
  EDIT_STEP_ID_SEARCH_PARAM,
} from "../../constants";

const useDownstreamViewQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const editKey = searchParams.get(EDIT_KEY_SEARCH_PARAM);
  const editCampaignId = searchParams.get(EDIT_CAMPAIGN_ID_SEARCH_PARAM);
  const editStepId = searchParams.get(EDIT_STEP_ID_SEARCH_PARAM);

  const params = {
    editKey,
    editCampaignId,
    editStepId,
  };

  const clearParams = () => {
    setSearchParams(
      (prev) => {
        prev.delete(EDIT_CAMPAIGN_ID_SEARCH_PARAM);
        prev.delete(EDIT_STEP_ID_SEARCH_PARAM);
        prev.delete(EDIT_KEY_SEARCH_PARAM);
        return prev;
      },
      { replace: true },
    );
  };

  const setEditCcItemParams = ({
    campaignId,
    key,
    stepId,
  }: {
    campaignId: string;
    stepId: string;
    key: string;
  }) => {
    setSearchParams(
      (prev) => {
        prev.set(EDIT_CAMPAIGN_ID_SEARCH_PARAM, campaignId);
        prev.set(EDIT_STEP_ID_SEARCH_PARAM, stepId);
        prev.set(EDIT_KEY_SEARCH_PARAM, key);
        return prev;
      },
      { replace: true },
    );
  };

  return {
    params,
    clearParams,
    setEditCcItemParams,
  };
};

export default useDownstreamViewQueryParams;
