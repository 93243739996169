import React, { FC, memo, useEffect } from "react";
import { EditorContent, FocusPosition, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { TextAlign } from "@tiptap/extension-text-align";
import EditorSelectionMenu from "./EditorSelectionMenu";
import EditorNewLineFloatingMenu from "./EditorNewLineFloatingMenu";

const extensions = [
  StarterKit,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
];

type Props = {
  content: string;
  onValueChange: (value: string | undefined) => void;
  disabled?: boolean;
  className?: string;
  autofocus?: FocusPosition;
};

const RichTextEditor: FC<Props> = ({
  content,
  onValueChange,
  disabled = false,
  autofocus = "end",
  className,
}) => {
  const editor = useEditor({
    extensions,
    content,
    editable: !disabled,
    parseOptions: {
      preserveWhitespace: "full",
    },
    //This option gives us the control to enable the default behavior of rendering the editor immediately
    immediatelyRender: true,
    //This option gives us the control to disable the default behavior of re-rendering the editor on every transaction.
    shouldRerenderOnTransaction: false,
    onUpdate({ editor }) {
      onValueChange(editor.getHTML());
    },
  });

  // update content in editor when content from props changed
  useEffect(() => {
    if (editor && editor.getHTML() !== content) {
      editor.commands.setContent(content, false, {
        preserveWhitespace: "full",
      });
    }
  }, [content, editor]);

  useEffect(() => {
    if (editor) {
      editor.setOptions({ editable: !disabled, autofocus });
    }
  }, [disabled, editor]);

  return (
    <>
      <EditorSelectionMenu editor={editor} />
      <EditorNewLineFloatingMenu editor={editor} />
      <EditorContent editor={editor} className={className} />
    </>
  );
};

export default memo(RichTextEditor);
