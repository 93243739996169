import { useLocation, useNavigate } from "react-router-dom";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

type Props = {
  isFetching: boolean;
};

const useScrollToKey = ({ isFetching }: Props) => {
  const { hash, pathname, search } = useLocation();
  const tableRef = useRef<any>(null);
  const [highlightedRowKey, setHighlightedRowKey] = useState<string>("");
  const timeoutRef: MutableRefObject<any> = useRef(null);
  const navigate = useNavigate();

  const clearHash = useCallback(() => {
    navigate(pathname + search, {
      replace: true,
    });
  }, [pathname, search, navigate]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (!isFetching && hash) {
      const key = hash.replace("#", "");

      if (tableRef.current?.scrollTo) {
        tableRef.current.scrollTo({ key });
        setHighlightedRowKey(key);
      }

      timeoutRef.current = setTimeout(() => {
        setHighlightedRowKey("");
        clearHash();
      }, 3000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [isFetching, hash, clearHash]);

  return { tableRef, highlightedRowKey };
};

export default useScrollToKey;
