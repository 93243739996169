import { Flex, Form, Input, InputRef, Modal, Select } from "antd";
import React, { FC, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCcVarApi,
  executeCcVarApi,
  validateCcVarApi,
} from "src/api/cc-variables.api";
import { CC_VAR_SEQUENCE_STEP } from "src/utils/campaigns.constant";
import { getLastSequence } from "src/utils/cm.utils";
import { required, validateKey } from "src/utils/validations";
import useCCVarData from "../../../hooks/useCCVarData";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  loadCcVarsThunk,
  TCcVariable,
  TCcVarsFormValues,
} from "../../../store/slices/ccVariablesSlice";
import { AppDispatch } from "../../../store/store";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import TextAreaWithAltEnter from "../../common/TextAreaWithAltEnter";
import { Button } from "../../common/ui/button";
import DynamicCCForm from "../../dynamicForms/DynamicCCForm";
import { selectCurrentCompany } from "../../../store/slices/companiesSlice";
import { TStorageFileItem } from "../../../globalTypes";
import { getCompanyPublicFilesListApi } from "../../../api/company-storage.api";
import SelectStorageItems from "../../common/SelectStorageItems/SelectStorageItems";
import { resetComputationMessages } from "../../../store/slices/computationMessages/slice";
import { useParams } from "react-router-dom";
import customizeFormLabels from "../../../utils/customizeFormLabels";
import useSetFocus from "../../../hooks/useSetFocus";

type Props = {
  onClose: () => void;
  ccVariables: TCcVariable[];
  createKey: string;
  onAfterCreate: () => void | Promise<void>;
};

const AddCcVarForm: FC<Props> = ({
  onClose,
  ccVariables,
  createKey,
  onAfterCreate,
}) => {
  const { ccVarTypes, form, type, resultType, dynamicItems } =
    useCCVarData(null);
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const { campaignId, phaseId, stepId } = useParams();
  const inputTagRef = useRef<InputRef>(null);
  const [isFetching, setIsFetching] = useState(false);
  const gridItemSequence = getLastSequence(ccVariables, CC_VAR_SEQUENCE_STEP);
  const currentCompany = useSelector(selectCurrentCompany);
  const varKeysLowerCase = useMemo(
    () => ccVariables.map((item) => item.id.key.toLowerCase()),
    [ccVariables],
  );
  const contextPathPrefix = `/${currentCompany?.name}/`;

  useSubmitFormOnEnter(() => submitForm(), { condition: !isFetching });
  useSetFocus(inputTagRef);

  if (!campaignId || !phaseId || !stepId) {
    messageApi.error("Failed to load the required data.");
    return null;
  }

  const onCreateCCItem = async (values: TCcVarsFormValues) => {
    try {
      setIsFetching(true);

      await createCcVarApi({
        campaignId,
        values,
        stepId,
        seq: gridItemSequence,
      });
      await dispatch(loadCcVarsThunk({ campaignId, stepId })).unwrap();

      onClose();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onExecute = async (key: string) => {
    try {
      const { data: isExecuting } = await validateCcVarApi({
        campaignId,
      });

      if (!isExecuting) {
        dispatch(resetComputationMessages());

        await executeCcVarApi({
          campaignId,
          key,
          stepId,
        });

        messageApi.success("Execution started successfully");
      } else {
        messageApi.error(
          "There are some active computation elements in progress, please wait before execute",
        );
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const submitForm = (execute: boolean = false) =>
    submitFormWithTrim({
      form,
      onSuccessValidationCb: async (values: TCcVarsFormValues) => {
        const { postProcessingParams, key } = values;

        if (postProcessingParams) {
          values.postProcessingParams = JSON.stringify(postProcessingParams);
        }

        await onCreateCCItem(values);

        if (execute) {
          await onExecute(key);
        }

        await onAfterCreate();
      },
    })();

  const getFolderFiles = async (path?: string): Promise<TStorageFileItem[]> => {
    const { data } = await getCompanyPublicFilesListApi({
      path: path || contextPathPrefix,
    });
    return data;
  };

  const insertToFormField = (data: string) => {
    form.setFieldValue("result", data);
  };

  return (
    <Modal
      title="Add Item"
      open={true}
      onCancel={onClose}
      destroyOnClose={true}
      centered={true}
      width={"80vw"}
      footer={() => {
        return (
          <div className="flex justify-end items-center gap-[12px]">
            <div className="flex gap-[12px]">
              <Button
                onClick={onClose}
                variant="primaryOutline"
                className="rounded-full self-start"
                size="sm"
              >
                Cancel
              </Button>
              <Button
                onClick={() => submitForm()}
                className="rounded-full self-start px-5"
                loading={isFetching}
                disabled={isFetching}
                size="sm"
              >
                Save
              </Button>
              <Button
                onClick={() => submitForm(true)}
                className="rounded-full self-start px-5"
                loading={isFetching}
                disabled={isFetching}
                size="sm"
              >
                Save and Compute
              </Button>
            </div>
          </div>
        );
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="cc_variable_form"
        style={{ height: "80vh", overflowY: "scroll" }}
        requiredMark={customizeFormLabels}
      >
        <Flex gap="middle" align="center">
          <Form.Item name="type" label="Type" rules={[required(true)]}>
            <Select
              placeholder="Select type"
              style={{ width: 140 }}
              options={ccVarTypes.map((type) => ({
                value: type,
                label: type,
              }))}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            hidden={!type}
            name="key"
            label="Key"
            initialValue={createKey}
            tooltip="Enter a valid JavaScript identifier. It should start with a letter, underscore (_), or dollar sign ($), and can include letters, numbers, underscores, and dollar signs."
            rules={[
              {
                required: true,
                validator: validateKey(varKeysLowerCase),
              },
            ]}
          >
            <Input ref={inputTagRef} placeholder="Enter unique key name" />
          </Form.Item>
        </Flex>
        <Form.Item hidden={!type} name="result" label="Result">
          <TextAreaWithAltEnter
            placeholder="Enter result"
            currentForm={form}
            fieldName="result"
            allowClear
            rows={5}
            disabled={resultType === "read_only"}
          />
        </Form.Item>
        {type === "string" && (
          <div className="flex flex-col gap-[8px] mt-[16px]">
            <div className="font-sans font-bold text-[#475569] text-[14px]">
              Select file from storage:
            </div>
            <SelectStorageItems
              selectType="FILE"
              pathPrefix={contextPathPrefix}
              getFolderFiles={getFolderFiles}
              onSelect={insertToFormField}
            />
          </div>
        )}
        <DynamicCCForm
          hidden={!type}
          form={form}
          items={dynamicItems}
          ccItem={null}
          gridItemSequence={gridItemSequence}
          campaignId={+campaignId}
          stepId={+stepId}
          phaseId={+phaseId}
          stepCCVars={ccVariables}
        />
      </Form>
    </Modal>
  );
};

export default AddCcVarForm;
