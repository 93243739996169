import { Modal } from "antd";
import { FC, useState } from "react";
import { dictionaryApi } from "src/api/dictionary.api";
import { updateTemplateClassesApi } from "src/api/document-templates.api";
import { Label } from "src/components/common/ui/label";
import useSubmitFormOnEnter from "src/hooks/useSubmitFormOnEnter";
import SearchableMultiSelect from "../SearchableMultiSelect";
import handleRequestError from "../../../utils/handleRequestError";
import { getMessageApi } from "../../../store/slices/appSlice";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  templateId: number;
  defaultClasses: string[];
  onUpdated?: (newClasses: string[]) => void;
};

export const EditTemplateClassesModal: FC<Props> = ({
  open,
  setOpen,
  templateId,
  defaultClasses = [],
  onUpdated,
}) => {
  const [classes, setClasses] = useState<string[]>(defaultClasses || []);
  const messageApi = getMessageApi();

  const fetchClasses = async (word: string) => {
    try {
      const { data } = await dictionaryApi({
        dictionaryType: "CLASS",
      }).find({
        wordMatch: `*${word}*`,
      });

      return data.map((item) => ({ label: item, value: item }));
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);

      return [];
    }
  };

  const handleSelectClasses = (classes: string[]) => {
    setClasses(classes);
  };

  const handleUpdate = async () => {
    await updateTemplateClassesApi({ templateId, classes });
    onUpdated?.(classes);
    setOpen(false);
  };

  useSubmitFormOnEnter(() => handleUpdate());

  return (
    <Modal
      title="Edit Classes"
      open={open}
      onOk={handleUpdate}
      okText="Change"
      onCancel={() => setOpen(false)}
      className="w-[700px]"
      maskClosable={false}
    >
      <div className="font-sans flex flex-col gap-3 mt-3">
        <Label>Select Classes</Label>
        <SearchableMultiSelect
          fetchOptions={fetchClasses}
          onSelect={handleSelectClasses}
          value={classes}
        />
      </div>
    </Modal>
  );
};
