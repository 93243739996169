import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import handleRequestError from "../../../../utils/handleRequestError";
import { getApiKeysApi } from "../api";
import { UserApiKey } from "../types";

const useGetUserApiKeys = () => {
  const [initialized, setInitialized] = useState(false);
  const [apiKeys, setApiKeys] = useState<UserApiKey[]>([]);
  const messageApi = useSelector(selectMessageApi);

  useEffect(() => {
    loadApiKeys().finally(() => {
      setInitialized(true);
    });
  }, []);

  const loadApiKeys = async () => {
    try {
      const { data } = await getApiKeysApi();

      setApiKeys(data);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  return {
    apiKeys,
    initialized,
    loadApiKeys,
  };
};

export default useGetUserApiKeys;
