import { FC, ReactNode } from "react";
import { KeyContext, Selection } from "../types";
import { cn } from "src/utils";

interface HighlightedTextProps {
  text: string;
  selections?: Selection[];
  contexts?: KeyContext;
  className?: string;
}

export const HighlightedText: FC<HighlightedTextProps> = ({
  text,
  selections = [],
  contexts = { text: "", selections: [] },
  className,
}) => {
  // Combine selections from direct prop and from contexts
  const allSelections = [...(contexts.selections || []), ...selections];

  if (allSelections.length === 0) {
    return <span className={className} data-original-text={text} data-start-index={0} data-end-index={text.length}>{text}</span>;
  }

  // Sort selections by start index
  const sortedSelections = [...allSelections].sort((a, b) => a.start - b.start);

  // Validate selections to prevent overlaps and out-of-bounds indexes
  const validSelections = sortedSelections.filter(
    (selection, index) =>
      // Check if selection is within text bounds
      selection.start >= 0 &&
      selection.end <= text.length &&
      selection.end > selection.start &&
      // Check if this selection doesn't overlap with the next one
      (index === sortedSelections.length - 1 ||
        selection.end <= sortedSelections[index + 1].start),
  );

  // Generate text segments with highlights
  const segments: ReactNode[] = [];
  let lastEnd = 0;

  validSelections.forEach((selection, index) => {
    // Add non-highlighted text before this selection
    if (selection.start > lastEnd) {
      segments.push(
        <span
          key={`text-${index}`}
          data-original-text={text.substring(lastEnd, selection.start)}
          data-start-index={lastEnd}
          data-end-index={selection.start}
        >
          {text.substring(lastEnd, selection.start)}
        </span>,
      );
    }

    // Add highlighted text
    segments.push(
      <span
        key={`highlight-${index}`}
        className="bg-[#EDE9FE] text-indigo-900 rounded px-0.5 font-medium"
        data-original-text={text.substring(selection.start, selection.end)}
        data-start-index={selection.start}
        data-end-index={selection.end}
        data-highlighted="true"
      >
        {text.substring(selection.start, selection.end)}
      </span>,
    );

    lastEnd = selection.end;
  });

  // Add any remaining text after the last selection
  if (lastEnd < text.length) {
    segments.push(
      <span
        key={`text-last`}
        data-original-text={text.substring(lastEnd, text.length)}
        data-start-index={lastEnd}
        data-end-index={text.length}
      >
        {text.substring(lastEnd, text.length)}
      </span>,
    );
  }

  return (
    <span className={cn("whitespace-pre-line", className)} data-original-complete-text={text}>{segments}</span>
  );
};
