import { Table } from "antd";
import React, { FC, useMemo, useState } from "react";
import { Icons } from "src/components/common/Icons";
import { Button } from "src/components/common/ui/button";
import {
  bulkUpdateCcVariables,
  TCcVariable,
  TCcVariableType,
  TCcVarsFormOptionsValues,
  TComputationState,
} from "../../../store/slices/ccVariablesSlice";
import { CC_VAR_SEQUENCE_STEP } from "../../../utils/campaigns.constant";
import { getLastSequence } from "../../../utils/cm.utils";
import GridItemActions from "../GridItemActions/GridItemActions";
import GridItemForm from "../GridItemForm/GridItemForm";
import GridItemKey from "../GridItems/GridItemKey/GridItemKey";
import GridItemParameters from "../GridItems/GridItemParameters/GridItemParameters";
import GridItemResult from "../GridItems/GridItemResult/GridItemResult";
import GridItemState from "../GridItems/GridItemState/GridItemState";
import GridItemTitle from "../GridItems/GridItemTitle/GridItemTitle";
import CampaignClasses from "../../../pages/campaign/components/StepData/CampaignClasses";
import useCcGridData from "./useGetCcGridData.hook";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import useScrollToKey from "./useScrollToKey.hook";
import { EmptyResult } from "../../common/EmptyResult";
import { useCampaignParams } from "src/pages/campaign/components/CampaignEditorPage/useCampaignParams";
import SkeletonList from "../../common/SkeletonList";
import { CcItemEditorData } from "../../ccItemEditor/types";
import { CcItemEditor } from "../../ccItemEditor";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";

const { Column } = Table;

export type TCcGridItemData = {
  key: React.Key;
  state: TComputationState | undefined;
  type: TCcVariableType;
  title: TGridItemTitle;
  varKey: string;
  content: TCcVarsFormOptionsValues | undefined;
  result: string | null;
  override: string | null;
  action: null;
  variable: TCcVariable;
};

export type TGridItemTitle = {
  scope: "global" | "local";
  value: string;
};

export enum TitleModal {
  EDIT = "Edit Row",
  ADD = "Add Row",
  CLONE = "Clone Row",
}

export type TEditModeProps = {
  variableData: TCcVariable;
  actionType: TitleModal;
};

type Props = {};

const CampaignGrid: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { campaignId, stepId, companyId } = useCampaignParams();
  const [isAddRowModalOpened, setIsAddRowModalOpened] = useState(false);
  const [editModeProps, setEditModeProps] = useState<TEditModeProps | null>(
    null,
  );
  const [editCCItem, setEditCCItem] = useState<TCcVariable | null>(null);
  const [gridItemSequence, setGridItemSequence] = useState<number | null>(null);
  const { hasCampaignUIShowGridParametersRole, hasCampaignCcCreateRole } =
    useUserHasPermission({ companyId });

  const editCCItemData = useMemo<CcItemEditorData | null>(() => {
    if (editCCItem) {
      return {
        ccItemKey: editCCItem.id.key,
        campaignId: editCCItem.id.campaignId.toString(),
        stepId: editCCItem.id.stepId.toString(),
      };
    }

    return null;
  }, [editCCItem]);

  const { gridData, ccVars, isLoading } = useCcGridData({
    campaignId,
    stepId,
  });
  const { tableRef, highlightedRowKey } = useScrollToKey({
    isFetching: isLoading,
  });

  const onAddRow = () => {
    const sequence = getLastSequence(ccVars, CC_VAR_SEQUENCE_STEP);

    setGridItemSequence(sequence);
    setEditModeProps(null);
    setIsAddRowModalOpened(true);
  };

  return (
    <div className="flex flex-col gap-6">
      <CampaignClasses />
      <div className="flex flex-col gap-4">
        <div className="redesign">
          {isLoading ? (
            <SkeletonList quantity={3} />
          ) : (
            <>
              {gridData.length ? (
                <Table
                  ref={tableRef}
                  dataSource={gridData}
                  id="grid"
                  bordered={true}
                  pagination={false}
                  scroll={{ y: "70vh", x: 1200 }}
                  rowClassName={(record) =>
                    highlightedRowKey === record.key
                      ? "animate-highlight-cc-grid-row"
                      : ""
                  }
                >
                  <Column
                    title="Title"
                    dataIndex="title"
                    key="title"
                    width={225}
                    shouldCellUpdate={(
                      record: TCcGridItemData,
                      prevRecord: TCcGridItemData,
                    ) =>
                      JSON.stringify(record.title) !==
                      JSON.stringify(prevRecord.varKey)
                    }
                    render={(title: TGridItemTitle, { variable }) => (
                      <GridItemTitle ccItem={variable} title={title} />
                    )}
                  />
                  <Column
                    title="Key"
                    dataIndex="varKey"
                    key="varKey"
                    width={265}
                    shouldCellUpdate={(
                      record: TCcGridItemData,
                      prevRecord: TCcGridItemData,
                    ) =>
                      //render if key changed
                      record.varKey !== prevRecord.varKey ||
                      //render if modifiedBy changed
                      record.variable.modifiedBy !==
                        prevRecord.variable.modifiedBy ||
                      //render if modifiedTime changed
                      record.variable.modifiedTime !==
                        prevRecord.variable.modifiedTime
                    }
                    render={(_, { variable }) => (
                      <GridItemKey ccItem={variable} />
                    )}
                  />
                  <Column
                    title="Status"
                    dataIndex="state"
                    key="state"
                    width={80}
                    shouldCellUpdate={(
                      record: TCcGridItemData,
                      prevRecord: TCcGridItemData,
                    ) => record.state !== prevRecord.state}
                    align="center"
                    render={(state: TComputationState, record) => (
                      <GridItemState
                        state={state}
                        varId={record.variable.id}
                        executable={record.variable.executable}
                      />
                    )}
                  />
                  {hasCampaignUIShowGridParametersRole && (
                    <Column
                      title="Parameters"
                      dataIndex="content"
                      key="content"
                      width={250}
                      shouldCellUpdate={(
                        record: TCcGridItemData,
                        prevRecord: TCcGridItemData,
                      ) =>
                        JSON.stringify(record.content) !==
                        JSON.stringify(prevRecord.content)
                      }
                      render={(options) => (
                        <GridItemParameters options={options} />
                      )}
                    />
                  )}
                  <Column
                    title="Content"
                    dataIndex="result"
                    key="result"
                    // shouldCellUpdate={(
                    //   record: TCcGridItemData,
                    //   prevRecord: TCcGridItemData,
                    // ) =>
                    //   record.result !== prevRecord.result ||
                    //   record.override !== prevRecord.override
                    // }
                    // onCell={(record: TCcGridItemData) => ({
                    //   className: record.state === "error" ? "text-red-600" : "",
                    // })}
                    render={(_, { variable }) => {
                      const visibleValue =
                        variable.resultOvr || variable.result;

                      if (!visibleValue) return null;

                      return (
                        <GridItemResult
                          ccItem={variable}
                          value={visibleValue}
                        />
                      );
                    }}
                  />
                  <Column
                    title=""
                    key="action"
                    width={50}
                    fixed="right"
                    className="!p-0 !pt-[5px] !pl-[5px]"
                    render={(
                      _: any,
                      record: TCcGridItemData,
                      index: number,
                    ) => (
                      <GridItemActions
                        variable={record.variable}
                        prevVariable={gridData[index - 1]?.variable}
                        nextVariable={gridData[index + 1]?.variable}
                        setIsAddRowModalOpened={setIsAddRowModalOpened}
                        onSetEditCCItem={() => setEditCCItem(record.variable)}
                        setEditModeProps={setEditModeProps}
                        setGridItemSequence={setGridItemSequence}
                      />
                    )}
                  />
                </Table>
              ) : (
                <EmptyResult message="There are no computation items in the step." />
              )}
            </>
          )}
        </div>

        {hasCampaignCcCreateRole && (
          <Button
            variant="primaryOutline"
            className="rounded-full self-start"
            onClick={onAddRow}
            icon={Icons.Plus}
          >
            Add Row
          </Button>
        )}
      </div>

      {isAddRowModalOpened && gridItemSequence !== null && (
        <GridItemForm
          editModeProps={editModeProps}
          isOpened={isAddRowModalOpened}
          setIsOpened={setIsAddRowModalOpened}
          gridItemSequence={gridItemSequence}
          setGridItemSequence={setGridItemSequence}
          varKeysLowerCase={ccVars.map((item) => item.id.key.toLowerCase())}
          setEditModeProps={setEditModeProps}
        />
      )}

      {editCCItemData && companyId && (
        <CcItemEditor
          editDataProps={editCCItemData}
          companyId={companyId.toString()}
          onAfterClose={() => setEditCCItem(null)}
          onAfterCCItemUpdate={(ccItem) => {
            dispatch(bulkUpdateCcVariables([ccItem]));
          }}
        />
      )}
    </div>
  );
};

export default React.memo(CampaignGrid);
