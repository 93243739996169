import React, { FC, useMemo, useState } from "react";
import { AssetListItem } from "../../../globalTypes";
import { Table, TableProps } from "antd";
import { format } from "date-fns";
import { STATE_OPTIONS_CONFIG } from "../constants";
import { MICROSITE_INPUT_PLACEHOLDER } from "../../../constants";
import CopyToClipboard from "../../common/copy-to-clipboard";
import { getAssetIcon } from "../utils";
import { AssetInfo } from "./asset-info";
import { ApproveAssetSelector } from "./approve-asset-selector";
import { AssetMenu } from "./asset-menu";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";

type Props = {
  assets: AssetListItem[];
  loadAndSetAssets: () => Promise<void>;
  setAssets: React.Dispatch<React.SetStateAction<AssetListItem[]>>;
};

type AssetTableItem = AssetListItem & { key: number };

export const AssetsTable: FC<Props> = ({
  assets,
  loadAndSetAssets,
  setAssets,
}) => {
  const { companyId } = useParams();
  const [hoveredRowKey, setHoveredRowKey] = useState<number | null>(null);
  const dataSource: AssetTableItem[] = useMemo(() => {
    return assets.map((item) => ({ ...item, key: item.asset.id }));
  }, [assets]);
  const { hasAssetUIGetPubAWSLink } = useUserHasPermission({ companyId });

  const columns: TableProps<AssetTableItem>["columns"] = [
    {
      title: "Asset Name",
      key: "Asset Name",
      render: (_, { asset: { ref, title, type }, key }) => (
        <div className="flex items-center flex-grow-0 gap-1">
          <div className="flex-shrink-0">{getAssetIcon(type)}</div>
          <div>{title}</div>

          {ref && hasAssetUIGetPubAWSLink && (
            <CopyToClipboard
              copyValue={ref}
              showCopy={hoveredRowKey === key}
              tooltip="Copy public storage link"
            />
          )}
        </div>
      ),
    },
    {
      title: "Microsite Folder",
      key: "Microsite Folder",
      width: 240,
      render: (_, { asset: { microSiteTargetFolder, deployedUrl }, key }) => (
        <div className="min-w-[240px] flex items-center gap-2">
          <span className="text-[#3C4E63] break-all">
            {microSiteTargetFolder || MICROSITE_INPUT_PLACEHOLDER}
          </span>

          {deployedUrl && hasAssetUIGetPubAWSLink && (
            <CopyToClipboard
              copyValue={deployedUrl}
              showCopy={hoveredRowKey === key}
              tooltip="Copy microsite link"
            />
          )}
        </div>
      ),
    },
    {
      title: "Status",
      key: "Status",
      width: 200,
      render: (_, { asset }) => (
        <div className="w-[200px]">
          <ApproveAssetSelector asset={asset} setAssets={setAssets} />
        </div>
      ),
    },
    {
      title: "Last Updated",
      key: "Last Updated",
      width: 160,
      render: (
        _,
        { asset: { modifiedTime, publishedState }, modifiedByUser },
      ) => (
        <div className="w-[160px] text-[#3C4E63] text-xs leading-[140%]">
          <span className="text-[#0F172A] font-medium">
            {format(modifiedTime, "MMM d, yyyy, h:mm:ss a")}
          </span>
          <br />
          {modifiedByUser
            ? `By ${modifiedByUser.firstName} ${modifiedByUser.lastName}`
            : "By System"}
          <br />
          {STATE_OPTIONS_CONFIG[publishedState].title}
        </div>
      ),
    },
    {
      key: "menu",
      width: 100,
      render: (_, { asset, modifiedByUser, key }) => (
        <div className="flex items-center gap-4">
          <AssetInfo
            showInfo={hoveredRowKey === key}
            asset={asset}
            modifiedByUser={modifiedByUser}
          />
          <AssetMenu
            asset={asset}
            loadAndSetAssets={loadAndSetAssets}
            setAssets={setAssets}
          />
        </div>
      ),
    },
  ];

  return (
    <Table<AssetTableItem>
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowHoverable={false}
      className="redesignAntTable"
      scroll={{ x: 900 }}
      onRow={(record) => ({
        onMouseEnter: () => setHoveredRowKey(record.key),
        onMouseLeave: () => setHoveredRowKey(null),
        className: "hover:bg-muted/50 transition-colors",
      })}
    />
  );
};
