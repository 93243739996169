import React, { FC, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../common/ui/dialog";
import { Textarea } from "../../common/ui/textarea";
import { Button } from "../../common/ui/button";
import { getChatSettingsApi, updateChatSettingsApi } from "../../../api/chat.api";
import { getMessageApi } from "src/store/slices/appSlice";
import handleRequestError from "src/utils/handleRequestError";

interface PromptSettingsModalProps {
  chatId: number;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const PromptSettingsModal: FC<PromptSettingsModalProps> = ({
  chatId,
  isOpen,
  onOpenChange,
}) => {
  const messageApi = getMessageApi();
  const [prompt, setPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen && chatId) {
      loadSettings();
    }
  }, [isOpen, chatId]);

  const loadSettings = async () => {
    try {
      setIsLoading(true);
      const { data } = await getChatSettingsApi({ chatId });
      setPrompt(data.prompt || "");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveSettings = async () => {
    try {
      setIsLoading(true);
      await updateChatSettingsApi({
        chatSettings: {
          id: {
            chatId,
          },
          prompt,
        },
      });
      onOpenChange(false);
      messageApi.success("Chat settings updated successfully");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="min-w-[500px]">
        <DialogHeader>
          <DialogTitle>Chat Settings</DialogTitle>
        </DialogHeader>
        <div>
          <label
            htmlFor="prompt"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Prompt
          </label>
          <Textarea
            id="prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            className="min-h-[320px]"
            placeholder="Enter prompt settings..."
          />
        </div>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveSettings}
            disabled={isLoading}
            loading={isLoading}
          >
            Save Changes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PromptSettingsModal;
