import React, { FC, Fragment, useMemo } from "react";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import { VAR_IN_PROMPT_PLACEHOLDER } from "../../../utils/campaigns.constant";
import TemplatePromptVar from "./TemplatePromptVar";

type Props = {
  promptTemplate: string;
  varKeys: Array<string | null>;
  setVarKeys: (keys: Array<string | null>) => void;
  gridItemSequence: number;
  postProcessingIndex: number;
  localKeys: TCcVariable[];
};

const TemplatePrompt: FC<Props> = ({
  promptTemplate,
  varKeys,
  setVarKeys,
  gridItemSequence,
  postProcessingIndex,
  localKeys,
}) => {
  const localKeysOptions = useMemo(() => {
    return localKeys.map(({ id: { key } }) => ({
      label: key,
      value: key,
    }));
  }, [localKeys]);

  if (!promptTemplate.length) return <span>Prompt template is empty!</span>;

  const splitTemplate = promptTemplate.split(VAR_IN_PROMPT_PLACEHOLDER);

  return (
    <div className="bg-[rgb(243,247,255)] rounded-[8px] py-[5px] px-[10px] break-words">
      {splitTemplate.length === 1 ? (
        splitTemplate[0]
      ) : (
        <>
          {splitTemplate.map((item, index) => {
            if (index === splitTemplate.length - 1) {
              return (
                <Fragment key={`${promptTemplate}__${index}`}>{item}</Fragment>
              );
            }

            return (
              <Fragment key={`${promptTemplate}__${index}`}>
                {item}
                <TemplatePromptVar
                  varKeys={varKeys}
                  setVarKeys={setVarKeys}
                  index={index}
                  localKeysOptions={localKeysOptions}
                  gridItemSequence={gridItemSequence}
                  postProcessingIndex={postProcessingIndex}
                />
              </Fragment>
            );
          })}
        </>
      )}
    </div>
  );
};

export default TemplatePrompt;
