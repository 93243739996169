import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Icons } from "src/components/common/Icons";
import { Button } from "src/components/common/ui/button";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import { TemplatesPageTabs } from "./Tabs";
import { PageHeader } from "../common/PageHeader";

type Props = {};

const DocTemplatesHeader: FC<Props> = () => {
  const { hasDocumentTemplateCreateRole } = useUserHasPermission({});

  return (
    <div className="flex flex-col gap-1">
      <div className="flex justify-between">
        <PageHeader title="Templates" />

        <div className="flex gap-3">
          <SettingsMenu />

          {hasDocumentTemplateCreateRole && (
            <Link to="/templates/documents/create">
              <Button icon={Icons.Plus} className="rounded-full font-sans">
                Add XSLT Template
              </Button>
            </Link>
          )}
        </div>
      </div>
      <TemplatesPageTabs />
    </div>
  );
};

export default DocTemplatesHeader;
