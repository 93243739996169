import React, { FC } from "react";
import { XMarkIcon } from "./Icons";

type Props = {
  onClick: () => void;
};

const XMarkButton: FC<Props> = ({ onClick }) => {
  return (
    <div
      className="size-10 bg-[#F1F5F9] text-[#3C4E63] rounded-full flex justify-center items-center cursor-pointer hover:bg-gray-200 transition-colors"
      onClick={onClick}
    >
      <XMarkIcon />
      <span className="sr-only">Go back</span>
    </div>
  );
};

export default XMarkButton;
