import { Avatar, AvatarImage } from "../../common/ui/avatar";
import { ChatBubble, ChatInput, ChatMessageList } from "../../chat";
import { motion } from "motion/react";
import { FC, useEffect, useRef, useState } from "react";
import { ChatBubbleMessage } from "../../chat/chat-bubble";
import { cn } from "src/utils";
import ChatVEIcon from "src/assets/VEChatIcon.svg";
import { Badge } from "../../common/ui/badge";
import { X } from "lucide-react";
import { handleRequestError } from "src/utils/handleRequestError";
import { getMessageApi } from "src/store/slices/appSlice";
import { getAiModelConfigOptions } from "src/api/model-configs.api";
import { getDynFormSelectOptions } from "src/utils/cm.utils";
import { Select } from "antd";
import MessageLoading from "../../chat/message-loading";
import { ChatInfo, Chat as ChatType, ChatMessageRequest, KeyContext } from "../types";
import {
  selectChatMessages,
  selectChatSelectedCCVarKeys,
  selectChatSelectedCCVars,
  unselectCCVarKey,
  usePostChatMessageMutation,
  selectChatCcVars,
  selectChatOverrides,
} from "src/store/slices/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { calculateTime, formatTime, getMessageVariant, getSelectedText } from "../utils";
import { useErrors } from "src/hooks/useErrors";

type ChatProps = {
  chatInfo: ChatInfo;
  updateChatInfo: (chatInfo: Partial<ChatType>) => void;
};

export const Chat: FC<ChatProps> = ({ chatInfo, updateChatInfo }) => {
  const messages = useSelector(selectChatMessages);
  const selectedCCVarKeys = useSelector(selectChatSelectedCCVarKeys);
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const [inputHeight, setInputHeight] = useState<number>(0);
  const messageApi = getMessageApi();
  const [lastSentRequestMessage, setLastSentRequestMessage] = useState<
    string | null
  >("");
  const keysRequestContext = useSelector(selectChatSelectedCCVars);
  const ccVars = useSelector(selectChatCcVars);
  const chatOverrides = useSelector(selectChatOverrides);

  const [postChatMessage, { error: postChatMessageError }] =
    usePostChatMessageMutation();

  useErrors([postChatMessageError]);

  const [models, setModels] = useState<
    { label: string; value: string | number | null }[]
  >([]);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const { data } = await getAiModelConfigOptions();
        const options = getDynFormSelectOptions({ data });
        setModels(options);
      } catch (e: any) {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);
      }
    };
    fetchModels();
  }, []);

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages, chatInfo.chat.id]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSendMessage(e as unknown as React.FormEvent<HTMLFormElement>);
    }
  };

  const handleSendMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!input) return;

    setInput("");
    formRef.current?.reset();

    // If no CC vars are selected, assume all CC vars should be included
    let messageKeysContext = keysRequestContext;
    if (selectedCCVarKeys.length === 0 && ccVars && ccVars.length > 0) {
      // Create context for all available CC vars
      messageKeysContext = ccVars.reduce((acc: Record<string, KeyContext>, ccVar) => {
        const ccVarChatOverride = chatOverrides.find(
          (override) => override.id.key === ccVar.id.key
        );
        const text = getSelectedText(ccVar, ccVarChatOverride);
        acc[ccVar.id.key] = {
          text,
          selections: [{ start: 0, end: text.length }]
        };
        return acc;
      }, {});
    }

    setLastSentRequestMessage(input + JSON.stringify(messageKeysContext));

    const message: ChatMessageRequest = {
      keysRequestContext: messageKeysContext,
      prompt: input,
    };

    await postChatMessage({
      chatId: chatInfo.chat.id,
      message,
    });
  };

  const handleInputResize = (height: number) => {
    setInputHeight(height);
  };

  const isAIReplied =
    messages.length > 0 && messages[messages.length - 1].type === "RESPONSE";

  return (
    <div className="flex h-[calc(100vh-134px)] w-full flex-col">
      <div
        className="flex w-[400px] overflow-y-auto bg-muted/40"
        style={{ height: `calc(100% - ${inputHeight}px)` }}
      >
        <ChatMessageList ref={messagesContainerRef}>
          {messages.map((message, index) => {
            const variant = getMessageVariant(message.type);
            const isAssistant = message.type === "RESPONSE";

            return (
              <motion.div
                key={message.id.timestamp}
                layout
                initial={{ opacity: 0, scale: 1, y: 50, x: 0 }}
                animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
                exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
                transition={{
                  opacity: { duration: 0.1 },
                  layout: {
                    type: "spring",
                    bounce: 0.3,
                    duration: index * 0.05 + 0.2,
                  },
                }}
                style={{ originX: 0.5, originY: 0.5 }}
                className="flex flex-col gap-2"
              >
                <ChatBubble variant={variant}>
                  {isAssistant ? (
                    <Avatar className="w-[32px] h-[32px] p-[8px] border-[#D4DAE0] border bg-white">
                      <AvatarImage src={ChatVEIcon} alt="Avatar" />
                    </Avatar>
                  ) : null}
                  <ChatBubbleMessage
                    isLoading={false}
                    className={cn(
                      "bg-[#E2E8F0]",
                      isAssistant && "bg-[#F8FAFC] w-[320px] px-2 py-0",
                    )}
                  >
                    {message.text}
                  </ChatBubbleMessage>
                </ChatBubble>
              </motion.div>
            );
          })}
          {messages.length > 0 && !isAIReplied && (
            <div className="flex flex-col gap-2">
              <ChatBubble variant="received">
                <ChatBubbleMessage className="flex items-center gap-2">
                  <MessageLoading />{" "}
                  <span className="text-xs">
                    Please, wait ~
                    {formatTime(calculateTime(lastSentRequestMessage || ""))}
                  </span>
                </ChatBubbleMessage>
              </ChatBubble>
            </div>
          )}
        </ChatMessageList>
      </div>
      <div className="px-4 pb-4 bg-muted/40 shrink-0">
        <form
          ref={formRef}
          onSubmit={handleSendMessage}
          className="relative rounded-lg border bg-background focus-within:ring-1 focus-within:ring-ring"
        >
          {selectedCCVarKeys.length > 0 && (
            <div className="flex flex-wrap gap-2 p-1 bg-muted/40 border-b max-h-24 overflow-y-auto max-w-[370px]">
              {selectedCCVarKeys.map((ccVarKey) => (
                <Badge
                  key={ccVarKey}
                  variant="secondary"
                  className="text-sm !rounded-sm"
                >
                  {ccVarKey}
                  <X
                    className="size-4 cursor-pointer hover:bg-gray-200 rounded ml-1"
                    onClick={() => dispatch(unselectCCVarKey(ccVarKey))}
                  />
                </Badge>
              ))}
            </div>
          )}
          <ChatInput
            ref={inputRef}
            onKeyDown={handleKeyDown}
            onChange={(e) => setInput(e.target.value)}
            onHeightChange={handleInputResize}
            placeholder="Type your message here..."
            className="min-h-12 resize-none rounded-lg bg-background border-0 p-3 shadow-none focus-visible:ring-0"
          />
          <div className="flex items-center p-3 pt-0">
            <Select
              options={models.map((model) => ({
                label: model.label,
                value: model.value as string,
              }))}
              style={{ width: 180, height: 24 }}
              value={
                models.find((m) => Number(m.value) === chatInfo.chat.aiModelId)
                  ?.value || null
              }
              onChange={(value) => {
                updateChatInfo({
                  aiModelId: value ? Number(value) : null,
                });
              }}
              placeholder="Select Model"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
