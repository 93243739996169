import React, { useRef } from "react";
import { Popover } from "antd";
import { RiInformationLine } from "@remixicon/react";
import clsx from "clsx";
import useHover from "../../../hooks/useHover";
import { getAssetIcon } from "../utils";
import { TAsset, TUser } from "../../../globalTypes";
import { format } from "date-fns";

type Props = {
  showInfo: boolean;
  asset: TAsset;
  modifiedByUser: TUser | null;
};

type InfoRowProps = {
  label: string;
  children: React.ReactNode;
};

const InfoRow = ({ label, children }: InfoRowProps) => (
  <div className="flex flex-col gap-1">
    <div className="font-semibold leading-[100%]">{label}</div>
    {children}
  </div>
);

export const AssetInfo = ({ showInfo, asset, modifiedByUser }: Props) => {
  const { type, createdTime, operationDate } = asset;
  const iconRef = useRef<HTMLDivElement | null>(null);
  const isHovering = useHover(iconRef);
  const infoLabelClassName = "text-[#0F172A] font-medium text-xs";

  return (
    <Popover
      placement="topRight"
      content={
        <div className="flex flex-col gap-4">
          <InfoRow label="Asset Type:">
            <div className="flex items-center gap-2">
              <div className="size-4">{getAssetIcon(type)}</div>
              <span
                className={clsx(
                  "lowercase first-letter:uppercase",
                  infoLabelClassName,
                )}
              >
                {type}
              </span>
            </div>
          </InfoRow>

          <InfoRow label="Created At:">
            <span className={infoLabelClassName}>
              {format(createdTime, "MMM d, yyyy, h:mm:ss a")}
            </span>
          </InfoRow>

          {operationDate && (
            <InfoRow label="Last Operation Date:">
              <span className={infoLabelClassName}>
                {format(operationDate, "MMM d, yyyy, h:mm:ss a")}
              </span>
            </InfoRow>
          )}

          <InfoRow label="Modified By:">
            {!modifiedByUser ? (
              <span className="text-vePrimary text-xs">System</span>
            ) : (
              <div className="flex flex-col text-xs font-medium max-w-xs">
                <span className="text-vePrimary">
                  {`${modifiedByUser.firstName} ${modifiedByUser.lastName} (${modifiedByUser.position})`}
                </span>
                <span className="text-[#0F172A] break-words">
                  {modifiedByUser.email}
                </span>
              </div>
            )}
          </InfoRow>
        </div>
      }
      open={isHovering}
    >
      <div ref={iconRef}>
        <RiInformationLine
          className={clsx(
            "cursor-pointer text-[#3C4E63] size-6 transition-opacity",
            {
              "opacity-100": showInfo,
              "opacity-0": !showInfo,
            },
          )}
        />
      </div>
    </Popover>
  );
};
