import axiosInstance from "../utils/axios/axiosInstance";
import {
  Chat,
  ChatInfo,
  ChatMessage,
  ChatMessageRequest,
  ChatOverride,
  ChatSettings,
} from "../components/CampaignChat/types";

export const getChatApi = ({ chatId }: { chatId: number }) => {
  return axiosInstance.get<ChatInfo>(`/api/secured/chat/${chatId}`);
};

export const getChatListApi = ({
  campaignId,
  stepId,
}: {
  campaignId: number;
  stepId: number;
}) => {
  return axiosInstance.get<ChatInfo[]>(
    `/api/secured/chat/${campaignId}/${stepId}`,
  );
};

export const createChatApi = ({
  campaignId,
  stepId,
  chat,
}: {
  campaignId: number;
  stepId: number;
  chat: Partial<Chat>;
}) => {
  return axiosInstance.post<Chat>(
    `/api/secured/chat/${campaignId}/${stepId}`,
    chat,
  );
};

export const updateChatTitleApi = ({
  chatId,
  title,
}: {
  chatId: number;
  title: string;
}) => {
  return axiosInstance.put<void>(
    `/api/secured/chat/${chatId}/update-title`,
    title,
  );
};

export const updateChatApi = ({ chat }: { chat: Partial<Chat> }) => {
  return axiosInstance.put<Chat>(`/api/secured/chat/${chat.id}`, chat);
};

// Delete chat
export const deleteChatApi = ({ chatId }: { chatId: number }) => {
  return axiosInstance.delete<void>(`/api/secured/chat/${chatId}`);
};

// Get chat messages
export const getChatMessagesApi = ({
  chatId,
  lastTimestamp,
}: {
  chatId: number;
  lastTimestamp?: string;
}) => {
  return axiosInstance.get<ChatMessage[]>(
    `/api/secured/chat/${chatId}/messages`,
    {
      params: {
        lastTimestamp,
      },
    },
  );
};

export const postChatMessageApi = ({
  chatId,
  message,
}: {
  chatId: number;
  message: ChatMessageRequest;
}) => {
  return axiosInstance.post<void>(
    `/api/secured/chat/${chatId}/message`,
    message,
  );
};

// Update content in bulk
export const updateChatContentBulkApi = ({
  campaignId,
  stepId,
  content,
  recomputeDownStream = false,
}: {
  campaignId: number;
  stepId: number;
  content: Record<string, string>;
  recomputeDownStream?: boolean;
}) => {
  return axiosInstance.put<void>(
    `/api/secured/${campaignId}/cc/${stepId}/content/bulk`,
    content,
    {
      params: {
        recomputeDownStream,
      },
    },
  );
};

export const updateChatMessageApi = ({
  chatId,
  message,
}: {
  chatId: number;
  message: ChatMessage;
}) => {
  return axiosInstance.put<ChatMessage>(
    `/api/secured/chat/${chatId}/message`,
    message,
  );
};

export const getChatOverridesApi = ({ chatId }: { chatId: number }) => {
  return axiosInstance.get<ChatOverride[]>(
    `/api/secured/chat/override/${chatId}`,
  );
};

export const updateChatOverrideApi = ({
  chatOverride,
}: {
  chatOverride: ChatOverride;
}) => {
  return axiosInstance.put<ChatOverride>(
    `/api/secured/chat/override`,
    chatOverride,
  );
};

export const createChatOverrideApi = ({
  chatOverride,
}: {
  chatOverride: ChatOverride;
}) => {
  return axiosInstance.post<ChatOverride>(
    `/api/secured/chat/override`,
    chatOverride,
  );
};

// Chat Settings API
export const getChatSettingsApi = ({ chatId }: { chatId: number }) => {
  return axiosInstance.get<ChatSettings>(
    `/api/secured/chat-settings/${chatId}`,
  );
};

export const updateChatSettingsApi = ({
  chatSettings,
}: {
  chatSettings: ChatSettings;
}) => {
  return axiosInstance.put<ChatSettings>(
    `/api/secured/chat-settings/${chatSettings.id.chatId}`,
    chatSettings,
  );
};
