import React, { FC } from "react";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import ContentTabContent from "./ContentTabContent";
import { CcItemEditType } from "../constants";
import { OnAfterCCItemUpdate } from "../types";
import ModelTabContent from "./ModelTabContent";
import PromptTabContent from "./PromptTabContent";

type Props = {
  ccItem: TCcVariable;
  activeTab: CcItemEditType;
  ccItemPhaseId: number;
  onAfterCCItemUpdate: OnAfterCCItemUpdate;
  hasCampaignEnvItemUpdateRole: boolean;
  hasCampaignCcOverrideRole: boolean;
  hasCampaignCcUpdateRole: boolean;
};

const EditorNavContent: FC<Props> = ({
  ccItem,
  onAfterCCItemUpdate,
  ccItemPhaseId,
  activeTab,
  hasCampaignEnvItemUpdateRole,
  hasCampaignCcOverrideRole,
  hasCampaignCcUpdateRole,
}) => {
  if (activeTab === CcItemEditType.CONTENT) {
    return (
      <ContentTabContent
        hasCampaignCcUpdateRole={hasCampaignCcUpdateRole}
        hasCampaignCcOverrideRole={hasCampaignCcOverrideRole}
        ccItem={ccItem}
        onAfterCCItemUpdate={onAfterCCItemUpdate}
      />
    );
  }

  if (activeTab === CcItemEditType.MODEL) {
    return (
      <ModelTabContent
        ccItem={ccItem}
        ccItemPhaseId={ccItemPhaseId}
        onAfterCCItemUpdate={onAfterCCItemUpdate}
      />
    );
  }

  if (activeTab === CcItemEditType.PROMPT) {
    return (
      <PromptTabContent
        ccItem={ccItem}
        onAfterCCItemUpdate={onAfterCCItemUpdate}
        hasCampaignEnvItemUpdateRole={hasCampaignEnvItemUpdateRole}
      />
    );
  }

  return <span className="text-red-600">Edit type not supported!</span>;
};

export default EditorNavContent;
