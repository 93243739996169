import React, { FC, useState } from "react";
import { Handle, Position } from "reactflow";
import CCItemStateIcon from "../../../common/CCItemStateIcon";
import {
  DownstreamDepsModalDefaultNodeProps,
  DownstreamDepsModalGroupNodeProps,
} from "../../types";
import MenuDropdown, { MenuDropdownItem } from "../../../common/MenuDropdown";
import { Button } from "../../../common/ui/button";
import {
  ExecuteIcon,
  Icons,
  MoveDownIcon,
  SolidEditIcon,
} from "../../../common/Icons";
import { CampaignViewTypes } from "../../../../types";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { NavLink, useLocation } from "react-router-dom";
import ExecuteCcItemModal from "../../../common/modals/ExecuteCCItemModal";
import useDownstreamViewQueryParams from "../../hooks/downstreamDeps/useDownstreamViewQueryParams";
import { Badge } from "../../../common/ui/badge";
import { campaignStatusBadgeSettings } from "../../../../constants";
import { Tooltip } from "antd";

type DefaultNodeProps = {
  //set data props for each node in useGetNodesAndEdges hook
  data: DownstreamDepsModalDefaultNodeProps;
};

export const DefaultNode: FC<DefaultNodeProps> = ({
  data: { ccItem, phaseId, companyId, phaseName, stepName },
}) => {
  const {
    id: { key, stepId, campaignId },
    state,
    resultOvr,
    type,
  } = ccItem;
  const hasOverride = !!resultOvr;
  const { hasCampaignCcItemComputationRole, hasCampaignCcReadRole } =
    useUserHasPermission({
      companyId,
    });
  const [isExecuteOpen, setIsExecuteOpen] = useState(false);
  const keyPath = `${phaseName}/${stepName}`;
  const goToKeyPath = `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}?view=${CampaignViewTypes.GRID}#${key}`;
  const { setEditCcItemParams } = useDownstreamViewQueryParams();
  const isDownstreamPage =
    useLocation().pathname.startsWith("/graph/downstream");

  const onShowExecuteModal = () => {
    setIsExecuteOpen(true);
  };

  const onCloseExecuteModal = () => {
    setIsExecuteOpen(false);
  };

  const nodeMenuItems = [
    hasCampaignCcItemComputationRole && {
      key: "execute",
      label: "Execute",
      disabled: false,
      icon: ExecuteIcon,
      onClick: (e: any) => {
        e.stopPropagation();
        onShowExecuteModal();
      },
      className: "!text-vePrimary",
    },
    {
      key: "goToKeyDefinition",
      label: (
        <NavLink target="_blank" to={goToKeyPath}>
          Go To Key Definition
        </NavLink>
      ),
      disabled: false,
      icon: MoveDownIcon,
    },
    isDownstreamPage &&
      hasCampaignCcReadRole && {
        key: "edit",
        label: "Edit",
        disabled: false,
        icon: SolidEditIcon,
        onClick: (e: any) => {
          e.stopPropagation();
          setEditCcItemParams({
            campaignId: campaignId.toString(),
            stepId: stepId.toString(),
            key,
          });
        },
      },
  ].filter((v) => !!v) as MenuDropdownItem[];

  const isNodeMenuEmpty = nodeMenuItems.length === 0;

  return (
    <div className="relative w-full h-full p-4 overflow-hidden">
      {hasOverride && (
        <Tooltip title="Override">
          <div className="absolute top-[-5px] right-[-15px] w-[40px] h-[20px] bg-gradient-to-t from-white to-yellow-500 rotate-45" />
        </Tooltip>
      )}

      <Handle
        className="!left-[-1px] opacity-0"
        type="target"
        position={Position.Left}
        isConnectable={false}
      />

      <div className="w-full h-full flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 overflow-hidden">
            <div className="flex-shrink-0">
              <CCItemStateIcon state={state} />
            </div>
            <div>
              <Badge
                className={
                  campaignStatusBadgeSettings[type]?.className ||
                  "bg-orange-100 text-orange-500"
                }
                variant="table"
              >
                {type}
              </Badge>
            </div>
          </div>

          {!isNodeMenuEmpty && nodeMenuItems && (
            <MenuDropdown items={nodeMenuItems} align="end">
              <Button
                variant={"ghostPrimary"}
                className="p-[1px] size-8"
                onClick={(e) => e.stopPropagation()}
              >
                <Icons.MoreDots className="size-6" />
              </Button>
            </MenuDropdown>
          )}
        </div>

        <div className="flex flex-col">
          <div className="w-full text-left text-xs text-[#3C4E63] truncate">
            {keyPath}
          </div>
          <div className="w-full text-left text-[#475569] font-semibold text-base truncate">
            {key}
          </div>
        </div>
      </div>

      <Handle
        className="!right-[-2px] opacity-0"
        type="source"
        position={Position.Right}
        isConnectable={false}
      />

      {isExecuteOpen && (
        <div onClick={(e) => e.stopPropagation()}>
          <ExecuteCcItemModal
            ccItemKey={key}
            closeModal={onCloseExecuteModal}
            isOpen={isExecuteOpen}
            stepId={stepId.toString()}
            campaignId={campaignId.toString()}
            showDownstream={false}
          />
        </div>
      )}
    </div>
  );
};

type GroupNodeProps = {
  data: DownstreamDepsModalGroupNodeProps;
};
export const GroupNode: FC<GroupNodeProps> = ({ data: { label } }) => {
  return (
    <div className="absolute">
      <strong className="relative top-[-5px] text-info">{label}</strong>
    </div>
  );
};
