import { AllowedSortFields, ArchiveStatus, CampaignType } from "../types";
import { SortingDirection } from "../../../types/docTemplates";
import {
  CampaignListSearchParams,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../constants";
import { UserMyPermissions } from "../../../store/slices/userData/types";
import { buildPermissions } from "../../../store/slices/userData/utils";
import { UserPermissions } from "../../../store/slices/userData/constants";

const _isAllowedSortField = (
  field: string | null,
): field is AllowedSortFields => {
  if (!field) return false;

  return ["name", "modifiedTime", "companyName", "type"].includes(field);
};

const _isSortingDirection = (
  value: string | null,
): value is SortingDirection => {
  return Object.values(SortingDirection).includes(value as SortingDirection);
};

const _isValidNumber = (pageNum: string | null): boolean => {
  const pageNumber = Number(pageNum);

  return !isNaN(pageNumber) && pageNumber > 0 && Number.isInteger(pageNumber);
};

export const getValidatedSortOrderByParamValues = (
  sortBy: string | null,
  orderBy: string | null,
): {
  sortBy: AllowedSortFields | null;
  orderBy: SortingDirection | null;
} => {
  if (!_isAllowedSortField(sortBy) || !_isSortingDirection(orderBy)) {
    return {
      sortBy: null,
      orderBy: null,
    };
  }

  return {
    sortBy,
    orderBy,
  };
};

export const getValidatedArchiveParamValue = (
  archive: string | null,
): ArchiveStatus => {
  if (archive === "false" || archive === "true") {
    return archive;
  }

  return "all";
};

export const getValidatedPageNumParamValue = (
  pageNum: string | null,
): number => {
  return _isValidNumber(pageNum) ? Number(pageNum) : DEFAULT_PAGE_NUMBER;
};

export const getValidatedPageSizeParamValue = (
  pageSize: string | null,
): number => {
  return _isValidNumber(pageSize) ? Number(pageSize) : DEFAULT_PAGE_SIZE;
};

export const getValidatedCompanyIdParamValue = (
  companyId: string | null,
): number | null => {
  return _isValidNumber(companyId) ? Number(companyId) : null;
};

export const convertToBoolean = (
  value: ArchiveStatus | null,
): boolean | undefined => {
  if (value === "true") return true;
  if (value === "false") return false;
  return undefined;
};

//for templates page we need "DRAFT_TEMPLATE,PUBLISHED_TEMPLATE" type as default
//for campaigns page we need "CAMPAIGN"
export const getValidatedCampaignTypeParamValue = (
  isTemplatesPage: boolean,
  type: string | null,
): CampaignType => {
  if (isTemplatesPage) {
    if (
      type !== "DRAFT_TEMPLATE" &&
      type !== "PUBLISHED_TEMPLATE" &&
      type !== "DRAFT_TEMPLATE,PUBLISHED_TEMPLATE"
    ) {
      return "DRAFT_TEMPLATE,PUBLISHED_TEMPLATE";
    } else {
      return type;
    }
  }

  return "CAMPAIGN";
};

export const getHasCampaignReadPerm = (
  permissions: UserMyPermissions,
  companyId: number,
) => {
  const { globalPermissions, companyPermissions, companyPermissionsDeny } =
    permissions;

  const permissionsSet = buildPermissions({
    globalPermissions,
    companyPermissions,
    companyPermissionsDeny,
    companyId,
  });

  return permissionsSet.has(UserPermissions.ROLE_CAMPAIGN_READ);
};

export const getCampaignListRedirectUrl = () => {
  const pageSizeParam = `${CampaignListSearchParams.PAGE_SIZE}=${DEFAULT_PAGE_SIZE}`;
  const pageNumParam = `${CampaignListSearchParams.PAGE_NUM}=${DEFAULT_PAGE_NUMBER}`;

  return `/campaigns?${pageNumParam}&${pageSizeParam}`;
};

export const getActiveCampaignListRedirectUrl = (
  companyId: number,
  backUrl: string,
) => {
  const companyIdParam = `${CampaignListSearchParams.COMPANY_ID}=${companyId}`;
  const pageSizeParam = `${CampaignListSearchParams.PAGE_SIZE}=${DEFAULT_PAGE_SIZE}`;
  const pageNumParam = `${CampaignListSearchParams.PAGE_NUM}=${DEFAULT_PAGE_NUMBER}`;
  const archiveParam = `${CampaignListSearchParams.ARCHIVE}=false`;
  const backUrlParam = `${CampaignListSearchParams.BACK_URL}=${encodeURIComponent(backUrl)}`;

  return `/campaigns?${companyIdParam}&${pageNumParam}&${pageSizeParam}&${archiveParam}&${backUrlParam}`;
};
