import { FC } from "react";

import { cn } from "src/utils";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "src/components/ui/accordion";
import { RevisionItem } from "./revision-item";
import { RevisionScope } from "src/store/slices/historySlice";

export const DayGroup: FC<{
  day: string;
  revisions: RevisionScope[];
  selectedRevisionId: number | null;
  onRevisionSelect: (transactionId: string, revisionId: number) => void;
  isExpanded: boolean;
  onToggle: () => void;
}> = ({
  day,
  revisions,
  selectedRevisionId,
  onRevisionSelect,
  isExpanded,
  onToggle,
}) => {
  const latestRevision = revisions[0];

  return (
    <AccordionItem value={day} className="border-b">
      <AccordionTrigger
        onClick={onToggle}
        className={cn(
          "flex items-center justify-between px-4 py-3 hover:bg-slate-50 hover:no-underline",
          isExpanded ? "bg-slate-50" : "",
        )}
      >
        <div className="flex flex-col gap-1">
          <div className="text-slate-900 text-base font-medium">{day}</div>
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 bg-[#066afe] rounded-full" />
            <span className="text-sm text-slate-600">
              {latestRevision.user.firstName} {latestRevision.user.lastName}
            </span>
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <div className="border-l-2 border-slate-200 ml-8 my-2">
          {revisions.map((rev) => (
            <RevisionItem
              key={rev.revision.id}
              revision={rev.revision}
              user={rev.user}
              isSelected={selectedRevisionId === rev.revision.id}
              onSelect={onRevisionSelect}
            />
          ))}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};
