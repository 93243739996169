import { getCompaniesApi } from "../../../api/companies.api";
import { useCallback, useEffect, useRef, useState } from "react";
import { TCompany } from "../../../store/slices/companiesSlice";
import { useCampaignListQueryParams } from "./useCampaignListQueryParams";
import { CampaignListQueryParams } from "../types";
import { convertToBoolean, getValidatedCompanyIdParamValue } from "../utils";
import handleRequestError from "../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { getCampaignsApi } from "../../../api/campaigns.api";
import { TCampaign } from "../../../store/slices/campaignsSlice";
import localStorageProvider from "../../../utils/localStorageProvider";
import { ALL_COMPANIES_OPTION_VALUE } from "../constants";

export const useInitCampaignList = () => {
  const messageApi = useSelector(selectMessageApi);
  const [initialized, setInitialized] = useState(false);
  const [companies, setCompanies] = useState<TCompany[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [campaigns, setCampaigns] = useState<TCampaign[]>([]);
  const [totalCampaigns, setTotalCampaigns] = useState<number>(0);
  const abortControllerRef = useRef<AbortController | null>(null);
  const currentRequestId = useRef(0);
  const {
    params,
    setCompanyIdParam,
    setArchivedParam,
    setPageSizeParam,
    setPageNumParam,
    setSortingParams,
    setCampaignTypeParam,
  } = useCampaignListQueryParams();
  const reloadCampaignList = useCallback(async () => {
    await requestCampaignsList(params);
  }, [params]);

  useEffect(() => {
    const abortController = new AbortController();

    void loadCompanies(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (!initialized || error) return;

    void requestCampaignsList(params);

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [params, initialized]);

  const requestCampaignsList = async ({
    companyId,
    orderBy,
    pageSize,
    page,
    sortBy,
    archive,
    type,
  }: CampaignListQueryParams) => {
    setLoading(true);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    currentRequestId.current += 1;
    const requestId = currentRequestId.current;

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const { data } = await getCampaignsApi({
        companyId: companyId || null,
        currentPage: page,
        pageSize,
        campaignType: type,
        archive: convertToBoolean(archive),
        sortBy: sortBy || undefined,
        orderBy: orderBy || undefined,
        signal: abortController.signal,
      });

      setCampaigns(data.items);
      setTotalCampaigns(data.totalItems);
      setError("");
    } catch (e: any) {
      if (e.name !== "CanceledError" && e.name !== "AbortError") {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);

        setError(customError.message);
      }
    } finally {
      if (requestId === currentRequestId.current) {
        setLoading(false);
      }
    }
  };

  async function loadCompanies(signal: AbortSignal) {
    try {
      const {
        data: { items },
      } = await getCompaniesApi({
        name: "",
        pageNumber: 1,
        pageSize: 1000,
        signal,
      });

      if (items.length) {
        // Check if the current company ID parameter exists in company list
        const isValidId = items.some(
          (company) => company.id === params.companyId,
        );

        if (!isValidId) {
          if (params.companyId === null) {
            const companyIdValueLS =
              localStorageProvider.getSelectedCompanyId();

            if (companyIdValueLS !== ALL_COMPANIES_OPTION_VALUE) {
              // A specific company ID was retrieved from local storage and it's not the "ALL" option.
              const validCompanyId =
                getValidatedCompanyIdParamValue(companyIdValueLS);

              if (validCompanyId !== null) {
                // The company ID from local storage is valid.
                localStorageProvider.setSelectedCompanyId(
                  validCompanyId.toString(),
                );
                setCompanyIdParam(validCompanyId.toString());
              } else {
                // The company ID from local storage is invalid. Use the first company from the list.
                const actualCompanyId = items[0].id.toString();
                localStorageProvider.setSelectedCompanyId(actualCompanyId);
                setCompanyIdParam(actualCompanyId);
              }
            }
          } else {
            // The provided company ID parameter is invalid.
            // Default to using the first company in the returned list.
            const firstCompanyId = items[0].id.toString();
            localStorageProvider.setSelectedCompanyId(firstCompanyId);
            setCompanyIdParam(firstCompanyId);
          }
        }

        // Update the list of companies in the state.
        setCompanies(items);
      }

      setError("");
    } catch (e: any) {
      if (e.name !== "CanceledError" && e.name !== "AbortError") {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);

        setError(customError.message);
      }
    } finally {
      setInitialized(true);
    }
  }

  return {
    campaignParams: params,
    initialized,
    companies,
    setCompanyIdParam,
    setArchivedParam,
    setPageSizeParam,
    setPageNumParam,
    setSortingParams,
    setCampaignTypeParam,
    loading,
    campaigns,
    totalCampaigns,
    reloadCampaignList,
    error,
  };
};
