import { Icons } from "../../../components/common/Icons";
import Pagination from "../../../components/common/Pagination";
import { Button } from "../../../components/common/ui/button";
import CompanyDrawer from "../../../components/companies/CompanyDrawer/CompanyDrawer";
import useCompaniesListHook from "./useCompaniesList";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { Table, TableBody } from "../../../components/common/ui/table";
import { TableHeader } from "../../../components/table/Header";
import CompaniesListItem from "../../../components/companies/CompaniesListItem";
import SkeletonList from "../../../components/common/SkeletonList";
import { EmptyResult } from "src/components/common/EmptyResult";
import { SortingDirection } from "src/types/docTemplates";
import { TCompany } from "src/store/slices/companiesSlice";
import CompanySkeletonRow from "src/components/companies/CompanySkeletonRow";
import { Input } from "../../../components/common/ui/input";
import { debounce } from "throttle-debounce";
import { useEffect, useMemo, useRef } from "react";
import { getNextOrder } from "src/utils";

const tableHeaderItems = [
  {
    title: "Company Name",
    className: "w-1/3 font-sans font-bold text-[#0F172A]",
    name: "name",
    isSortable: true,
  },
  {
    title: "Website",
    className: "w-1/3 font-sans font-bold text-[#0F172A]",
  },
  {
    title: "Creation date",
    className: "w-1/3 font-sans font-bold text-[#0F172A]",
    name: "createdTime",
    isSortable: true,
  },
  null,
];

const CompaniesPage = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const {
    total,
    updatePage,
    updatePageSize,
    loading,
    list,
    onCreateCompany,
    drawerVisible,
    setDrawerVisible,
    updateSorting,
    updateSearch,
    requestParams,
  } = useCompaniesListHook();
  const {
    pageNumber,
    pageSize,
    sortBy,
    orderBy,
    name: searchTerm,
  } = requestParams;
  const { hasCompanyCreateRole } = useUserHasPermission({});

  useEffect(() => {
    if (searchInputRef.current && !searchTerm) {
      searchInputRef.current.value = "";
    }
  }, [searchTerm]);

  const renderPagination = () => {
    return (
      pageNumber > 0 && (
        <Pagination
          updatePageSize={updatePageSize}
          updatePage={updatePage}
          total={total}
          pageSize={pageSize}
          pageNumber={pageNumber}
        />
      )
    );
  };

  const handleUpdateSorting = (field: string) => {
    if (field !== sortBy) {
      updateSorting(field as keyof TCompany, SortingDirection.ASC);
      return;
    }

    updateSorting(field as keyof TCompany, getNextOrder(orderBy));
  };

  const debouncedSearch = useMemo(
    () => debounce(500, (value: string) => updateSearch(value)),
    [updateSearch],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    e.target.value = value;
    debouncedSearch(value);
  };

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex justify-between">
        <h1 className=" text-slate-900 text-2xl font-semibold font-sans leading-normal">
          Companies
        </h1>
        {hasCompanyCreateRole && (
          <Button
            icon={Icons.Plus}
            className="rounded-full"
            disabled={loading}
            onClick={() => setDrawerVisible(true)}
          >
            Add New Company
          </Button>
        )}
      </div>

      <div className="w-full flex justify-between">
        <Input
          ref={searchInputRef}
          type="search"
          placeholder="Search companies..."
          className="max-w-sm"
          defaultValue={searchTerm}
          onChange={handleSearchChange}
        />
        {renderPagination()}
      </div>

      {list.length ? (
        <>
          <div className="rounded-md border w-full mx-auto">
            <Table>
              <TableHeader
                items={tableHeaderItems}
                handleUpdateSorting={handleUpdateSorting}
                sorting={{
                  field: sortBy,
                  direction: orderBy,
                }}
              />
              <TableBody className="font-sans">
                {!loading
                  ? list.map((item) => (
                      <CompaniesListItem key={item.id} item={item} />
                    ))
                  : Array.from({ length: 12 }).map((_, index) => (
                      <CompanySkeletonRow key={index} />
                    ))}
              </TableBody>
            </Table>
          </div>

          {renderPagination()}
        </>
      ) : (
        <>
          {loading ? (
            <SkeletonList quantity={3} />
          ) : (
            <EmptyResult
              message={
                requestParams.name
                  ? "No companies found"
                  : "There are no companies in your profile."
              }
              className="h-full w-full"
            />
          )}
        </>
      )}

      {hasCompanyCreateRole && (
        <CompanyDrawer
          visible={drawerVisible}
          setVisible={setDrawerVisible}
          initialFormValues={null}
          onCreateCompany={onCreateCompany}
        />
      )}
    </div>
  );
};

export default CompaniesPage;
