import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../store/slices/appSlice";
import { TCustomError } from "src/utils/handleRequestError";
import { SerializedError } from "@reduxjs/toolkit";

// @TODO: This is a temporary hook to handle errors. We should move this to a more centralized location.
export const useErrors = (
  errors: (TCustomError | SerializedError | undefined)[],
) => {
  const messageApi = useSelector(selectMessageApi);

  const errorsToReport = errors.filter(Boolean);

  useEffect(() => {
    if (errorsToReport.length > 0) {
      errors.forEach((error) => {
        if (error) {
          messageApi.error(error.message);
          console.error(error);
        }
        console.error(error);
      });
    }
  }, [errors]);
};
