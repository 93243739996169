import React, { FC, useEffect, useRef, useState } from "react";
import { Button, FormInstance, Segmented, Select } from "antd";
import { TVarType, VarTypeEnum } from "../../../types";
import SelectCCKey from "../../common/SelectCCKey";
import TextArea from "antd/es/input/TextArea";

type Props = {
  localKeysOptions: { label: string; value: string }[];
  varKey: string | null;
  varType: TVarType;
  index: number;
  form: FormInstance<any>;
  formPromptArgsKey: string;
  gridItemSequence: number;
  closePopup: () => void;
};
const ArgumentContent: FC<Props> = ({
  localKeysOptions,
  formPromptArgsKey,
  gridItemSequence,
  form,
  varKey,
  varType,
  index,
  closePopup,
}) => {
  const [inputText, setInputText] = useState<string | null>(null);
  const inputTagRef = useRef<any>(null);
  const [argumentType, setArgumentType] = useState<TVarType>(varType);

  const argumentTypeOptions = [
    VarTypeEnum.LOCAL_VAR,
    VarTypeEnum.CAMPAIGN_VAR,
    VarTypeEnum.RAW,
    VarTypeEnum.EXPRESSION,
  ];

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus();
    }
  }, [argumentType]);

  const onChooseArgument = (value: string) => {
    const newKeys = form.getFieldValue(formPromptArgsKey);

    if (argumentType === VarTypeEnum.EXPRESSION) {
      newKeys[index] = `expr(${value.trim()})`;
    } else if (argumentType === VarTypeEnum.RAW) {
      newKeys[index] = `"${value.trim()}"`;
    } else {
      newKeys[index] = value;
    }

    form.setFieldsValue({
      [formPromptArgsKey]: newKeys,
    });

    closePopup();
  };

  const getInputSection = () => {
    switch (argumentType) {
      case VarTypeEnum.CAMPAIGN_VAR:
        return (
          <SelectCCKey
            gridItemSequence={gridItemSequence}
            onSelect={(value) => onChooseArgument(value)}
          />
        );
      case VarTypeEnum.LOCAL_VAR:
        return (
          <Select
            options={localKeysOptions}
            defaultOpen
            autoFocus
            showSearch
            onSelect={(value) => onChooseArgument(value)}
            placeholder="Choose Step Key"
            defaultValue={
              varType === VarTypeEnum.LOCAL_VAR ? varKey : undefined
            }
          />
        );
      case VarTypeEnum.RAW:
      case VarTypeEnum.EXPRESSION:
        return (
          <div className="flex flex-col items-end gap-2">
            <TextArea
              autoSize={{ minRows: 2, maxRows: 6 }}
              placeholder={
                argumentType === VarTypeEnum.RAW
                  ? "Enter text"
                  : "Enter an expression"
              }
              ref={inputTagRef}
              value={inputText || undefined}
              onChange={(e) => setInputText(e.target.value)}
            />
            <div>
              <Button
                type="primary"
                size="small"
                shape="round"
                onClick={() => inputText && onChooseArgument(inputText)}
                disabled={!inputText}
              >
                Save
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col gap-2 mb-1 max-w-[600px]">
      <Segmented
        size="small"
        value={argumentType}
        onChange={setArgumentType}
        options={argumentTypeOptions}
      />

      <div className="flex flex-col gap-2">
        {varKey && (
          <div className="flex gap-2 text-[12px]">
            <span className="shrink-0 font-bold text-[#475569]">
              Argument Value:
            </span>
            <span className="text-vePrimary">{varKey}</span>
          </div>
        )}
        {getInputSection()}
      </div>
    </div>
  );
};

export default ArgumentContent;
