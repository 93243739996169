import { FC } from "react";
import { format } from "date-fns";
import { cn } from "src/utils";

export const RevisionItem: FC<{
  revision: { id: number; btxUuid: string; timestamp: number; title?: string };
  user: { firstName: string; lastName: string };
  isSelected: boolean;
  onSelect: (transactionId: string, revisionId: number) => void;
}> = ({ revision, user, isSelected, onSelect }) => {
  return (
    <div
      className={cn(
        "group flex items-center justify-between px-4 py-2 cursor-pointer hover:bg-slate-100 rounded-sm pl-2",
        isSelected && "bg-[#E9EDF2]",
      )}
      onClick={() => onSelect(revision.btxUuid, revision.id)}
    >
      <div className="flex flex-col gap-1">
        <div className="text-slate-900 text-base font-medium">
          {format(new Date(revision.timestamp), "MMMM d, yyyy, h:mma")}
        </div>
        <div className="flex items-center gap-2">
          <div className="w-2 h-2 bg-[#066afe] rounded-full" />
          <span className="text-sm text-slate-600">
            {user.firstName} {user.lastName}
          </span>
        </div>
      </div>
    </div>
  );
};
