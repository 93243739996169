import React, { FC, useState } from "react";
import { UserPermissions } from "../../../store/slices/userData/constants";
import { Button } from "../../common/ui/button";
import { Icons } from "../../common/Icons";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { StartCampaignModal } from "./start-campaign-modal";

type Props = {
  companyId: number | null;
};
export const StartCampaign: FC<Props> = ({ companyId }) => {
  const { hasCampaignStartRole, hasPermissionAnywhere } = useUserHasPermission({
    companyId,
  });
  //Need to show button if user has ROLE_CAMPAIGN_START permission anywhere
  const showButton = hasPermissionAnywhere(UserPermissions.ROLE_CAMPAIGN_START);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {showButton && (
        <Button
          className="rounded-full"
          disabled={companyId === null || !hasCampaignStartRole}
          onClick={() => setIsModalOpen(true)}
        >
          <Icons.Plus className="mr-[4px] size-4" />
          Create Campaign
        </Button>
      )}

      {isModalOpen && companyId !== null && (
        <StartCampaignModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          companyId={companyId}
        />
      )}
    </>
  );
};
