import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import { getCcVarApi } from "src/api/cc-variables.api";
import { selectCurrentCampaign } from "src/store/slices/campaignsSlice";
import { TCcVariable } from "src/store/slices/ccVariablesSlice";
import { selectCurrentStep } from "src/store/slices/stepsSlice";
import AddCCVarForm from "./AddCCVarForm";
import {
  applyAnchorTags,
  applyHoverEffect,
  closestEditableElement,
} from "../helpers";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import handleRequestError from "../../../utils/handleRequestError";
import { useParams } from "react-router-dom";
import { CcItemEditor } from "../../ccItemEditor";
import { CcItemEditorData } from "../../ccItemEditor/types";
import VeeContextMenu from "./VeeContextMenu";
import { useOutsideAlerter } from "src/hooks/useClickOutside";

type DocViewIframeProps = {
  initialContent: string;
  ccVariables: TCcVariable[];
  onUpdated: () => void;
  onTemplateLoaded: (doc: Document) => void;
  scrollTop: number;
  setScrollTop: (scrollTop: number) => void;
  onKeyAdded: () => void;
  isPdf?: boolean;
  pdfSrc?: string;
};

const DocViewIframe: FC<DocViewIframeProps> = ({
  initialContent,
  ccVariables,
  onUpdated,
  onTemplateLoaded,
  scrollTop,
  setScrollTop,
  isPdf,
  pdfSrc,
  onKeyAdded,
}) => {
  const messageApi = useSelector(selectMessageApi);
  const currentCampaign = useSelector(selectCurrentCampaign);
  const currentStep = useSelector(selectCurrentStep);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const [selectedVariable, selectVariable] = useState<TCcVariable>();
  const [createKey, setCreateKey] = useState<string>();
  const { companyId } = useParams();
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [contextMenuVariable, setContextMenuVariable] = useState<string>();
  const { hasCampaignCcUpdateRole, hasCampaignCcCreateRole } =
    useUserHasPermission({ companyId });

  // Function to close the context menu
  const closeContextMenu = useCallback(() => {
    setContextMenuPosition(null);
    setContextMenuVariable(undefined);
  }, []);

  useOutsideAlerter(contextMenuRef, closeContextMenu);

  const editCCItemData: CcItemEditorData | null = useMemo(() => {
    if (selectedVariable) {
      return {
        ccItemKey: selectedVariable.id.key,
        campaignId: selectedVariable.id.campaignId.toString(),
        stepId: selectedVariable.id.stepId.toString(),
      };
    }

    return null;
  }, [selectedVariable]);

  useEffect(() => {
    const recentlyAddedVariable = ccVariables.find((variable) => {
      return variable.id.key === createKey;
    });

    if (recentlyAddedVariable && recentlyAddedVariable.state === "computed") {
      setCreateKey(undefined);
      onUpdated();
      onKeyAdded();
    }

    const updatedSelectedVariable = ccVariables.find((variable) => {
      return variable.id.key === selectedVariable?.id.key;
    });

    if (updatedSelectedVariable?.state === "computed") {
      selectVariable(updatedSelectedVariable);
      onUpdated();
    }
  }, [ccVariables]);

  useEffect(() => {
    if (isPdf) {
      return;
    }

    const iframe = iframeRef.current;
    const doc = iframe?.contentDocument || iframe?.contentWindow?.document;

    if (!doc) {
      messageApi.error("Unable to load the template");
      return;
    }

    doc.open();
    doc.write(initialContent);
    doc.close();

    const applyScrollPosition = () => {
      if (scrollTop) {
        const scrollingElement = doc.scrollingElement || doc.documentElement;
        scrollingElement.scrollTop = scrollTop;
      }
    };

    const handleClick = async (event: MouseEvent) => {
      // Close context menu if it's open
      if (contextMenuPosition) {
        closeContextMenu();
      }

      if (!hasCampaignCcUpdateRole && !hasCampaignCcCreateRole) {
        return;
      }

      try {
        const closestElement = closestEditableElement(event);
        const veContentKey = closestElement
          ? closestElement.getAttribute("ve-content")
          : null;

        if (!veContentKey) {
          return;
        }

        if (!currentCampaign || !currentStep) {
          messageApi.error("Unable to get [currentCampaign] or [currentStep]");
          return;
        }

        const { data: ccItem } = await getCcVarApi({
          campaignId: currentCampaign.id,
          stepId: currentStep.id,
          key: veContentKey,
        });

        if (ccItem) {
          selectVariable(ccItem);
        } else if (!ccItem && veContentKey) {
          setCreateKey(veContentKey);
        }
      } catch (e: any) {
        const customError = handleRequestError(e);

        messageApi.error(customError.message);
        console.error(customError);
      }
    };

    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();

      const closestElement = closestEditableElement(event);
      const veContentKey = closestElement
        ? closestElement.getAttribute("ve-content")
        : null;

      if (veContentKey) {
        const iframe = iframeRef.current;
        if (!iframe) return;

        const iframeRect = iframe.getBoundingClientRect();
        const cursorX = event.clientX + iframeRect.left;
        const cursorY = event.clientY + iframeRect.top;

        setContextMenuPosition({ x: cursorX, y: cursorY });
        setContextMenuVariable(veContentKey);
      }
    };

    const handleScroll = () => {
      const scrollingElement = doc.scrollingElement || doc.documentElement;
      setScrollTop(scrollingElement.scrollTop);

      // Close context menu on scroll
      closeContextMenu();
    };

    const handleLoad = () => {
      applyScrollPosition();
    };

    if (hasCampaignCcUpdateRole || hasCampaignCcCreateRole) {
      applyHoverEffect(doc);
    }

    applyAnchorTags(doc);
    onTemplateLoaded(doc);

    // Add event listeners
    doc.addEventListener("click", handleClick);
    doc.addEventListener("contextmenu", handleContextMenu);
    doc.addEventListener("scroll", handleScroll);
    iframe.addEventListener("load", handleLoad);

    // Add a mousedown listener to close context menu on any interaction
    const handleMouseDown = () => {
      if (contextMenuPosition) {
        closeContextMenu();
      }
    };

    doc.addEventListener("mousedown", handleMouseDown);

    // Add window scroll listener to close context menu
    const handleWindowScroll = () => {
      if (contextMenuPosition) {
        closeContextMenu();
      }
    };

    window.addEventListener("scroll", handleWindowScroll);

    // Add a general click listener to the iframe to ensure we catch all clicks
    const handleIframeClick = () => {
      if (contextMenuPosition) {
        closeContextMenu();
      }
    };

    iframe.addEventListener("click", handleIframeClick);

    return () => {
      doc.removeEventListener("click", handleClick);
      doc.removeEventListener("contextmenu", handleContextMenu);
      doc.removeEventListener("scroll", handleScroll);
      doc.removeEventListener("mousedown", handleMouseDown);
      iframe.removeEventListener("load", handleLoad);
      window.removeEventListener("scroll", handleWindowScroll);
      iframe.removeEventListener("click", handleIframeClick);
    };
  }, [
    initialContent,
    closeContextMenu,
    contextMenuPosition,
    hasCampaignCcUpdateRole,
    hasCampaignCcCreateRole,
  ]);

  return (
    <div className="w-full mt-[24px] border border-slate-300 rounded-md h-[600px]">
      <div>
        {isPdf && pdfSrc ? (
          <iframe
            className="w-full h-[600px] rounded-md"
            title="preview"
            src={pdfSrc}
          />
        ) : (
          <iframe
            ref={iframeRef}
            className="w-full h-[600px] rounded-md"
            title="preview"
          />
        )}
      </div>

      {hasCampaignCcUpdateRole && editCCItemData && companyId && (
        <CcItemEditor
          editDataProps={editCCItemData}
          companyId={companyId}
          onAfterCCItemUpdate={onUpdated}
          onAfterClose={() => {
            selectVariable(undefined);
          }}
        />
      )}

      {contextMenuPosition && contextMenuVariable && (
        <div
          ref={contextMenuRef}
          className="fixed bg-white border border-slate-300 rounded-md p-2 context-menu-container"
          style={{
            left: contextMenuPosition.x,
            top: contextMenuPosition.y,
          }}
        >
          <VeeContextMenu
            ccVariables={ccVariables}
            ccVarKey={contextMenuVariable}
            onOpenEditor={(variable) => {
              selectVariable(variable);
            }}
            onClose={closeContextMenu}
          />
        </div>
      )}

      {hasCampaignCcCreateRole && createKey !== undefined && (
        <AddCCVarForm
          onClose={() => setCreateKey(undefined)}
          createKey={createKey}
          ccVariables={ccVariables}
          onAfterCreate={onUpdated}
        />
      )}
    </div>
  );
};

export default DocViewIframe;
