import React from "react";
import useGetUserApiKeys from "../hooks/useGetUserApiKeys";
import { Skeleton } from "antd";
import ListItem from "./ListItem";
import Header from "./Header";
import { EmptyResult } from "../../../common/EmptyResult";

const UserApiKeys = () => {
  const { loadApiKeys, initialized, apiKeys } = useGetUserApiKeys();

  if (!initialized) return <Skeleton active />;

  return (
    <div className="w-[766px] flex flex-col gap-4">
      <Header loadApiKeys={loadApiKeys} />

      {apiKeys.length ? (
        <div className="border rounded-md">
          {apiKeys.map(({ id: { apiKey } }) => {
            return (
              <ListItem
                key={apiKey}
                apiKey={apiKey}
                loadApiKeys={loadApiKeys}
              />
            );
          })}
        </div>
      ) : (
        <EmptyResult message="There are no generated keys" />
      )}
    </div>
  );
};

export default UserApiKeys;
