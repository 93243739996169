import React, { FC } from "react";
import clsx from "clsx";
import { OverrideEditorMode } from "../constants";
import { CodeIcon, TLetterIcon } from "../../common/Icons";

type Props = {
  editorMode: OverrideEditorMode;
  setEditorMode: (mode: OverrideEditorMode) => void;
  itemClassName?: string;
  iconClassName?: string;
  richEditorDisabled?: boolean;
};

const RichEditorModeSwitcher: FC<Props> = ({
  editorMode,
  setEditorMode,
  itemClassName,
  iconClassName,
  richEditorDisabled = false
}) => {
  return (
    <div className="flex">
      <div
        className={clsx(
          "flex items-center justify-center size-10 rounded-lg cursor-pointer",
          itemClassName,
          {
            "bg-[#E2E0F8] text-[#4F46E5]":
              editorMode === OverrideEditorMode.RICH_EDITOR,
            "hover:text-[#4F46E5]":
              editorMode !== OverrideEditorMode.RICH_EDITOR,
            "pointer-events-none !cursor-not-allowed opacity-[20%]": richEditorDisabled
          },
        )}
        onClick={() => setEditorMode(OverrideEditorMode.RICH_EDITOR)}
      >
        <TLetterIcon className={iconClassName} />
      </div>
      <div
        className={clsx(
          "flex items-center justify-center size-10 rounded-lg cursor-pointer",
          itemClassName,
          {
            "bg-[#E2E0F8] text-[#4F46E5]":
              editorMode === OverrideEditorMode.DEFAULT,
            "hover:text-[#4F46E5]": editorMode !== OverrideEditorMode.DEFAULT,
          },
        )}
        onClick={() => setEditorMode(OverrideEditorMode.DEFAULT)}
      >
        <CodeIcon className={iconClassName} />
      </div>
    </div>
  );
};

export default RichEditorModeSwitcher;
