import { FC, useEffect, useState } from "react";
import { Button } from "../../common/ui/button";
import { Plus } from "lucide-react";
import { createChatApi, getChatApi, updateChatApi } from "src/api/chat.api";
import { useCampaignChatParams } from "../hooks/useCampaignChatParams";
import { CCVarsGrid } from "./cc-vars-grid";
import { ChatInfo, Chat as ChatType } from "src/components/CampaignChat/types";
import { useNavigate } from "react-router-dom";
import { handleRequestError } from "src/utils/handleRequestError";
import { getMessageApi } from "src/store/slices/appSlice";
import { CreateNewChatAlert } from "./create-new-chat-alert";
import { useBoolean } from "src/hooks/useBoolean";
import {
  useGetDocumentsCcVarsQuery,
  useGetChatOverridesQuery,
  useGetChatMessagesQuery,
  useUpdateChatOverrideMutation,
  useCreateChatOverrideMutation,
  useUpdateChatContentBulkMutation,
  setSelectedCCVarKeys,
  selectPendingChatOverrides,
  selectChatMessages,
} from "src/store/slices/chatSlice";
import ChatBreadcrumb from "./chat-breadcrumb";
import HistoryModal from "./history/history-modal";
import { POLLING_INTERVAL } from "../constants";
import { useErrors } from "src/hooks/useErrors";
import { Chat } from "./Chat";
import { useDispatch, useSelector } from "react-redux";
import { selectChatSelectedCCVarKeys } from "src/store/slices/chatSlice";

const CampaignChat: FC = () => {
  const { chatId, companyId } = useCampaignChatParams();
  const navigate = useNavigate();
  const messageApi = getMessageApi();
  const dispatch = useDispatch();

  // TODO: Move to store
  const [chatInfo, setChatInfo] = useState<ChatInfo | null>(null);
  const [showNewChatDialog, setShowNewChatDialog] = useState(false);
  const [isNewChatLoading, setIsNewChatLoading] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isApplyingOverrides, startApplyingOverrides, stopApplyingOverrides] =
    useBoolean(false);

  const selectedCCVarKeys = useSelector(selectChatSelectedCCVarKeys);

  const [updateChatOverride] = useUpdateChatOverrideMutation();
  const [createChatOverride] = useCreateChatOverrideMutation();
  const [updateChatContentBulk] = useUpdateChatContentBulkMutation();

  const { data: ccVars, error: ccVarsError } = useGetDocumentsCcVarsQuery(
    {
      documentTemplateId: chatInfo?.documentTemplateId || 0,
      stepId: chatInfo?.chat.stepId || 0,
    },
    {
      skip: !chatInfo?.documentTemplateId || !chatInfo?.chat.stepId,
    },
  );

  const { data: chatOverrides = [], error: chatOverridesError } =
    useGetChatOverridesQuery(
      { chatId: Number(chatId) },
      {
        skip: !chatId,
        pollingInterval: POLLING_INTERVAL,
      },
    );

  const { error: messagesError } = useGetChatMessagesQuery(
    { chatId: Number(chatId) },
    {
      skip: !chatId,
      pollingInterval: POLLING_INTERVAL,
    },
  );

  const pendingChatOverrides = useSelector(selectPendingChatOverrides);
  const messages = useSelector(selectChatMessages);

  useErrors([ccVarsError, chatOverridesError, messagesError]);

  useEffect(() => {
    dispatch(setSelectedCCVarKeys([]));
  }, [chatId]);

  useEffect(() => {
    try {
      const fetchCurrentChat = async () => {
        if (!chatId) return;

        const response = await getChatApi({
          chatId: Number(chatId),
        });

        setChatInfo(response.data);
      };

      fetchCurrentChat();
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  }, [chatId]);

  const handleCreateNewChat = async () => {
    if (!chatInfo?.chat.campaignId || !chatInfo?.chat.stepId) return;

    try {
      setIsNewChatLoading(true);
      const response = await createChatApi({
        campaignId: chatInfo.chat.campaignId,
        stepId: chatInfo.chat.stepId,
        chat: {
          title: "New Chat",
          aiModelId: null,
        },
      });

      navigate(`/campaigns/company/${companyId}/chat/${response.data.id}`);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsNewChatLoading(false);
    }
  };

  const handleCreateNewChatClick = () => {
    if (selectedCCVarKeys.length > 0) {
      setShowNewChatDialog(true);
    } else {
      handleCreateNewChat();
    }
  };

  const handleConfirmNewChat = () => {
    setShowNewChatDialog(false);
    handleCreateNewChat();
  };

  const handleChatUpdate = async (chatUpdate: Partial<ChatType>) => {
    if (!chatInfo?.chat.id) return;

    try {
      const updatedChat: ChatType = {
        ...chatInfo?.chat,
        ...chatUpdate,
      };
      await updateChatApi({
        chat: updatedChat,
      });
      setChatInfo({
        ...chatInfo,
        chat: updatedChat,
      });
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const applyOverrides = async () => {
    try {
      startApplyingOverrides();
      const pendingOverrides = chatOverrides.filter(
        (v) => v.status === "READY",
      );

      if (!chatInfo?.chat.campaignId || !chatInfo?.chat.stepId) {
        messageApi.error("Campaign or step ID not found");
        return;
      }

      const content = pendingOverrides.reduce(
        (acc, override) => {
          acc[override.id.key] = override.text;
          return acc;
        },
        {} as Record<string, string>,
      );

      await updateChatContentBulk({
        campaignId: chatInfo.chat.campaignId,
        stepId: chatInfo.chat.stepId,
        content,
        recomputeDownStream: true,
      });

      const updatePromises = pendingOverrides.map((override) =>
        updateChatOverride({
          chatOverride: {
            id: {
              chatId: chatId,
              key: override.id.key,
            },
            text: override.text,
            status: "APPLIED",
          },
        }),
      );

      await Promise.all(updatePromises);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      stopApplyingOverrides();
    }
  };

  const handleEditedContent = async (key: string, content: string) => {
    const chatOverride = chatOverrides.find((v) => v.id.key === key);
    try {
      if (chatOverride) {
        await updateChatOverride({
          chatOverride: {
            ...chatOverride,
            text: content,
            status: "READY",
          },
        });
      } else {
        await createChatOverride({
          chatOverride: {
            id: { chatId: chatId, key },
            text: content,
            status: "READY",
          },
        });
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const cancelOneOverride = async (key: string) => {
    try {
      await updateChatOverride({
        chatOverride: {
          id: { chatId: chatId, key },
          text: "",
          status: "CANCELED",
        },
      });
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const cancelOverrides = async () => {
    try {
      startApplyingOverrides();
      const promises = pendingChatOverrides.map((v) =>
        updateChatOverride({
          chatOverride: {
            id: { chatId: chatId, key: v.id.key },
            text: "",
            status: "CANCELED",
          },
        }),
      );
      await Promise.all(promises);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      stopApplyingOverrides();
    }
  };

  return (
    <div className="flex h-full">
      <div className="w-[400px] bg-[#F8FAFC]">
        <div className="flex gap-4 h-full">
          <div className="flex flex-col w-[720px] h-[calc(100vh-64px)]">
            <Button
              variant="outline"
              onClick={handleCreateNewChatClick}
              className="m-4"
              disabled={messages.length === 0}
              loading={isNewChatLoading}
              icon={Plus}
              iconClassName="size-4"
            >
              New Chat
            </Button>
            {chatInfo && ccVars && (
              <Chat chatInfo={chatInfo} updateChatInfo={handleChatUpdate} />
            )}
          </div>
        </div>
      </div>
      <div className="w-full">
        {chatInfo && (
          <ChatBreadcrumb
            chatInfo={chatInfo}
            onHistoryClicked={() => setIsHistoryOpen(true)}
          />
        )}
        {ccVars && (
          <CCVarsGrid
            isApplyingOverrides={isApplyingOverrides}
            applyOverrides={applyOverrides}
            cancelOverrides={cancelOverrides}
            cancelOneOverride={cancelOneOverride}
            onEditedContent={handleEditedContent}
            chatInfo={chatInfo!}
          />
        )}
      </div>

      <CreateNewChatAlert
        showNewChatDialog={showNewChatDialog}
        setShowNewChatDialog={setShowNewChatDialog}
        handleConfirmNewChat={handleConfirmNewChat}
      />

      {isHistoryOpen && (
        <HistoryModal
          open={isHistoryOpen}
          onOpenChange={setIsHistoryOpen}
          chatInfo={chatInfo!}
          ccVariables={ccVars?.map((v) => v.id.key) || []}
          chatId={Number(chatId)}
        />
      )}
    </div>
  );
};

export default CampaignChat;
