import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { CompaniesListRequestParams } from "src/api/companies.api";
import { TCompany } from "src/store/slices/companiesSlice";
import { SortingDirection } from "src/types/docTemplates";
import { stringifyObjectValues } from "src/utils/stringifyObjectValues";

export const useCompaniesSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const requestParams = useMemo<CompaniesListRequestParams>(() => {
    const pageNumber = searchParams.get("pageNumber");
    const pageSize = searchParams.get("pageSize");
    const name = searchParams.get("name") || undefined;
    const sortBy = searchParams.get("sortBy") || "name";
    const orderBy = searchParams.get("orderBy") || "asc";

    return {
      name,
      pageNumber: Number(pageNumber),
      pageSize: Number(pageSize),
      sortBy: sortBy ? (sortBy as keyof TCompany) : undefined,
      orderBy: orderBy ? (orderBy as SortingDirection) : undefined,
    };
  }, [searchParams]);

  const updateSearchParams = (params: CompaniesListRequestParams) => {
    const stringifiedParams =
      stringifyObjectValues<CompaniesListRequestParams>(params);
    setSearchParams(stringifiedParams);
  };

  return {
    requestParams,
    updateSearchParams,
  };
};
