import React, { FC, useState } from "react";
import { ArchiveIcon } from "lucide-react";
import {
  ArrowUpTrayIcon,
  ArrowUturnLeftIcon,
  CloneIcon,
  DeleteIcon,
  Icons,
} from "../../common/Icons";
import MenuDropdown, { MenuDropdownItem } from "../../common/MenuDropdown";
import { useNavigate } from "react-router-dom";
import { getMessageApi } from "../../../store/slices/appSlice";
import useConfirm from "../../../hooks/useConfirm";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import {
  archiveCampaignApi,
  cloneCampaignApi,
  deleteCampaignApi,
  publishCampaignApi,
  unArchiveCampaignApi,
  unPublishCampaignApi,
} from "../../../api/campaigns.api";
import handleRequestError from "../../../utils/handleRequestError";
import CloneEntityModal, {
  TCloneEntityFormValues,
} from "../../common/modals/CloneEntityModal/CloneEntityModal";
import ClonedWithErrorsMessage from "../../common/ClonedWithErrorsMessage";
import { CampaignsTableItem } from "../types";
import { Button } from "../../common/ui/button";
import DeleteWithFilesModal from "../../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";

type Props = {
  campaign: CampaignsTableItem;
  reloadCampaignList: () => Promise<void>;
};
export const ItemMenu: FC<Props> = ({ campaign, reloadCampaignList }) => {
  const { id, name: title, type: campaignType, companyId, archive } = campaign;
  const navigate = useNavigate();
  const messageApi = getMessageApi();
  const confirm = useConfirm();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const {
    hasCampaignDeleteRole,
    hasCampaignCloneRole,
    hasCampaignPublishRole,
    hasCampaignUnpublishRole,
    hasCampaignArchiveRole,
  } = useUserHasPermission({
    companyId,
  });

  const handleDelete = async (removeFiles: boolean) => {
    try {
      await deleteCampaignApi({ campaignId: id, removeFiles });

      reloadCampaignList();
      setIsDeleteModalOpen(false);
      messageApi.success("The campaign has been successfully removed");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onCloneCampaign = async (values: TCloneEntityFormValues) => {
    try {
      const params = {
        ...values,
        name: values.name.replace(/ +/g, " "),
      };

      const { data } = await cloneCampaignApi({
        campaignId: id,
        ...params,
      });

      navigate(`/campaigns/company/${companyId}/campaign/${data.campaign.id}`);

      if (data.errorMessages.length) {
        messageApi.warning(
          <ClonedWithErrorsMessage errorMessages={data.errorMessages} />,
        );
      } else {
        messageApi.success("The campaign has been successfully cloned");
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onPublishCampaign = async () => {
    try {
      if (campaignType === "DRAFT_TEMPLATE") {
        await publishCampaignApi({ campaignId: id });
      } else {
        await unPublishCampaignApi({ campaignId: id });
      }

      reloadCampaignList();

      messageApi.success(
        `The campaign has been successfully ${
          campaignType === "PUBLISHED_TEMPLATE" ? "unpublished" : "published"
        }`,
      );
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onArchiveCampaign = async () => {
    try {
      if (archive) {
        await unArchiveCampaignApi({ campaignId: id });
        messageApi.success("Campaign has been unarchived successfully");
      } else {
        await archiveCampaignApi({ campaignId: id });
        messageApi.success("Campaign has been archived successfully");
      }

      reloadCampaignList();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const menuItems = [
    hasCampaignArchiveRole &&
      !archive && {
        key: "archive",
        label: "Archive",
        icon: ArchiveIcon,
        onClick: () =>
          confirm({
            action: onArchiveCampaign,
            title: "Archive Campaign",
            content: "Are you sure you want to archive this campaign?",
            okText: "Archive",
          }),
      },
    hasCampaignArchiveRole &&
      archive && {
        key: "unarchive",
        label: "Unarchive",
        icon: ArrowUturnLeftIcon,
        onClick: () =>
          confirm({
            action: onArchiveCampaign,
            title: "Unarchive Campaign",
            content: "Are you sure you want to unarchive this campaign?",
            okText: "Unarchive",
          }),
      },
    hasCampaignPublishRole &&
      campaignType === "DRAFT_TEMPLATE" && {
        key: "publish",
        label: "Publish",
        icon: ArrowUpTrayIcon,
        onClick: () =>
          confirm({ action: onPublishCampaign, title: "Publish Template" }),
        className: "!text-vePrimary",
      },
    hasCampaignUnpublishRole &&
      campaignType === "PUBLISHED_TEMPLATE" && {
        key: "unpublish",
        label: "Unpublish",
        icon: ArrowUturnLeftIcon,
        onClick: () =>
          confirm({ action: onPublishCampaign, title: "Unpublish Template" }),
      },
    hasCampaignCloneRole && {
      key: "clone",
      label: "Clone",
      icon: CloneIcon,
      onClick: () => setIsCloneModalOpen(true),
    },
    hasCampaignDeleteRole && {
      key: "delete",
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  return (
    <>
      <MenuDropdown items={menuItems} dropdownClassName="w-[190px]">
        <Button variant={"ghostPrimary"} className="size-10 p-2">
          <Icons.MoreDots className="size-6" />
        </Button>
      </MenuDropdown>

      {hasCampaignDeleteRole && isDeleteModalOpen && (
        <DeleteWithFilesModal
          entity="Campaign"
          onDelete={handleDelete}
          title={title}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
        />
      )}

      {hasCampaignCloneRole && isCloneModalOpen && (
        <CloneEntityModal
          entity="Campaign"
          entityTitle={title}
          entityTitleTooltip="Please enter a string between 4 and 255 characters."
          onSubmitForm={onCloneCampaign}
          isModalOpen={isCloneModalOpen}
          onCancel={() => setIsCloneModalOpen(false)}
        />
      )}
    </>
  );
};
