import { FC } from "react";

interface SelectionState {
  visible: boolean;
  x: number;
  y: number;
  startIndex: number;
  endIndex: number;
  parentElement: Element | null;
}

interface SelectedTextContextMenuProps {
  selectionState: SelectionState;
  onAddSelection: () => void;
}

export const SelectedTextContextMenu: FC<SelectedTextContextMenuProps> = ({
  selectionState,
  onAddSelection,
}) => {
  if (!selectionState.visible) return null;

  return (
    <div
      id="selection-dropdown"
      className="fixed bg-white rounded-md shadow-lg border border-gray-200 py-1 z-50 overflow-hidden min-w-[120px] animate-in fade-in zoom-in-95 duration-100"
      style={{
        left: `${selectionState.x}px`,
        top: `${selectionState.y + 5}px`,
        transform: "translateX(-50%)",
      }}
    >
      <div
        className="px-3 py-1.5 text-sm text-gray-700 hover:bg-indigo-50 hover:text-indigo-600 cursor-pointer flex items-center gap-2 transition-colors"
        onClick={onAddSelection}
      >
        Add Selection
      </div>
    </div>
  );
};
