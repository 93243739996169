import { Button } from "src/components/common/ui/button";
import { useBoolean } from "src/hooks/useBoolean";
import { createChatApi } from "src/api/chat.api";
import { handleRequestError } from "src/utils/handleRequestError";
import { useNavigate } from "react-router-dom";
import { useUserHasPermission } from "src/store/slices/userData/hooks/useUserHasPermission";
import { FC } from "react";
import { useSelector } from "react-redux";
import { selectMessageApi } from "src/store/slices/appSlice";
import { selectCurrentStep } from "src/store/slices/stepsSlice";
import { useCampaignParams } from "../CampaignEditorPage/useCampaignParams";
import { SparklesIcon } from "@heroicons/react/24/outline";
type StartChatButtonProps = {};

export const StartChatButton: FC<StartChatButtonProps> = () => {
  const { companyId, campaignId } = useCampaignParams();
  const step = useSelector(selectCurrentStep);
  const [isLoading, startLoading, stopLoading] = useBoolean(false);
  const messageApi = useSelector(selectMessageApi);
  const navigate = useNavigate();
  const { hasChatListRole } = useUserHasPermission({
    companyId,
  });

  const handleStartChat = async () => {
    startLoading();
    try {
      const response = await createChatApi({
        campaignId: Number(campaignId),
        stepId: Number(step?.id),
        chat: {
          stepId: Number(step?.id),
          title: "New Chat",
          aiModelId: null,
        },
      });

      navigate(`/campaigns/company/${companyId}/chat/${response.data.id}`);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      stopLoading();
    }
  };

  if (!hasChatListRole) return null;

  return (
    <div className="flex gap-[4px]">
      <Button
        variant="primary"
        size="sm"
        className="rounded-full"
        onClick={handleStartChat}
        disabled={isLoading || !step?.documentTemplateId}
        icon={SparklesIcon}
        iconClassName="w-4 h-4"
        loading={isLoading}
      >
        Edit with AI
      </Button>
    </div>
  );
};
