import React, { FC, ReactNode, useEffect, useState } from "react";
import { getCcVarInfoApi } from "../../../api/cc-variables.api";
import { Button, Drawer, Skeleton } from "antd";
import handleRequestError from "../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import EditorHeader from "./EditorHeader";
import EditorNav from "./EditorNav";
import EditorNavContent from "./EditorNavContent";
import clsx from "clsx";
import { CcItemEditorData, CcItemInfo, OnAfterCCItemUpdate } from "../types";
import { CcItemEditType } from "../constants";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { selectEventMessages } from "../../../store/slices/computationMessages/slice";
import XMarkButton from "../../common/XMarkButton";

type Props = {
  editDataProps: CcItemEditorData | null;
  companyId: string;
  onAfterClose?: () => void;
  onAfterCCItemUpdate?: OnAfterCCItemUpdate;
};

const Editor: FC<Props> = ({
  editDataProps,
  onAfterClose,
  onAfterCCItemUpdate: onAfterUpdate,
  companyId,
}) => {
  const messageApi = useSelector(selectMessageApi);
  const eventMessages = useSelector(selectEventMessages);
  const [ccItem, setCcItem] = useState<CcItemInfo | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<CcItemEditType>(
    CcItemEditType.CONTENT,
  );
  const {
    hasCampaignCcItemComputationRole,
    hasCampaignEnvItemReadRole,
    hasCampaignCcUpdateRole,
    hasCampaignEnvItemUpdateRole,
    hasCampaignCcOverrideRole,
  } = useUserHasPermission({
    companyId,
  });

  //Update cc-item if a new event arrives. Compare by modifiedTime. If modifiedTime different - update the state
  useEffect(() => {
    if (eventMessages.length === 0 || !ccItem) return;

    const ccItemMsg = eventMessages.find(
      ({ item }) =>
        item?.id.key === ccItem.item.id.key &&
        item?.id.stepId === ccItem.item.id.stepId,
    );

    if (ccItemMsg && ccItemMsg.item) {
      if (ccItem.item.modifiedTime !== ccItemMsg.item.modifiedTime) {
        setCcItem((prev) => {
          if (prev && ccItemMsg.item) {
            return {
              ...prev,
              item: ccItemMsg.item,
            };
          }

          return null;
        });
      }
    }
  }, [eventMessages]);

  useEffect(() => {
    if (editDataProps) {
      setDrawerOpen(true);
      setActiveTab(CcItemEditType.CONTENT);
      setLoading(true);

      loadCCItem(editDataProps).finally(() => setLoading(false));
    }
  }, [editDataProps]);

  const loadCCItem = async ({
    ccItemKey,
    campaignId,
    stepId,
  }: CcItemEditorData) => {
    try {
      const { data } = await getCcVarInfoApi({
        campaignId,
        stepId,
        key: ccItemKey,
      });

      setCcItem(data);

      return data;
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onDrawerClose = () => {
    setDrawerOpen(false);
  };

  const afterOpenChange = (open: boolean) => {
    if (!open && onAfterClose) {
      onAfterClose();
    }
  };

  const onAfterCCItemUpdate: OnAfterCCItemUpdate = async () => {
    if (editDataProps) {
      const ccItemInfo = await loadCCItem(editDataProps);

      if (ccItemInfo && onAfterUpdate) {
        onAfterUpdate(ccItemInfo.item);
      }
    } else {
      console.error("Couldn't update cc-item");
    }
  };

  return (
    <Drawer
      title={null}
      placement="left"
      width="100%"
      open={drawerOpen}
      afterOpenChange={afterOpenChange}
      classNames={{
        header: "!hidden",
        body: "!pt-4 !pb-6 !px-0",
      }}
    >
      {loading ? (
        <ContentWrap className="flex flex-col gap-4">
          <XMarkButton onClick={onDrawerClose} />
          <Skeleton active paragraph={{ rows: 10 }} />
        </ContentWrap>
      ) : (
        <>
          {ccItem ? (
            <div className="flex flex-col gap-4 h-full">
              <ContentWrap>
                <EditorHeader
                  onDrawerClose={onDrawerClose}
                  ccItem={ccItem}
                  companyId={companyId}
                  hasCampaignCcItemComputationRole={
                    hasCampaignCcItemComputationRole
                  }
                />
              </ContentWrap>

              <EditorNav
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                hasPrompt={ccItem.item.type === "prompt"}
                hasDynamicFields={!!ccItem.item.options}
                hasCampaignEnvItemReadRole={hasCampaignEnvItemReadRole}
                hasCampaignCcUpdateRole={hasCampaignCcUpdateRole}
              />

              <ContentWrap className="flex-1 overflow-y-auto">
                <EditorNavContent
                  activeTab={activeTab}
                  ccItem={ccItem.item}
                  ccItemPhaseId={ccItem.phaseId}
                  onAfterCCItemUpdate={onAfterCCItemUpdate}
                  hasCampaignEnvItemUpdateRole={hasCampaignEnvItemUpdateRole}
                  hasCampaignCcOverrideRole={hasCampaignCcOverrideRole}
                  hasCampaignCcUpdateRole={hasCampaignCcUpdateRole}
                />
              </ContentWrap>
            </div>
          ) : (
            <ContentWrap>
              <div className="text-red-600">
                An error occurred! We couldn't load the data. Please try again
                later.
              </div>
              <Button onClick={onDrawerClose}>Close</Button>
            </ContentWrap>
          )}
        </>
      )}
    </Drawer>
  );
};

export default Editor;

interface ContentWrapProps {
  children: ReactNode;
  className?: string;
}

const ContentWrap: FC<ContentWrapProps> = ({ children, className }) => {
  return <div className={clsx("px-12", className)}>{children}</div>;
};
